import React, { Component } from "react";
import { DuePaymentCard } from "../../../shared/due-payment-card/DuePaymentCard";
import { ActiveProductsDashboard } from "../../../shared/active-products-dashboard/ActiveProductsDashboard";

export class SummaryVertical extends Component {
  render() {
    return (
      <div className='desktopView homePayDues twoColLayout'>
        {/* Repeat ypur col from here  */}
        <h2 className='homeHederText'>Payments Due</h2>
        {this.props.dueProducts.map(value => (
          <div key={value.policyNumber} className='leftCol'>
            <DuePaymentCard clientName={this.props.clientName} dueProduct={value} {...this.props} />
          </div>
        ))}

        {/* Repeat your col block End **** */}
        <div className='rightCol'>
          <h2 className='homeHederText'>Your Active Products</h2>
          <ActiveProductsDashboard
            activeProducts={this.props.activeProducts}
            hasEAPlusProduct={this.props.hasEAPlusProduct}
            globlWebContents={this.props.globlWebContents}
          />
        </div>
      </div>
    );
  }
}
