import { HttpRequest } from './http/http.service';
import { apiRequest } from '../_constants/url.constants';
import { isCustomer } from '../_routes/app-routes';

export class AuthenticationService {
  http = new HttpRequest();

  isUserLoggedIn() {
    let activeSession = document.cookie.replace(/(?:(?:^|.*;\s*)activeSession\s*=\s*([^;]*).*$)|^.*$/, "$1");
    if (activeSession === '') {
      localStorage.removeItem('ccuser');
      localStorage.removeItem('user');
      document.cookie = 'activeSession=true';
    } else {
      if (isCustomer()) {
        return localStorage.getItem('ccuser') ? true : false;
      }
      return localStorage.getItem('user') ? true : false;
    }
  }

  getInitialIds(hostName) {
    return this.http.get(apiRequest.baseForInitialIds + apiRequest.initialIds + '/' + hostName)
  }

  login(userInfo) {
    return this.http.post(apiRequest.base + apiRequest.login, userInfo);
  }

  loginAttempts(params) {
    return this.http.post(apiRequest.base + apiRequest.loginAttempts, params);
  }

  verifyPolicyNumber(params) {
    return this.http.post(apiRequest.base + apiRequest.verifyPolicyNumber, params);
  }

  verifyPersonalInfo(params) {
    return this.http.post(apiRequest.base + apiRequest.verifyPersonalInfo, params);
  }

  verifySignInCreds(params) {
    return this.http.post(apiRequest.base + apiRequest.verifySignInCreds, params);
  }

  forgotUsername(params) {
    return this.http.post(apiRequest.base + apiRequest.forgotUsername, params);
  }

  resetPassword(params) {
    return this.http.post(apiRequest.base + apiRequest.resetPassword, params);
  }

  forgotPassword1(params) {
    return this.http.get(apiRequest.base + apiRequest.forgotPassword1 + params);
  }

  forgotPassword2(params) {
    return this.http.post(apiRequest.base + apiRequest.forgotPassword2, params);
  }

  logout(params) {
    return this.http.get(apiRequest.base + apiRequest.logout + '/' + params);
  }

  impersonateUser(params) {
    let url = apiRequest.impersonateUser.replace('{companyId}', params.companyId)
      .replace('{userId}', params.userId);
    return this.http.get(apiRequest.base + url);
  }

  resetUserPassword(params) {
    return this.http.post(apiRequest.base + apiRequest.forgotPassword2, params)
  }

  deleteUser(params) {
    let url = apiRequest.deleteUser.replace('{companyId}', params.companyId)
      .replace('{userId}', params.userId);
    return this.http.delete(apiRequest.base + url, params);
  }

  registerMigration(params) {
    return this.http.post(apiRequest.base + apiRequest.registerMigration, params)
  }

  updateLoginInformations(params) {
    return this.http.put(apiRequest.base + apiRequest.updateLoginInformations, params)
  }
}