import React, { Component } from "react";
import { Link } from "react-router-dom";

export class DuePaymentCard extends Component {
  dueProduct = this.props.dueProduct;

  handlePayNow = () => {
    if (this.dueProduct.paymentAction.payNowLink.disable === 'N') {
      this.props.history.push({
        pathname: "/payment-center/pay-now",
        data: {
          selectedProduct: this.props.dueProduct,
          clientName: this.props.clientName,
          fromScreen: 'DASHBOARD'
        }
      });
    }
  };

  render() {
    return (
      <div className='flexWhiteBox'>
        <div className='leftColumn'>
          <div className='headerColumn selected'>
            <h3>
              <Link title={""} to={`/product-details/${this.dueProduct.policyNumber}`}>
                {this.dueProduct.productDesc}
              </Link>
            </h3>
            <div className='btn-links'>
              <span className={'paymentStatusBadge ' + this.dueProduct.paymentStatusDisplayStyle}>{this.dueProduct.paymentStatus}</span>
            </div>
          </div>
          <div className='bodyRow'>
            <div className='flexBox'>
              <ul>
                <li><span className="label">CERTIFICATE/PLAN NUMBER</span><span className="value">{this.dueProduct.policyNumber}</span></li>
                <li><span className="label">PAYMENT FREQUENCY</span><span className="value">{this.dueProduct.currentPaymentFrequecyType}</span></li>
                <li><span className="label">DUE DATE</span><span className="value">{this.dueProduct.dueDate}</span></li>
                <li><span className="label">AMOUNT DUE</span><span className="value">{this.dueProduct.amountDue}</span></li>
              </ul>
            </div>
          </div>
          <div className='footerCol'>
            <div onClick={this.handlePayNow} className='bottomButton'>
              <button className={'ma-hp-payNow btn agiaBlueButton payNow ' + (this.dueProduct.paymentAction.payNowLink.disable === 'Y' ? 'disabled' : '')}>Pay now</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
