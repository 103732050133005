import React from "react";
import { PaymentCenterService } from "../../../../_services/payment-center.service";
import { SharedService } from "../../../../_services/shared.service";
import { PayNowDesktop } from "./desktop/PayNowDesktop";
import { PayNowMobile } from "./mobile/PayNowMobile";
import { Notification } from "../../../shared/notifications/notifications";
import { getCardYears, convertStringToAmount } from "../../../../_services/utility.service";
import { UpdatePaymentMethods } from "../../../shared/modals/update-payment-method/UpdatePaymentMethod";
import { ConfirmPayNow } from "../../../shared/modals/confirm-pay-now/ConfirmPayNow";
import "./PayNow.scss";
import { ARTICLES, MONTHS_LONG, PAGE_TITLES, PAGES_TITLE_KEYS } from '../../../../_constants/constants';
import { StaticContentModal } from '../../../shared/modals/static-content/StaticContentModal';
import { Helmet } from "react-helmet";

export class PayNow extends React.Component {
  paymentCenterService = new PaymentCenterService();
  sharedService = new SharedService();

  state = {
    paymentMethods: [],
    paymentMethodsLoaded: false,
    hasAnyPaymentMethods: false,
    selectedPaymentMethodRecId: undefined, // holds id
    selectedPaymentMethodObj: null, // holds object for display purpose
    selectedPaymentMethod: null, // holds object to pass to child component

    showPaymentMethodPop: false,
    showConfirmPayPopup: false,
    showPayNowSuccessPopup: false,

    globlWebContents: {},

    confirmPopupContent: '',
    payNowSuccessMsg: '',
    isFormValid: false,

    states: [],
    years: getCardYears(),
    pageTitle: ""
  };

  componentDidMount() {

    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: client + PAGE_TITLES.PAY_NOW })

    let product;

    if (this.props.location.data) {
      product = this.props.location.data.selectedProduct;
      product['clientName'] = this.props.location.data.clientName;
      product['fromScreen'] = this.props.location.data.fromScreen;
      sessionStorage.setItem('payNowProduct', JSON.stringify(product))
    }
    else if (sessionStorage.getItem('payNowProduct')) {
      product = JSON.parse(sessionStorage.getItem('payNowProduct'))
    }
    else {
      product = null
    }

    if (product) {
      this.getPayNow(product.policyNumber, product.fromScreen);
    }
  }

  getPayNow = (policyNumber, type) => {
    let { client } = JSON.parse(localStorage.getItem("initialIds"));
    let user = JSON.parse(localStorage.getItem("user"));

    let params = {
      "client": client,
      "accountNumber": user.accountNumber,
      "policyNumber": policyNumber,
      "firstName": user.firstName,
      "lastName": user.lastName,
      "userid": user.userid,
      "csUserid": ""
    }

    this.props.setLoading(true)
    this.paymentCenterService.getPayNow(params, type).subscribe((response) => {
      this.props.setLoading(false)
      if (response.status === 200) {
        let product = response.data.activeProducts[0];
        product['clientName'] = response.data.clientName;
        this.setState({
          product: product
        }, () => {
          setTimeout(() => {
            if (this.state.product.paymentAction.payNowLink.message) {
              let _messages = [{
                type: 'INFO',
                content: [this.state.product.paymentAction.payNowLink.message]
              }]

              this.props.notificationContext.processMessages(_messages);
            }
          }, 500)

          this.getPaymentMethods();

          this.globalWC_getContent([
            ARTICLES.PAYNOW_INSURANCE,
            ARTICLES.PAYNOW_NON_INSURANCE,
            ARTICLES.PAYNOW_INSURANCE_NOBILL
          ]);
        })
        this.props.notificationContext.processMessages(response.data.messages);
      }else {
        this.props.notificationContext.processMessages(response.data.messages);
      }
    })
  }

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items && response.data.items.forEach(element => {
          webContents[element.title] = element.content;
        });
        this.setState({
          globlWebContents: webContents
        });
        // document.getElementById("globalWC_headerPhoneInfo").innerHTML = response.data.content;
      }
    });
  };

  getAttestationContent = (rawContent) => {
    var content = rawContent;
    let dataMap, _dataMap;
    let product = this.state.product

    let paymentMethod = this.state.paymentMethods
      .find(item => item.paymentRecordId === this.state.selectedPaymentMethodRecId)

    let paymentMethodType = paymentMethod.creditCard ? 'creditCard' : 'bankAccount' // credit card or bank account!

    let user = JSON.parse(localStorage.getItem("user"));

    let { clientPhoneNumber } = JSON.parse(localStorage.getItem("initialIds"));

    // Determin financial institution
    let accountStr = paymentMethod.creditCard ? '' : ' account';
    let financialInstitutionName = paymentMethod[paymentMethodType] ? (paymentMethod[paymentMethodType].financialInstitutionName + accountStr) : '';

    dataMap = [
      { field: '[$CURRENT_DATE$]', value: `${MONTHS_LONG[new Date().getMonth()]} ${new Date().getDate()}, ${new Date().getFullYear()}` }, // Month DD, YYYY
      { field: '[$FULL_NAME$]', value: `${user.firstName} ${user.lastName}` },
      { field: '[$PRODUCT_NAME$]', value: `${product.productDesc}` },
      { field: '[$CLIENT_NAME$]', value: product.clientName },
      { field: '[$TOLL_FREE$]', value: clientPhoneNumber },
      { field: '[$USER_NAME$]', value: `${user.firstName} ${user.lastName}` },
      { field: '[$DUE_DATE$]', value: `${product.dueDate}` },
      { field: '[$FIN_INSTITUTION$]', value: `${financialInstitutionName}` },
      { field: '[$ACCOUNT_NUM$]', value: `${paymentMethod[paymentMethodType] && paymentMethod[paymentMethodType].accountNumberLast4Digit}` },
    ];

    if (product.billingFeeInd === 'Y') {
      let billingFeeObj = product.billingFees.find((item) => item.code === '04'); // Code for Direct Billing
      let amountDue = convertStringToAmount(product.amountDue);
      let billFee = convertStringToAmount(billingFeeObj.value2);

      _dataMap = [
        { field: '[$AMOUNT_DUE$]', value: `${product.amountDue}` },
        { field: '[$PREMIUM$]', value: `$${(amountDue - billFee).toFixed(2)}` },
        { field: '[$BILLING_FEE$]', value: `${billingFeeObj.value2}` },
      ]
    } else {
      _dataMap = [
        { field: '[$AMOUNT_DUE$]', value: `${product.amountDue}` },
      ]
    }

    dataMap = dataMap.concat(_dataMap)

    dataMap.forEach((item) => {
      content = content.replaceAll(item.field, item.value);
    })

    return content;
  }

  getPaymentMethods = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let { companyId, client } = JSON.parse(localStorage.getItem("initialIds"));
    let paymentMethods = [];
    let selectedPaymentMethod = null;
    let addOptionObj = {
      paymentRecordId: "ADD_NEW_PAYMENT_METHOD",
      paymentMethodHeader: "Add new payment method"
    }

    let params = {
      client: client,
      accountNumber: user.accountNumber,
      policyNumber: user.policyNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      userid: user.userid,
      companyId: companyId
    };

    this.props.setLoading(true);
    this.paymentCenterService.getPaymentMethods(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200 && response.data.paymentMethods) {
        response.data.paymentMethods.length !== 0 && this.setState({ hasAnyPaymentMethods: true });
        paymentMethods = response.data.paymentMethods;

        if (this.state.product.currentPaymentMethodRecordId) {
          selectedPaymentMethod = response.data.paymentMethods.find(item => item.paymentRecordId === this.state.product.currentPaymentMethodRecordId);
        } else {
          selectedPaymentMethod = response.data.paymentMethods[0];
        }

      }

      // Add 'Add new payment method'
      const found = paymentMethods.some(paymentMethod => paymentMethod.paymentRecordId === "ADD_NEW_PAYMENT_METHOD");
      if (!found) {
        paymentMethods.push(addOptionObj);
      }

      // selectedPaymentMethod = selectedPaymentMethod || addOptionObj;

      if (selectedPaymentMethod === null || selectedPaymentMethod === undefined) {
        selectedPaymentMethod = addOptionObj
      }

      this.setState({
        paymentMethods: paymentMethods,
        paymentMethodsLoaded: true,
        selectedPaymentMethod: selectedPaymentMethod,
        selectedPaymentMethodObj: selectedPaymentMethod,
        selectedPaymentMethodRecId: selectedPaymentMethod.paymentRecordId
      }, () => {
        this.checkForExpiry(selectedPaymentMethod.paymentRecordId);
      });
    });
  };

  handleOnEdit = selectedPaymentMethod => {
    this.setState(
      {
        selectedPaymentMethod: selectedPaymentMethod
      },
      () => {
        this.setState({ showPaymentMethodPop: true });
      }
    );
  };

  handlePaymentMethodClose = (data, messages) => {
    this.setState({ showPaymentMethodPop: false });
    if (data) {
      this.props.notificationContext.processMessages(messages)
      data.push({
        paymentRecordId: "ADD_NEW_PAYMENT_METHOD",
        paymentMethodHeader: "Add new payment method"
      })
      this.setState(
        {
          paymentMethods: data,
          selectedPaymentMethod: data[0].paymentRecordId,
          selectedPaymentMethodObj: data[0],
          selectedPaymentMethodRecId: data[0].paymentRecordId,
          hasAnyPaymentMethods: true
        }
      );
    }
  };

  handleConfirmPaynowClose = data => {
    this.setState({ showConfirmPayPopup: false });
    data && this.handleSubmit();
  };

  confirmPaynow = () => {
    let content = '';
    let product = this.state.product;
    if (product.isInsuranceProduct === 'Y') {
      content = (product.billingFeeInd === 'Y') ? this.state.globlWebContents[ARTICLES.PAYNOW_INSURANCE] : this.state.globlWebContents[ARTICLES.PAYNOW_INSURANCE_NOBILL];
    } else {
      content = this.state.globlWebContents[ARTICLES.PAYNOW_NON_INSURANCE];
    }

    this.setState({ confirmPopupContent: this.getAttestationContent(content) }, () => {
      this.setState({ showConfirmPayPopup: true });
    })
  };

  handleSubmit = async () => {
    let { companyId, client, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let user = JSON.parse(localStorage.getItem("user"));
    let paymentMethodObj = this.state.paymentMethods.find(item => item.paymentRecordId === this.state.selectedPaymentMethodRecId);
    let hostName = document.location.hostname;

    let params = {
      hostName: hostName,
      client: client,
      domainGroupId: domainGroupId,
      companyId: companyId,
      accountNumber: user.accountNumber,
      policyNumber: this.state.product.policyNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      userid: user.userid,
      totalPaymentAmount: this.state.product.amountDue,

      paymentMethod: paymentMethodObj.paymentMethodHeader,
      productName: this.state.product.productDesc,
      paymentType: paymentMethodObj.bankAccount ? paymentMethodObj.bankAccount.paymentType : paymentMethodObj.creditCard.paymentType,
      lastFourDigit: paymentMethodObj.bankAccount ? paymentMethodObj.bankAccount.accountNumberLast4Digit : paymentMethodObj.creditCard.accountNumberLast4Digit,

      paymentDetails: [
        {
          accountNumber: user.accountNumber,
          policyNumber: this.state.product.policyNumber,
          paymentAmount: this.state.product.amountDue,
          paymentMethodRecordId: paymentMethodObj.paymentRecordId
        }
      ]
    };

    this.props.setLoading(true);
    this.paymentCenterService.submitPayNow(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {
        let messages = this.props.notificationContext.getCleanMessages(response.data.messages);
        let message = '';
        messages.forEach((_message) => {
          message = message + _message.content[0] + "<br><br>";
        })
        this.setState({ payNowSuccessMsg: message }, () => { this.setState({ showPayNowSuccessPopup: true }) })
      } else {
        this.props.notificationContext.processMessages(response.data.messages);
      }
    });
  };

  handleDropDownChange = e => {
    const { name, value } = e.target;
    if (value === "ADD_NEW_PAYMENT_METHOD") {
      this.handleOnEdit(null);
    } else {
      this.setState({
        [name]: value,
        selectedPaymentMethodObj: this.state.paymentMethods.find(item => item.paymentRecordId === value)
      }, () => {
        this.checkForExpiry(value)
      });
    }
  };

  checkForExpiry = (value) => {
    // detect change in payment methods drop down to show confirm popup right on dropdown change
    let paymentMethodObj = this.state.paymentMethods.find(item => item.paymentRecordId === value);
    let type = paymentMethodObj.creditCard ? 'creditCard' : 'bankAccount';

    if (type === 'creditCard' && paymentMethodObj.isCardExpired === 'Y') { // If expired card, push the new message in messages array
      let newMsg = [{
        messageId: 'EXPIRED_CARD',
        type: 'ERROR',
        content: [paymentMethodObj.cardExpMessage]
      }]
      this.props.notificationContext.pushNonInfoMsg(newMsg);

      this.setState({ isFormValid: false })
    } else {
      // If valid/not-expired card or bank account, remove the previously added error message
      this.props.notificationContext.popNonInfoMsg('EXPIRED_CARD');
      this.setState({ isFormValid: true })
    }
  }

  handleSuccessPayNowClose = () => {
    this.setState({ showPayNowSuccessPopup: false }, () => { this.props.history.goBack() })
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.PAY_NOW] || this.state.pageTitle}</title>
        </Helmet>
        <div>
          {this.state.product &&
            <>
              {this.state.showPaymentMethodPop && (
                <UpdatePaymentMethods
                  {...this.props}
                  states={this.state.states}
                  years={this.state.years}
                  selectedPaymentMethod={this.state.selectedPaymentMethod}
                  show={this.state.showPaymentMethodPop}
                  hide={this.handlePaymentMethodClose}
                />
              )}

              {this.state.showConfirmPayPopup &&
                <ConfirmPayNow
                  {...this.props}
                  show={this.state.showConfirmPayPopup}
                  hide={this.handleConfirmPaynowClose}
                  content={this.state.confirmPopupContent}
                />}

              {this.state.showPayNowSuccessPopup &&
                <StaticContentModal
                  show={this.state.showPayNowSuccessPopup}
                  hide={this.handleSuccessPayNowClose}
                  title={'Payment Successful'}
                  content={this.state.payNowSuccessMsg}
                  {...this.props}
                />
              }

             
                <div className='paymentCenterContent payNowTemplate'>
                  <h1 className='mainHeadingT'>Pay Now</h1>
                  {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)} 
                  {this.state.paymentMethodsLoaded && (
                    <div>
                      {this.props.globalContext.state.deviceWidth >= 768 && (
                        <PayNowDesktop
                          //
                          {...this.props}
                          product={this.state.product}
                          paymentMethods={this.state.paymentMethods}
                          handleOnEdit={this.handleOnEdit}
                          confirmPaynow={this.confirmPaynow}
                          handleDropDownChange={this.handleDropDownChange}
                          selectedPaymentMethodRecId={this.state.selectedPaymentMethodRecId}
                          selectedPaymentMethodObj={this.state.selectedPaymentMethodObj}
                          isFormValid={this.state.isFormValid}
                          hasAnyPaymentMethods={this.state.hasAnyPaymentMethods}
                        />
                      )}

                      {this.props.globalContext.state.deviceWidth <= 767 && (
                        <PayNowMobile
                          //
                          {...this.props}
                          product={this.state.product}
                          paymentMethods={this.state.paymentMethods}
                          handleOnEdit={this.handleOnEdit}
                          confirmPaynow={this.confirmPaynow}
                          handleDropDownChange={this.handleDropDownChange}
                          selectedPaymentMethodRecId={this.state.selectedPaymentMethodRecId}
                          selectedPaymentMethodObj={this.state.selectedPaymentMethodObj}
                          isFormValid={this.state.isFormValid}
                          hasAnyPaymentMethods={this.state.hasAnyPaymentMethods}
                        />
                      )}
                    </div>
                  )}
                </div>
        
            </>
          }

        </div>
      </>
    );
  }
}
