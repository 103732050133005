import React, { Component } from "react";
import { NOTIFICATIONS_TYPE } from "../../../_constants/constants";
import { NotificationContext } from '../../../_context/notifications.context'

export class Notification extends Component {
  messageTimeout;

  constructor(props) {
    super(props);

    if (!this.checkAutoCloseNotification()) {
      // this.selfClose(); // commenting based on 700
    }
  }

  componentWillUnmount() {
    clearInterval(this.messageTimeout);
  }

  checkAutoCloseNotification = () => {
    return this.props.message.type === "INFO";
  };

  selfClose = () => {
    // this.messageTimeout = setTimeout(() => {
    //   this.context.resetNonInfoNotification()
    //   this.context.resetPopupNotifications()
    // }, 10000);
  };

  manualClose = () => {
    let index = this.props.id;
    let notificationsInfo = Object.assign([], this.context.state.infoNotification);
    let notificationsInfoPopup = Object.assign([], this.context.state.popupNotifications);

    if (notificationsInfo.length > 0) notificationsInfo.splice(index, 1);
    if (notificationsInfoPopup.length > 0) notificationsInfoPopup.splice(index, 1);

    this.context.setInfoNotification(notificationsInfo);
    this.context.setPopupNotifications(notificationsInfoPopup)
  }

  render() {
    return (
      <>
        <div className={"col-12 float-left notification-box " + NOTIFICATIONS_TYPE[this.props.message.type]}>

          {/* Show close icon or not */}
          {this.checkAutoCloseNotification() ? (
            <span className='notification-close materialicons' onClick={this.manualClose}>close</span>
          ) : ''}

          {/* Actual message */}
          <span className="message" dangerouslySetInnerHTML={{ __html: this.props.message.content ? this.props.message.content.join("\n") : "" }}></span>
        </div>
      </>
    );
  }
}

Notification.contextType = NotificationContext; 
