import React from "react";
import { OverlayTrigger, Tooltip, Overlay, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ARTICLES } from "../../../../../_constants/constants";
import { convertStringToAmount } from '../../../../../_services/utility.service';

export class ProductsDesktop extends React.Component {

  state = {
    showToolTip: false,
    toolTipTarget: null
  }

  getDisclaimers = () => {
    let disclaimerTypesMap = [
      { name: 'autopay', content: this.props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER] },
      { name: 'combinedbill', content: this.props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING] },
      { name: 'nocosttoyou', content: this.props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_NOCOST] },
    ]
    let components = [];
    let iterated = [];
    this.props.products.forEach((product, index) => {
      let disclaimerObj = disclaimerTypesMap.find((item) => item.name === product.paymentStatusDisplayStyle);
      let hasAlreadyIterated = !iterated.includes(product.paymentStatusDisplayStyle);

      if (disclaimerObj && hasAlreadyIterated) {
        components.push(<div key={index} dangerouslySetInnerHTML={{ __html: disclaimerObj.content }} ></div>)
        iterated.push(product.paymentStatusDisplayStyle);
      }
    });

    return components;
  }


  getPayNowBtn = () => {
    let isAutoPay = this.props.selectedProduct && this.props.selectedProduct.paymentStatus === "Auto Pay";
    let btnLabel = this.props.selectedProduct ? this.props.selectedProduct.paymentAction.payNowLink.label : 'Pay Now';
    if (this.props.selectedProduct === undefined || isAutoPay) {
      return (
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 150 }}
          overlay={
            <Tooltip>
              <div className='tooltipHover buttonTooltip'>
                <div className='flexBox' id='globalWC_payNow'>
                  {isAutoPay ? this.props.globlWebContents[ARTICLES.PRODUCTS_AUTO_TOOLTIP] : this.props.globlWebContents[ARTICLES.PRODUCTS_NO_RADIO]}
                </div>
              </div>
            </Tooltip>
          }>
          <button className='btn agiaBlueButton disabled' variant='success'>
            {btnLabel}
          </button>
        </OverlayTrigger>
      );
    } else {
      // let isDue = this.props.selectedProduct && this.props.selectedProduct.paymentStatus && this.props.selectedProduct.paymentStatus.toLowerCase() === "due now";
      let enabled = this.props.selectedProduct && this.props.selectedProduct.paymentAction.payNowLink.disable !== 'Y';
      return (
        <button
          onClick={() => {
            this.props.handlePayNow(null);
          }}
          disabled={!enabled}
          className='ma-pc-payNow btn agiaBlueButton payNow'
          variant='success'
          type='button'
          value='Submit'>
          {btnLabel}
        </button>
      );
    }
  };

  getChangeFreqBtn = () => {
    let btnLabel = this.props.selectedProduct ? this.props.selectedProduct.paymentAction.changeAutoPayOrFrequency.label : 'Change Auto Pay or Frequency';
    let isPastDue;

    // Check whether the selected product is due now or past due. 
    if (this.props.selectedProduct && this.props.selectedProduct.paymentStatusDisplayStyle === "pastdue") {
      isPastDue = true;
    } else {
      isPastDue = false;
    }

    if (this.props.selectedProduct === undefined) {
      return (
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 150 }}
          overlay={
            <Tooltip>
              <div className='tooltipHover'>
                <div className='flexBox' id='globalWC_changeFreqBtn'>
                  {this.props.globlWebContents[ARTICLES.TOOLTIP_FREQUENCY_NO_SELECTION]}
                  {/* First select a product that you wish to change */}
                </div>
              </div>
            </Tooltip>
          }>
          <button className='btn agiaBlueButton-outline disabled' type='button' value='Submit' data-toggle='modal' data-target='#contactInformation'>
            {btnLabel}
          </button>
        </OverlayTrigger>
      );
    }
    else if (isPastDue) {
      let enabled = this.props.selectedProduct && this.props.selectedProduct.paymentAction.changeAutoPayOrFrequency.disable !== 'Y';
      return (
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 150 }}
          overlay={
            <Tooltip>
              <div className='tooltipHover'>
                <div className='flexBox'>
                  {this.props.globlWebContents[ARTICLES.TOOLTIP_DUE_FREQUENCY]}
                  {/* First select a product that you wish to change */}
                </div>
              </div>
            </Tooltip>
          }>
          <button
            className={'btn agiaBlueButton-outline ' + (!enabled && 'disabled')}
            type='button'
            value='Submit'
            data-toggle='modal'
            data-target='#contactInformation'>
            {btnLabel}
          </button>
        </OverlayTrigger>
      );
    }
    else {
      let enabled = this.props.selectedProduct && this.props.selectedProduct.paymentAction.changeAutoPayOrFrequency.disable !== 'Y';
      return (
        <button
          onClick={() => {
            enabled && this.props.handleChangeFreqAutoPay(this.props.selectedProduct);
          }}
          className={'ma-pc-autopayFrequency btn agiaBlueButton-outline ' + (!enabled && 'disabled')}
          variant='success'
          type='button'
          value='Submit'>
          {btnLabel}
        </button>
      );
    }
  };

  handleToolTip = (event, policyNumber) => {
    event.persist();
    this.setState({ showToolTip: false });
    setTimeout(async () => {
      !this.props.selectedProduct.paymentInfoLoaded && await this.props.getPaymentInfo(policyNumber)
      this.setState({
        showToolTip: !this.state.showToolTip,
        toolTipTarget: event.target
      })
    }, 200)
  };

  render() {
    return (
      <div className='desktopView productsTemplate'>
        {this.props.selectedProduct && this.props.selectedProduct.showPaymentAmountBreakdown && <Overlay
          show={this.state.showToolTip}
          target={this.state.toolTipTarget}
          placement="auto"
          rootClose={true}
          rootCloseEvent={'click'}
          onHide={() => { this.setState({ showToolTip: false }) }}
        >
          <Popover id="popover-contained">
            <div className='tooltipHover payment-amount-breakdown'>
              <div className='flexBox'>
                <ul>
                  <li>
                    <span className="label">AMOUNT DUE</span>
                    <span className="value">{this.props.selectedProduct.paymentInfo.amountDue || '-'}</span>
                    <span className="tooltip-break-special-chars"><sup>{this.props.selectedProduct.paymentInfo.amountDueSpecialCharacter}</sup></span>
                  </li>
                  <li>
                    <span className="label">Past Due</span>
                    <span className="value">{this.props.selectedProduct.paymentInfo.pastDueAmount || '-'}</span>
                    <span className="tooltip-break-special-chars"></span>
                  </li>
                  <li>
                    <span className="label">Adjustment</span>
                    <span className="value">{this.props.selectedProduct.paymentInfo.adjustment || '-'}</span>
                    <span className="tooltip-break-special-chars">{convertStringToAmount(this.props.selectedProduct.paymentInfo.adjustment) > 0 && '\u00A0CR'}</span>

                  </li>
                  {convertStringToAmount(this.props.selectedProduct.paymentInfo.billingCredit) !== 0 &&
                    < li >
                      <span className="label">Billing Credit</span>
                      <span className="value">{this.props.selectedProduct.paymentInfo.billingCredit || '-'}</span>
                      <span className="tooltip-break-special-chars">{convertStringToAmount(this.props.selectedProduct.paymentInfo.billingCredit) > 0 && '\u00A0CR'}</span>
                    </li>
                  }
                  <li>
                    <span className="label">TOTAL AMOUNT DUE</span>
                    <span className="value">{this.props.selectedProduct.paymentInfo.totalAmountDue || '-'}</span>
                    <span className="tooltip-break-special-chars"></span>
                  </li>
                  {this.props.selectedProduct.paymentInfo.disclaimer &&
                    <li>
                      <span className="tooltip-breakdown-disclaimer">{this.props.selectedProduct.paymentInfo.disclaimer}</span>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </Popover>
        </Overlay>}

        <h2 className='homeHederText'>Active Products</h2>
        <table className='table table-light borderless productTable'>
          <thead>
            <tr>
              <th scope='col'>&nbsp;</th>
              <th className="product-col" scope='col'>PRODUCT</th>
              <th className="payment-frequency-col" scope='col'>PAYMENT FREQUENCY</th>
              <th className="auto-pay-method-col" scope='col'>AUTO PAY METHOD</th>
              <th scope='col' className='amount amount-col'>
                AMOUNT DUE
              </th>
              <th className="duedate-col" scope='col'>DUE DATE</th>
            </tr>
          </thead>
          <tbody>
            {this.props.products.map(value => (
              <tr
                tabIndex='0'
                key={value.policyNumber}
                onClick={() => {
                  this.props.radioBtnChange(value);
                }}
                className={value.paymentStatus && value.paymentStatus.toLowerCase() === "due now" ? "selected" : ""}>
                <td>
                  <div className='custom-radio'>
                    <input
                      type='radio'
                      className='custom-control-input'
                      checked={this.props.selectedProduct && this.props.selectedProduct.policyNumber === value.policyNumber}
                      id={value.policyNumber}
                      name={value.policyNumber}
                      onChange={() => {
                        // this.radioBtnChange(value);
                      }}
                    />
                    <span className='materialicons uncheck' htmlFor={value.policyNumber}>
                      radio_button_unchecked
                    </span>
                    <span className='materialicons check' htmlFor={value.policyNumber}>
                      radio_button_checked
                    </span>
                  </div>
                </td>
                <td>
                  <Link title={""} to={`/product-details/${value.policyNumber}`}>
                    <span className='anchorText'>{value.productDesc}</span>
                  </Link>
                  <span className='smallTableText'>{value.policyNumber}</span>
                </td>
                <td>{value.currentPaymentFrequecyType}</td>
                <td>{value.currentPaymentMethod}</td>
                <td className='amount'>
                  {value.amountDue}
                  <sup dangerouslySetInnerHTML={{ __html: value.amountDueSpecialCharacter }}></sup>
                  {value.showPaymentAmountBreakdown && (
                    <>

                      <span variant='success' onClick={($event) => { this.handleToolTip($event, value.policyNumber) }} className='materialicons infoIcon'>
                        info_outline
                     </span>

                    </>
                  )}
                </td>

                <td><div className="displayFlex"><span className="dateValue">{value.dueDate}</span><span className={'paymentStatusBadge ' + value.paymentStatusDisplayStyle} value={value.paymentStatus}>
                  {value.paymentStatus}
                </span></div></td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='roundBottomButton'>
          {this.getPayNowBtn()}
          {this.getChangeFreqBtn()}
        </div>
        <div className='footerTextPara'>
          <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.AUTOPAY_MARKETTING] }} ></div>
          <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
          {this.getDisclaimers()}
          <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.TRANSACTION_DISCLAIMER] }}></div>
        </div>

      </div >
    );
  }
}
