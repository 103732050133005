import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { convertStringToAmount } from '../../../../../_services/utility.service';

export const PayNowMobile = props => {
  const [open, setOpen] = useState(false);
  const [disableControls, setDisableControls] = useState(true);

  useEffect(() => {
    let isSubmitDisabled = props.product.paymentAction.submitPayment && props.product.paymentAction.submitPayment.disable === 'Y';
    let hasMessage = props.product.paymentAction.payNowLink.hasOwnProperty('message');
    let disableControls = isSubmitDisabled && hasMessage;
    setDisableControls(disableControls)    
  }, []);

  return (
    <div className='mobileView payNowMobile'>
      <div className='singleCol'>
        <div className='flexWhiteBox'>
          <h2 className='homeHederText'>Product</h2>
          <div className='leftColumn'>
            <div className='headerColumn'>
              <h3>
                <Link title={""} to={`/product-details/${props.product.policyNumber}`}>{props.product.productDesc} </Link>
              </h3>
              <div className='btn-links'>
                <span className={'paymentStatusBadge ' + props.product.paymentStatusDisplayStyle}>
                  {props.product.paymentStatus}
                </span>
              </div>
            </div>
            <div className='bodyRow'>
              <div className='flexBox'>
                <ul>
                  <li>
                    <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                    <span className='value'>
                      {props.product.policyNumber}
                    </span>
                  </li>
                  <li>
                    <span className='label'>PAYMENT FREQUENCY</span>
                    <span className='value'>{props.product.currentPaymentFrequecyType}</span>
                  </li>
                  <li>
                    <span className='label'>DUE DATE</span>
                    <span className='value'>{props.product.dueDate}</span>
                  </li>
                  <li>
                    <span className='label'>AMOUNT DUE</span>
                    <span className='value'>
                      {!props.product.paymentInfo && <span>{props.product.amountDue}</span>}
                      {props.product.paymentInfo && (
                        <>
                          <span>
                            <Link onClick={(e) => { e.preventDefault(); setOpen(!open) }} aria-controls='payNow-collapse-text' aria-expanded={open} className='payNow-collapse-text' to='#'>
                              <span className='priceValue'>{props.product.amountDue}</span>
                              <span className='materialicons leftArrow'>chevron_left</span>
                              <span className='materialicons downArrow'>expand_more</span>
                            </Link>
                          </span>
                        </>
                      )}
                    </span>
                  </li>
                  {props.product.paymentInfo && (
                    <Collapse in={open}>
                      <div id='payNowCollapseText'>
                        <div className='bodyRow'>
                          <div className='flexBox'>
                            <ul>
                              <li>
                                <span className='label'>AMOUNT DUE</span>
                                <span className='value'>{props.product.paymentInfo.amountDue || "-"}</span>
                                <span className="tooltip-break-special-chars"><sup>{props.product.paymentInfo.amountDueSpecialCharacter}</sup></span>
                              </li>
                              <li>
                                <span className='label'>PAST DUE</span>
                                <span className='value'>{props.product.paymentInfo.pastDueAmount || "-"}</span>
                                <span className="tooltip-break-special-chars"></span>
                              </li>
                              <li>
                                <span className='label'>ADJUSTMENT</span>
                                <span className='value'>{props.product.paymentInfo.adjustment || "-"} </span>
                                <span className="tooltip-break-special-chars">{convertStringToAmount(props.product.paymentInfo.adjustment) > 0 && '\u00A0CR'}</span>
                              </li>
                              {convertStringToAmount(props.product.paymentInfo.billingCredit) !== 0 &&
                                < li >
                                  <span className="label">Billing Credit</span>
                                  <span className="value">{props.product.paymentInfo.billingCredit || '-'} </span>
                                  <span className="tooltip-break-special-chars">{convertStringToAmount(props.product.paymentInfo.billingCredit) > 0 && '\u00A0CR'}</span>
                                </li>
                              }
                              <li>
                                <span className='label'>TOTAL AMOUNT DUE</span>
                                <span className='value'>{props.product.paymentInfo.totalAmountDue || "-"}</span>
                                <span className="tooltip-break-special-chars"></span>
                              </li>
                              {props.product.paymentInfo.disclaimer &&
                                <li>
                                  <span className="tooltip-breakdown-disclaimer">{props.product.paymentInfo.disclaimer}</span>
                                </li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {props.hasAnyPaymentMethods && (
          <div className='flexWhiteBox'>
            <h2 className='homeHederText'>Payment Method</h2>
            <div className='rightColumn'>
              <div className='headerColumn'>
                <select
                  value={props.selectedPaymentMethodRecId}
                  onChange={props.handleDropDownChange}
                  name='selectedPaymentMethodRecId'
                  disabled={disableControls}>
                  {props.paymentMethods.map(value => (
                    <option key={value.paymentRecordId} value={value.paymentRecordId}>
                      {value.paymentMethodHeader}
                    </option>
                  ))}
                </select>
                <div className='btn-links-edit'>
                  <Link
                    //
                    title={""}
                    to='#'
                    className={disableControls ? 'disabled-link-btn' : ''}
                    onClick={(e) => {
                      e.preventDefault();
                      !disableControls && props.handleOnEdit(props.selectedPaymentMethodObj);
                    }}>
                    Edit
                  </Link>
                </div>
              </div>
              <div className='bodyRow'>
                <div className='flexBox'>
                  {props.selectedPaymentMethodObj.bankAccount == null && (
                    <ul>
                      <li>
                        <span className='label'>PAYMENT TYPE</span>
                        <span className='value'>{props.selectedPaymentMethodObj.paymentMethodHeader}</span>
                      </li>
                      <li>
                        <span className='label'>CARDHOLDER NAME</span>
                        <span className='value single-line-ellipses' title={props.selectedPaymentMethodObj.cardHolderName}>
                          {props.selectedPaymentMethodObj.cardHolderName}
                        </span>
                      </li>
                      <li>
                        <span className='label'>CARD NUMBER</span>
                        <span className='value'>{props.selectedPaymentMethodObj.creditCard.cardNumber}</span>
                      </li>
                      <li>
                        <span className='label'>CARD EXPIRATION</span>
                        <span className='value'>{props.selectedPaymentMethodObj.creditCard.expirationDate}</span>
                      </li>
                    </ul>
                  )}
                  {props.selectedPaymentMethodObj.creditCard == null && (
                    <ul>
                      <li>
                        <span className='label'>PAYMENT TYPE</span>
                        <span className='value'>{props.selectedPaymentMethodObj.paymentMethodHeader}</span>
                      </li>
                      <li>
                        <span className='label'>ACCOUNT-HOLDER NAME</span>
                        <span className='value'>{props.selectedPaymentMethodObj.cardHolderName}</span>
                      </li>
                      <li>
                        <span className='label'>ACCOUNT NUMBER</span>
                        <span className='value'>{props.selectedPaymentMethodObj.bankAccount.accountNumber}</span>
                      </li>
                      <li>
                        <span className='label'>ROUTING NUMBER</span>
                        <span className='value'>{props.selectedPaymentMethodObj.bankAccount.maskedRoutingNumber}</span>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {!props.hasAnyPaymentMethods && (
          <div className='flexWhiteBox payNowaddPaymentM'>
            <div className='leftColumn'>
              <div className='text'>No payment methods found</div>

              <div className='roundBottomButton'>
                <button
                  onClick={() => {
                    props.handleOnEdit(null);
                  }}
                  disabled={disableControls}
                  className='btn agiaBlueButton'
                  type='button'
                  value='Submit'>
                  <span className='materialicons'>add</span>
                  <span className='navText'>Add Payment Method</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='bottomButton equalWidth'>
        <button
          onClick={() => {
            props.history.goBack();
          }}
          className='btn agiaBlueButton-outline payNow'
          type='button'
          value='Submit'>
          Go back
        </button>
        <button
          id="ma-pn-submitPayment"
          onClick={() => {
            props.confirmPaynow();
          }}
          disabled={!props.isFormValid || !props.hasAnyPaymentMethods || disableControls}
          className='btn agiaBlueButton payNow'
          type='button'
          value='Submit'>
          Submit Payment
        </button>
      </div>
    </div>
  );
};
