import React from "react";
import { Link } from "react-router-dom";
import { ReCaptcha } from "react-recaptcha-v3";
import { SharedService } from "../../_services/shared.service";
import { AuthenticationService } from "../../_services/auth.service";
import { API_KEYS, NOTIFICATIONS, ARTICLES, PAGE_TITLES, PAGES_TITLE_KEYS } from "../../_constants/constants";
import { LOCALIZATION } from "../../_constants/localization";
import { Notification } from '../shared/notifications/notifications';
import { Helmet } from "react-helmet";

export class ForgotPassword extends React.Component {
  sharedService = new SharedService();
  authenticationService = new AuthenticationService();

  constructor(props, context) {
    super(props, context);

    this.state = {
      username: "",
      isBot: true,
      isFormValid: false,
      pageTitle: ""
    };
  }

  componentDidMount() {
    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: client + PAGE_TITLES.FORGOT_PASSWORD })

    this.props.setLoading(true);
    this.globalWC_getContent([ARTICLES.FORGOT_PASSWORD_INFO]);
  }

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        response.data.items && response.data.items.forEach(element => {
          document.getElementById(`globalWC_${element.title}`).innerHTML = element.content;
        });
      }
    });
  };

  handleSubmit = () => {
    if (!this.state.isBot) {
      if (this.checkValidation()) {
        let { companyId, client } = JSON.parse(localStorage.getItem("initialIds"));
        let params = `/userName/${this.state.username}/companyId/${companyId}/client/${client}`;

        this.props.setLoading(true);
        this.authenticationService.forgotPassword1(params).subscribe(response => {
          if (response.status === 200) {
            this.props.history.push({
              pathname: "/forgot-password-security-question",
              data: response.data
            });
          } else {
            this.props.notificationContext.processMessages(response.data.messages)
          }
          this.props.setLoading(false);
        });
      }
    } else {
      alert(NOTIFICATIONS.BOT_SUSPECTED);
    }
  };

  verifyCallback = recaptchaToken => {
    // Here you will get the final recaptchaToken!!!
    if (recaptchaToken) {
      let params = {
        token: recaptchaToken
      }
      
      this.props.setLoading(true);
      this.sharedService.verifyCaptcha(params).subscribe(response => {
        this.props.setLoading(false);
        if (response.status === 200) {
          this.setState({
            isBot: response.data.isBot
          });
        }
      });
    } else {
      this.props.setLoading(false);
    }
  };

  checkValidation = () => {
    if (/^\s*$/.test(this.state.username)) {
      // setErrMsg && this.setState({ errorMessage: 'Please provide a username' });
      this.setState({ isFormValid: false });
      return false;
    }

    this.setState({ isFormValid: true });
    // setErrMsg && this.setState({ errorMessage: '' });
    return true;
  };

  handleChange = e => {
    // e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.checkValidation();
    });
  };
  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  render() {
    return (
      <>

        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.FORGOT_PASSWORD] || this.state.pageTitle}</title>
        </Helmet>

        <div className='usernameWrapper'>
          <div className='lusernameColumn mobileButton'>
            {/* <form  className="was-validation" noValidate> */}
            <h1 className="mainHeadingT">{LOCALIZATION.EN.FORGOT_PASSWORD_1}</h1>
            {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
            {/* {this.state.errorMessage !== "" ? <div className='warningAlert p-2 mt-3'>{this.state.errorMessage}</div> : null} */}
            <div id={`globalWC_${ARTICLES.FORGOT_PASSWORD_INFO}`}></div>
            {/* <p>Enter the password for your online Xxxxxx account, and we'll send you instructions to reset your password.</p>
          <p>
            If you haven't registered for online access with Spire, you'll need to register first by
            <Link title={""} to='/account-details'>
              <span> creating a new login</span>
            </Link>
          </p> */}
            <div className="backgroundSetColor">
              <div className='formLayout'>
                <div className='form-group'>
                  <div className='username'>
                    <label htmlFor='username'>Username</label>
                    <input className='form-control' type='text' onKeyDown={this.handleKeyDown} id='username' onChange={this.handleChange} name='username' required />
                    {/* <a title="" className="findPlanText" href="#">Don't know your username?</a> */}
                    <Link
                      title={""}
                      to='/#'
                      onClick={e => {
                        e.preventDefault();
                        this.props.history.push('/forgot-username')
                      }}
                      className='findPlanText'>
                      Don't know your username?
                </Link>
                    <div className='invalid-feedback'>Please fill out this field.</div>
                  </div>
                </div>
                <ReCaptcha sitekey={API_KEYS.RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
              </div>
              {/* <div className="captcha">
              <iframe className='recaptcha-v3-iframe' src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;logging=true&amp;k=6LeuMjIUAAAAAODtAglF13UiJys0y05EjZugej6b&amp;co=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbTo0NDM.&amp;hl=en&amp;v=pRiAUlKgZOMcFLsfzZTeGtOA&amp;size=invisible&amp;cb=mrb3yb91jnco" width="256" height="60" role="presentation" name="a-ox28xci2mgb0" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe>
              </div> */}

            </div>
            <div className="roundBottomButton">
              <button className={"btn btn-primary agiaBlueButton mt-3 " + (!this.state.isFormValid && "disabled")} onClick={this.handleSubmit}>
                Next: Security Verification
            </button>
              <Link to="/">Back to login</Link>
            </div>
            {/* </form> */}
          </div>
        </div>
      </>
    );
  }
}
