import React from "react";

export class ImpersonateHeader extends React.Component {
    user = JSON.parse(localStorage.getItem('user'));

    render() {
        return (
            <div className="impersonateHeader">
                <label className='materialicons'>person</label>
                <label className='navText'>{this.user.csUserid}</label>
            </div>
        )
    }
}

