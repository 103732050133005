import React from "react";
import { formatAccountNumber, formatRoutingNumber, numberAndDash } from "../../../../_services/utility.service";
import { GlobalContext } from "../../../../_context/global.context";

export class BankAccountForm extends React.Component {
  checkZip = ({ target: { value, name } }) => {
    const isValid = numberAndDash(value);
    isValid &&
      this.props.inputChange({
        target: {
          name: name,
          value: value
        }
      });
  };

  formatRouting = e => {
    const isValid = numberAndDash(e.target.value);
    if (isValid) {
      const caret = e.target.selectionStart;
      const element = e.target;
      const formattedNum = formatRoutingNumber(e.target.value);
      this.props.inputChange({
        target: {
          name: e.target.name,
          value: formattedNum
        }
      });

      window.requestAnimationFrame(() => {
        let _caret = caret + 1;
        element.selectionStart = _caret;
        element.selectionEnd = _caret;
      });
    }
  };

  formatAccount = ({ target: { value, name } }) => {
    const isValid = numberAndDash(value);
    if (isValid) {
      const formattedNum = formatAccountNumber(value);
      this.props.inputChange({
        target: {
          name: name,
          value: formattedNum
        }
      });
    }
  };

  render() {
    return (
      <div className='formPanel mainPopupWraper'>
        <GlobalContext.Consumer>
          {context => (
            <>
              <div className="leftPanel">
                <h2 className='bInfoText'>Payment Information</h2>
              <img alt={""} src={require("../../../../_assets/images/check_picture_u1399.jpg").default} width='100%' height='auto' />
              <div className='rememberPay mt-4 mb-3'>
                <div className='mb-3'>
                  <div className="custom-control-add custom-radio">
                    <input type='radio' className='custom-control-input mr-1' id='Checking' checked={this.props.form.type === "C"} value='C' onChange={this.props.inputChange} name='type'/>
                    <span className="materialicons uncheck" htmlFor="Checking" >radio_button_unchecked</span>
                    <span className="materialicons check" htmlFor="Checking">radio_button_checked</span> <span htmlFor='Checking'>Checking</span>
                    </div>
                </div>
                <div className='mb-3'>
                  <div className="custom-control custom-radio">
                    <input type='radio' id='Savings' checked={this.props.form.type === "S"} value='S' onChange={this.props.inputChange} name='type' className='custom-control-input mr-1' />
                    <span className="materialicons uncheck" htmlFor="Savings" >radio_button_unchecked</span>
                    <span className="materialicons check" htmlFor="Savings">radio_button_checked</span> <span htmlFor='Savings'>Savings</span>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='oldPassword'>
                  <label htmlFor='oldPassword'>Account-holder Name</label>
                  <input
                    className='form-control'
                    type='text'
                    value={this.props.form.accountName}
                    onChange={this.props.inputChange}
                    onKeyDown={this.props.handleKeyDown}
                    name='accountName'
                    disabled={this.props.form.editMode}
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='newPassword'>
                  <label htmlFor='newPassword'>Bank Routing Number</label>
                  <input
                    //
                    className='form-control'
                    type='text'
                    value={this.props.form.originalRoutingNumber}
                    onChange={this.formatRouting}
                    onKeyDown={this.props.handleKeyDown}
                    name='originalRoutingNumber'
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='confirmPwd'>
                  <label htmlFor='confirmPwd'>Confirm Bank Routing Number</label>
                  <input
                    className='form-control'
                    type='text'
                    value={this.props.form.confirmRountingNum}
                    onChange={this.formatRouting}
                    onKeyDown={this.props.handleKeyDown}
                    name='confirmRountingNum'
                  />
                </div>
              </div>

              <div className='form-group'>
                <div className='answer'>
                  <label htmlFor='answer'>Account Number</label>
                  <input
                    className='form-control'
                    type='text'
                    value={this.props.form.accountNum}
                    onChange={this.formatAccount}
                    onKeyDown={this.props.handleKeyDown}
                    name='accountNum'
                    disabled={this.props.form.editMode}
                  />
                </div>
              </div>
              {!this.props.form.editMode && (
                <div className='form-group'>
                  <div className='answer'>
                    <label htmlFor='answer'>Confirm Account Number</label>
                    <input
                      className='form-control'
                      type='text'
                      value={this.props.form.confirmAccountNum}
                      onChange={this.formatAccount}
                      onKeyDown={this.props.handleKeyDown}
                      name='confirmAccountNum'
                      disabled={this.props.form.editMode}
                    />
                  </div>
                </div>
              )}
            </div>
              <div className="rightPanel">
                <h2 className='bInfoText'>Billing Information</h2>
               <div className='form-group'>
                <div className='address'>
                  <label htmlFor='address'>Address 1</label>
                  <input
                    className='form-control'
                    type='text'
                    maxLength='30'
                    name='address1'
                    value={this.props.form.address1}
                    onChange={this.props.inputChange}
                    onKeyDown={this.props.handleKeyDown}
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='address'>
                  <label htmlFor='address'>Address 2</label>
                  <input
                    className='form-control'
                    type='text'
                    maxLength='30'
                    name='address2'
                    value={this.props.form.address2}
                    onChange={this.props.inputChange}
                    onKeyDown={this.props.handleKeyDown}
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='address'>
                  <label htmlFor='address'>Address 3</label>
                  <input
                    className='form-control'
                    type='text'
                    maxLength='30'
                    name='address3'
                    value={this.props.form.address3}
                    onChange={this.props.inputChange}
                    onKeyDown={this.props.handleKeyDown}
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='city'>
                  <label htmlFor='city'>City</label>
                  <input
                    className='form-control'
                    type='text'
                    name='city'
                    value={this.props.form.city}
                    onChange={this.props.inputChange}
                    onKeyDown={this.props.handleKeyDown}
                    maxLength='30'
                  />
                </div>
              </div>

              <div className='form-group displayFlex'>
                <div className='state'>
                  <label htmlFor='state'>State</label>
                  <select name='selectedState' value={this.props.form.selectedState} onChange={this.props.inputChange}>
                    <option value=''>Please Select</option>
                    {context.state.states.map(value => (
                      <option key={value.stateCode} value={value.stateCode}>
                        {value.stateCode}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='zipCode'>
                  <label htmlFor='zipCode'>Zip</label>
                  <input
                    className='form-control'
                    type='text'
                    name='zip'
                    value={this.props.form.zip}
                    onChange={this.checkZip}
                    onKeyDown={this.props.handleKeyDown}
                    maxLength='10'
                    required='required'
                  />
                </div>

                {/* <div className='zipCode'>
            <label htmlFor='zipCode'>Zip Code</label>
            <input className='form-control' type='text' id='zipcode' name='Zip Code' />
          </div> */}
              </div>
             </div> 
            </>
          )}
        </GlobalContext.Consumer>
      </div>
    );
  }
}
