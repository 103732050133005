import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AuthenticationService } from "../_services/auth.service";
import { Login, CustomerLogin } from "../_components/login";
import { RegisterMigratedUser } from "../_components/register-migrated/RegisterMigratedUser";
import { CustomerHomePage } from "../_components/customer-home";
import { ForgotUserName, ForgotUserConfirmation, ForgotPassword, ForgotPasswordSecurityQues } from "../_components/forgot";
import { ResetPassword } from "../_components/reset-password";
import { AccountDetails, PersonalInfo, SignInCreds } from "../_components/register";
import { Dashboard } from "../_components/my-account/dashboard/Dashboard";
import { MyProfile } from "../_components/my-account/profile/my-profile/MyProfile";
import { PayNow } from "../_components/my-account/payment-center/pay-now/PayNow";
import { ProductsMain } from "../_components/my-account/products-main/ProductsMain";
import { PaymentCenter } from "../_components/my-account/payment-center/PaymentCenter";
import { PaymentMethods } from "../_components/my-account/payment-center/payment-methods/PaymentMethods";
import { Products } from "../_components/my-account/payment-center/products/Products";
import { PaymentHistory } from "../_components/my-account/payment-center/payment-history/PaymentHistory";
import { ProductDetails } from "../_components/my-account/products-main/product-details/ProductDetails";
import { Unsubscribe } from '../_components/unsubscribe/Unsubscribe'

export var isCustomer = function () {
  return localStorage.getItem('initialIds') && JSON.parse(localStorage.getItem('initialIds')).isCustomerSite;// window.location.href.indexOf('customer.com') !== -1
};

/* let initComponent =  isCustomer() ? CustomerLogin : Login;

let customerDashboard = isCustomer() ? {
    path: '/customer-home',
    component: CustomerHomePage,
    exact: true,
    auth: true
  }: {};
 */
export const AppRoutes = {
  routes: [
    {
      path: "/",
      component: "component",
      exact: true
    },
    {
      path: "/logging-in/:id",
      component: Login,
      exact: true
    },
    {
      path: "/forgot-username",
      component: ForgotUserName,
      exact: true
    },
    {
      path: "/forgot-password",
      component: ForgotPassword,
      exact: true
    },
    {
      path: "/forgot-password-security-question",
      component: ForgotPasswordSecurityQues,
      exact: true
    },
    {
      path: "/reset-password/:id",
      component: ResetPassword,
      exact: true
    },
    {
      path: "/email-confirmation",
      component: ForgotUserConfirmation,
      exact: true
    },
    {
      path: "/account-details",
      component: AccountDetails,
      exact: true
    },
    {
      path: "/personal-info",
      component: PersonalInfo,
      exact: true
    },
    {
      path: "/sign-in-creds",
      component: SignInCreds,
      exact: true
    },
    {
      path: "/reactivate-account",
      component: RegisterMigratedUser,
      exact: true
    },
    {
      path: "/dashboard",
      component: Dashboard,
      auth: true
    },
    {
      path: "/my-profile",
      component: MyProfile,
      auth: true
    },
    {
      path: "/products-main",
      component: ProductsMain,
      auth: true
    },
    {
      path: "/payment-center",
      component: PaymentCenter,
      auth: true
    },
    {
      path: "/product-details/:id",
      component: ProductDetails,
      auth: true
    },
    {
      path: "/unsubscribe/:id",
      component: Unsubscribe
    }
  ]
};

export class RouteBody extends Component {
  authenticationService = new AuthenticationService();

  constructor(props) {
    super(props);

    this.state = {
      body: this.prepareRoutes()
    };
  }

  prepareRoutes = () => {
    //let isCustomer = localStorage.getItem('initialIds') && JSON.parse(localStorage.getItem('initialIds')).isCustomerSite ;
    let initComponent = isCustomer() ? CustomerLogin : Login;

    AppRoutes.routes.forEach((route) => {
      route.component = route.component === 'component' ? initComponent : route.component
    });

    let customerDashboard = isCustomer() ? {
      path: '/customer-home',
      component: CustomerHomePage,
      exact: true,
      auth: true
    } : {};

    AppRoutes.routes.push(customerDashboard);
    let routes = AppRoutes.routes.filter((route) => route.hasOwnProperty('path'));
    let body = routes.map((routesInfo, index) => {
      if (routesInfo.hasOwnProperty("auth") && routesInfo.auth === true) { // Routes for logged in user
        return (
          <Route
            props={this.props}
            key={index}
            path={routesInfo.path}
            render={props =>
              this.authenticationService.isUserLoggedIn() ? (
                <div className='bodyWrapper'>
                  <routesInfo.component
                    {...props}
                    notificationContext={this.props.notificationContext}
                    globalContext={this.props.globalContext}
                    setLoading={this.props.setLoading}
                  />{" "}
                </div>
              ) : (
                  <Redirect to='/' />
                )
            }
          />
        )

        // return <AuthRoute {...this.props} key={index} path={routesInfo.path} component={routesInfo.component} />;
      } else if (routesInfo.path === "/") { // Login page route 
        return (
          <Route
            props={this.props}
            key={index}
            path={routesInfo.path}
            render={props =>
              !this.authenticationService.isUserLoggedIn() ? (
                <div className='bodyWrapper not-logged-in'>
                  {" "}
                  <routesInfo.component
                    {...props}
                    notificationContext={this.props.notificationContext}
                    globalContext={this.props.globalContext}
                    setLoading={this.props.setLoading}
                  />
                </div>
              ) : (
                  (!isCustomer() ? <Redirect to='/dashboard' /> : <Redirect to='/customer-home' />)
                )
            }
            exact
          />
        );
      } else { // All other routes
        return (
          <Route
            props={this.props}
            key={index}
            path={routesInfo.path}
            render={props => (
              <div className='bodyWrapper'>
                <routesInfo.component
                  {...props}
                  notificationContext={this.props.notificationContext}
                  globalContext={this.props.globalContext}
                  setLoading={this.props.setLoading} />
              </div>
            )}
            exact
          />
        );
      }
    });
    return body;
  };

  render() {
    return <Switch>{this.state.body}</Switch>;
  }
}

export const PaymentCenterRoutes = {
  routes: [
    {
      path: `/payment-center/products`,
      component: Products,
      ref: React.createRef()
    },
    {
      path: `/payment-center/payment-methods`,
      component: PaymentMethods,
      ref: React.createRef()
    },
    {
      path: `/payment-center/payment-history`,
      component: PaymentHistory,
      ref: React.createRef()
    },
    {
      path: "/payment-center/pay-now",
      component: PayNow,
      ref: React.createRef()
    }
  ]
};

export class PaymentCenterRoutesComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      body: this.prepareRoutes()
    };
  }

  prepareRoutes = () => {
    let body = PaymentCenterRoutes.routes.map((routesInfo, index) => {
      return (
        <Route
          props={this.props}
          key={index}
          path={routesInfo.path}
          render={props => <routesInfo.component ref={routesInfo.ref}  {...props} notificationContext={this.props.notificationContext}
            globalContext={this.props.globalContext}
            setLoading={this.props.setLoading} />}
          exact
        />
      );
    });
    return body;
  };

  render() {
    return <Switch>{this.state.body}</Switch>;
  }
}
