import React from "react";
import Modal from "react-bootstrap/Modal";
import { PaymentCenterService } from "../../../../_services/payment-center.service";
import { Notification } from "../../../shared/notifications/notifications";
import { SharedService } from '../../../../_services/shared.service';
import { convertStringToAmount } from '../../../../_services/utility.service'

export class ChangeFrequencyAutopay extends React.Component {
  paymentCenterService = new PaymentCenterService();
  sharedService = new SharedService();

  state = {
    paymentFrequencies: this.props.product.availablePaymentFrequencyList,
    selectedPaymentFrequecy: this.props.selectedPaymentFrequecy,
    selectedPaymentMethod: this.props.selectedPaymentMethod,
    amountDue: this.props.product.amountDue,

    isFormValid: true,

    globalWebContents: {},

    // isFormValid: false,
    infoMessage: {
      type: "",
      content: [""]
    }
  };

  componentDidMount() {
    if (this.props.product.paymentAction.changeAutoPayOrFrequency.message) {
      this.props.notificationContext.setPopupNotifications([{
        type: 'INFO',
        content: [this.props.product.paymentAction.changeAutoPayOrFrequency.message]
      }])
    }

    let paymentMethodObj = this.props.paymentMethods.find(item => item.paymentRecordId === this.state.selectedPaymentMethod);
    let type = paymentMethodObj.creditCard ? 'creditCard' : 'bankAccount';
    if (type === 'creditCard' && paymentMethodObj.isCardExpired === 'Y') { // If expired card, push the new message in messages array
      let popupNotifications = this.props.notificationContext.state.popupNotifications;
      let newMsg = [{
        messageId: 'EXPIRED_CARD',
        type: 'ERROR',
        content: [paymentMethodObj.cardExpMessage]
      }]
      let result = popupNotifications.concat(newMsg);
      this.props.notificationContext.setPopupNotifications(result);

      this.setState({ isFormValid: false })
    }

    setTimeout(() => {
      this.props.globalContext.triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: '/change-payment-frequency-autopay',
        pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${JSON.parse(localStorage.getItem("initialIds")).clientName} - Change Auto Pay or Frequency`
      })
    }, 1000)
  }

  componentWillUnmount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: this.props.location.pathname,
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
    })
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === 'selectedPaymentMethod') { // detect change in payment methods drop down to show confirm popup right on dropdown change
        let isRemoveAutoPay = this.props.product.paymentStatusDisplayStyle === 'autopay';
        let paymentMethodObj = this.props.paymentMethods.find(item => item.paymentRecordId === this.state.selectedPaymentMethod);
        let type = paymentMethodObj.creditCard ? 'creditCard' : 'bankAccount';

        if (type === 'creditCard' && paymentMethodObj.isCardExpired === 'Y') { // If expired card, push the new message in messages array
          let popupNotifications = this.props.notificationContext.state.popupNotifications;
          let newMsg = [{
            messageId: 'EXPIRED_CARD',
            type: 'ERROR',
            content: [paymentMethodObj.cardExpMessage]
          }]
          let result = popupNotifications.concat(newMsg);
          this.props.notificationContext.setPopupNotifications(result);

          this.setState({ isFormValid: false })
        } else {
          // If valid/not-expired card or bank account, remove the previously added error message
          let popupNotifications = this.props.notificationContext.state.popupNotifications;
          let index = popupNotifications.findIndex((item) => item.messageId === 'EXPIRED_CARD');
          index !== -1 && popupNotifications.splice(index, 1);
          index !== -1 && this.props.notificationContext.setPopupNotifications(popupNotifications);
          this.setState({ isFormValid: true });
          // Show only when: 
          // Remove auto is selected : this.state.selectedPaymentMethod === "00" && isRemoveAutoPay
          // or 
          // Add new payment method is selected:  value === 'ADD_NEW_PAYMENT_METHOD'
          ((this.state.selectedPaymentMethod === "00" && isRemoveAutoPay) || value === 'ADD_NEW_PAYMENT_METHOD') && this.handleSubmit()
        }
      }
      else if (name === 'selectedPaymentFrequecy') { // detect change in payment frequecy drop down to update the amount due
        let amountDue = this.state.paymentFrequencies.find(item => item.code === value)['value2'];
        this.setState({ amountDue: amountDue })
      }
    });
  };

  handlePaymentMethodChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: this.props.paymentMethods[value] || value });
  };

  handleSubmit = () => {
    if (this.state.selectedPaymentMethod === "ADD_NEW_PAYMENT_METHOD") {
      this.props.notificationContext.resetPopupNotifications();
      this.props.hide("ADD_NEW_PAYMENT_METHOD");
      return;
    }
    else {
      let params = this.getSubmitParams();

      if (params['emailType'] !== 'none') { // Do not do anything if no changes have been made
        this.props.notificationContext.resetPopupNotifications();
        this.props.hide(this.state.selectedPaymentMethod, params);
        return
      } else {
        this.props.hide(); // Do not hid when 'Not on autopay' is re-selected
      }
    }
  };

  getSubmitParams = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let hostName = document.location.hostname;
    let { companyId, client, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let paymentMethodObj = this.props.paymentMethods.find(item => item.paymentRecordId === this.state.selectedPaymentMethod);
    let paymentFreqObj = this.state.paymentFrequencies.find(item => item.code === this.state.selectedPaymentFrequecy);
    let isBillingFeeInd = this.props.product.billingFeeInd === 'Y';
    let date = new Date();
    let currentDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()

    let params = {
      currentDate: currentDate,
      hostName: hostName,
      client: client,
      accountNumber: user.accountNumber,
      policyNumber: this.props.product.policyNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      userid: user.userid,
      companyId: companyId,
      domainGroupId: domainGroupId,
      paymentFrequency: paymentFreqObj.value1,
      paymentMethod: paymentMethodObj.paymentMethodHeader,
      paymentDetails: [
        {
          accountNumber: user.accountNumber,
          policyNumber: this.props.product.policyNumber,
          billingMode: this.state.selectedPaymentFrequecy,
          paymentMethodRecordId: paymentMethodObj.paymentRecordId,
          billingMethod: paymentMethodObj.billingMethodCode || '04',
          paymentAmount: this.state.amountDue
        }
      ],
    };

    // adding extra params for Email template
    params['emailType'] = this.getEmailType(params, this.props.product);
    params['productName'] = this.props.product.productDesc;
    params['paymentDueDate'] = this.props.product.dueDate;
    params['address'] = '';
    params['paymentMethodType'] = this.getPaymentMethodType(paymentMethodObj);
    params['lastFourDigit'] = this.getLastFour(paymentMethodObj);

    // add extra params for attestation calculations to be included in email template
    if (paymentMethodObj.paymentRecordId !== '00' && (params['emailType'] === 'add-admin-fees' || params['emailType'] === 'add')) { // not if remove auto pay is selected
      if (isBillingFeeInd) {
        let billingFeeObj = this.props.product.billingFees.find((item) => item.code === paymentMethodObj.billingMethodCode);
        let premium = convertStringToAmount(paymentFreqObj.value2);
        let billFee = convertStringToAmount(billingFeeObj.value2)

        params['premium'] = `${paymentFreqObj.value2}`;
        params['adminFee'] = `${billingFeeObj.value2}`;
        params['totalPremium'] = `$${(premium + billFee).toFixed(2)}`;
      } else {
        params['totalPremium'] = `${paymentFreqObj.value2}`;
      }
    }

    return params;
  }

  getEmailType = ({ paymentDetails }, product) => {
    const { paymentMethodRecordId, billingMode } = paymentDetails[0];
    const isPaymentMethodUpdated = this.isPaymentMethodUpdated(product, paymentMethodRecordId)

    // Remove AutoPay
    if (paymentMethodRecordId === '00' && product.paymentStatusDisplayStyle === 'autopay') { // if remove auto pay
      return 'remove';
    }

    // Both value get changed
    if (billingMode !== product.currentPaymentFrequecyCode && isPaymentMethodUpdated) {
      return 'both';
    }

    // From non auto pay to auto pay
    if (product.paymentStatusDisplayStyle !== 'autopay' && paymentMethodRecordId !== '00') {
      return product.billingFeeInd === 'Y' ? 'add-admin-fees' : 'add';
    }

    // Only frequency value get changed
    if (billingMode !== product.currentPaymentFrequecyCode && !isPaymentMethodUpdated) {
      return 'frequency';
    }

    // Only payment method value get changed
    if (billingMode === product.currentPaymentFrequecyCode && isPaymentMethodUpdated) {
      return 'payment-method';
    }

    // not change anything
    return 'none'
  }

  getLastFour = (paymentMethodObj) => {
    if (paymentMethodObj.paymentRecordId !== '00') {
      let type = paymentMethodObj.creditCard ? 'creditCard' : 'bankAccount';
      let lastFour = paymentMethodObj[type].accountNumberLast4Digit;
      return lastFour;
    } else {
      return ''
    }
  }

  getPaymentMethodType = (paymentMethodObj) => {
    if (paymentMethodObj.paymentRecordId !== '00') {
      let type = paymentMethodObj.creditCard ? 'creditCard' : 'bankAccount';
      let pType = paymentMethodObj[type].paymentType;
      return pType;
    } else {
      return ''
    }
  }

  isPaymentMethodUpdated = (product, paymentMethodRecordId) => {
    if (product.paymentStatusDisplayStyle === 'autopay') { // If on auto pay
      if (product.currentPaymentMethodRecordId !== paymentMethodRecordId) { // check if drop downs are changed
        return true;
      } else {
        return false;
      }
    } else { // if not on auto pay
      if (paymentMethodRecordId !== '00') { // if a valid payment method is selected instead of 'Not on Auto Pay'
        return true
      } else { // if 'Not on Auto Pay' is selected, means payment method not updated
        return false;
      }
    }
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.show}
        onHide={() => {
          this.props.notificationContext.resetPopupNotifications();
          this.props.hide();
        }}>
        <Modal.Header closeButton>
          <div className='col-12 p-0'>
            <Modal.Title>Change Auto Pay or Frequency</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.props.notificationContext.state.popupNotifications.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
          <h2 className='subHeading'>{this.props.product.productDesc}</h2>
          <div className='bodyRow'>
            <div className='flexBox'>
              <ul>
                <li>
                  <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                  <span className='value'>{this.props.product.policyNumber}</span>
                </li>
                <li>
                  <span className='label'>PAYMENT FREQUENCY</span>
                  <span className='value'>
                    {this.state.paymentFrequencies.length === 1 && <span>{this.state.paymentFrequencies[0].value1}</span>}
                    {this.state.paymentFrequencies.length > 1 &&
                      <select value={this.state.selectedPaymentFrequecy} onChange={this.handleChange} name='selectedPaymentFrequecy'>
                        {this.state.paymentFrequencies.map(value => (
                          <option key={value.code} value={value.code}>
                            {value.value1}
                          </option>
                        ))}
                      </select>}
                  </span>
                </li>
                <li>
                  <span className='label'>AUTO PAY METHOD</span>
                  <span className='value'>
                    <select value={this.state.selectedPaymentMethod} onChange={this.handleChange} name='selectedPaymentMethod'>
                      {this.props.paymentMethods.map(value => (
                        <option key={value.paymentRecordId} value={value.paymentRecordId}>
                          {value.paymentMethodHeader}
                        </option>
                      ))}
                    </select>
                  </span>
                </li>
                <li>
                  <span className='label'>DUE DATE</span>
                  <span className='value'>{this.props.product.dueDate}</span>
                </li>
                <li>
                  <span className='label'>AMOUNT DUE</span>
                  <span className='value'>{this.state.amountDue}<sup>*</sup></span>
                </li>
              </ul>
              {this.props.product.disclaimer && <span className="updateFrequencyDisclaimer" dangerouslySetInnerHTML={{ __html: this.props.product.disclaimer }}></span>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn agiaBlueButton-outline'
            data-dismiss='modal'
            onClick={() => {
              this.props.notificationContext.resetPopupNotifications();
              this.props.hide();
            }}>
            Cancel
          </button>

          <button
            id="ma-af-saveChanges"
            disabled={!this.state.isFormValid || this.props.product.paymentAction.saveChanges.disable === 'Y'}
            type='button'
            className='btn agiaBlueButton'
            data-dismiss='modal'
            onClick={this.handleSubmit}>
            Save changes
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
