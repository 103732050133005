import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AuthenticationService } from "../../_services/auth.service";
import { listenersService } from '../../_services/listeners.service'
import { SharedService } from "../../_services/shared.service";
import { LOCALIZATION } from "../../_constants/localization";
import { Notification } from '../shared/notifications/notifications';
import { Helmet } from "react-helmet";
import { NOTIFICATIONS, PAGES_TITLE_KEYS } from "../../_constants/constants";
const publicIp = require('public-ip');

export class RegisterMigratedUser extends Component {
    authenticationService = new AuthenticationService();
    sharedService = new SharedService();

    state = {
        email: '',
        planNumber: "",
        userName: "",
        password: "",
        confirmPassword: "",
        selectedQuestion: "",
        selectedAnswer: "",
        hideNewPassword: true,
        hideConfirmPassword: true,
        questions: [],

        validations: {
            greaterThan8: false,
            hasLowerCase: false,
            hasUpperCase: false,
            hasNumber: false,
            newConfirmMatch: false
        },

        isVerified: false,
        showPopup: false,
        isFormValid: false,
        pageTitle: ""
    };

    componentDidMount() {

        let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
        this.setState({ pageTitle: clientName })

        this.props.setLoading(false);
        if (this.props.location.email) {
            this.setState({ email: this.props.location.email });
            this.getSecurityQuestions();
        } else {
            this.props.history.push('/');
        }
    }

    getSecurityQuestions = () => {
        this.sharedService.getSecurityQuestions().subscribe(response => {
            if (response.status === 200) {
                this.setState({
                    questions: response.data.items
                });
            } else {
                this.props.notificationContext.processMessages(response.data.messages);
            }
        });
    };

    passwordValidation = () => {
        return new Promise((res, rej) => {
            this.setState(
                {
                    validations: {
                        greaterThan8: this.state.password.length >= 8,
                        hasLowerCase: this.state.password.match(/^(.*[a-z].*)$/),
                        hasUpperCase: this.state.password.match(/^(.*[A-Z].*)$/),
                        hasNumber: !this.state.password.match(/^([^0-9]*)$/),
                        newConfirmMatch: this.state.newPassword !== "" && this.state.confirmPassword !== "" && this.state.password === this.state.confirmPassword
                    }
                },
                () => {
                    if (
                        this.state.validations.greaterThan8 &&
                        this.state.validations.hasLowerCase &&
                        this.state.validations.hasUpperCase &&
                        this.state.validations.hasNumber &&
                        this.state.validations.newConfirmMatch
                    ) {
                        this.setState({ isFormValid: true });
                        res(true);
                    } else {
                        this.setState({ isFormValid: false });
                        res(false);
                    }
                }
            );
        });
    };

    checkRequiredFields = () => {
        if (/^\s*$/.test(this.state.userName)) {
            this.setState({ isFormValid: false });
            return false;
        }

        if (/^\s*$/.test(this.state.password)) {
            this.setState({ isFormValid: false });
            return false;
        }

        if (/^\s*$/.test(this.state.confirmPassword)) {
            this.setState({ isFormValid: false });
            return false;
        }

        if (/^\s*$/.test(this.state.selectedQuestion)) {
            this.setState({ isFormValid: false });
            return false;
        }

        if (/^\s*$/.test(this.state.selectedAnswer)) {
            this.setState({ isFormValid: false });
            return false;
        }

        this.setState({ isFormValid: true });
        return true;
    };

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, async () => {
            let isValid = await this.passwordValidation();
            isValid && this.checkRequiredFields();
        });
    };

    handleKeyDown = e => {
        if (e.key === "Enter") {
            this.handleCreateAccount();
        }
    };

    onSubmit = async () => {
        let isPasswordValid = await this.passwordValidation();
        let isRequiredValid = this.checkRequiredFields();

        if (isPasswordValid && isRequiredValid) {
            let { companyId, isSubdomain, domainGroupId, client } = JSON.parse(localStorage.getItem("initialIds"));
            let hostName = document.location.hostname;

            let params = {
                "userName": this.state.userName,
                "emailAddress": this.state.email,
                "password": this.state.password,
                "securityQuestion": this.state.selectedQuestion,
                "securityAnswer": this.state.selectedAnswer,
                "companyId": companyId,
                "domainGroupId": domainGroupId,
                "client": client,
                "hostName": hostName
            }
            this.props.setLoading(true);
            this.authenticationService.registerMigration(params).subscribe(response => {
                if (response.status === 200) {
                    let params = {
                        username: this.state.userName,
                        password: this.state.password,
                        companyId: companyId,
                        domainGroupId: domainGroupId,
                        isSubdomain: isSubdomain,
                        isCustomerUser: false,
                        client: client
                    };

                    this.authenticationService.login(params).subscribe(response => {
                        if (response.status === 200) {
                            localStorage.setItem("user", JSON.stringify(response.data));
                            (async () => {
                                let ip = await publicIp.v4();
                                let params = {
                                    "username": this.state.userName,
                                    "companyId": companyId,
                                    "domainGroupId": domainGroupId,
                                    "isCustomerUser": false,
                                    "client": client,
                                    clientLoginIp: ip
                                };
                                this.authenticationService.updateLoginInformations(params).subscribe(_response => {
                                    if (_response.status !== 403) {
                                        localStorage.setItem("hideWelcomeTxt", true);
                                        listenersService.triggreAuthStateChanged(JSON.stringify(response.data));
                                        this.props.history.push("dashboard");
                                    } else {
                                        this.props.setLoading(false);
                                    }
                                })
                            })();
                        } else {
                            this.props.setLoading(false);
                            this.props.notificationContext.processMessages(response.data.messages);
                        }
                    });
                } else {
                    this.props.notificationContext.processMessages(response.data.messages);
                }
                this.props.setLoading(false);
            });
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.REACTIVATE_USER] || this.state.pageTitle}</title>
                </Helmet>
                <div className='registerWrapper usernameWrapper'>
                    <div>
                        <div className='registerHeader'>
                            <h1 className='mainHeadingT'>{LOCALIZATION.EN.REACTIVATE_ALL_STEPS}</h1>
                            {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
                            {/* {this.state.errorMessage !== "" ? <div className='warningAlert p-2 mt-3'>{this.state.errorMessage}</div> : null} */}
                            <p>{NOTIFICATIONS.REACTIVATE_USER_HEADER}</p>
                        </div>
                        <div className='lusernameColumn mobileButton loginCred'>
                            <div className="backgroundSetColor">
                                <div className="columnLayout">
                                    <div className="leftCol">
                                        <div className='form-group'>
                                            <div className='username mt-3'>
                                                <div htmlFor='username'> Username</div>
                                                <input
                                                    onKeyDown={this.handleKeyDown}
                                                    className='form-control'
                                                    type='text'
                                                    id='Username'
                                                    onChange={this.handleChange}
                                                    placeholder='Username'
                                                    name='userName'
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className='password mt-3'>
                                                <div htmlFor='password'>Password</div>
                                                <input
                                                    onKeyDown={this.handleKeyDown}
                                                    className='form-control password'
                                                    type={this.state.hideNewPassword ? "password" : "text"}
                                                    onChange={this.handleChange}
                                                    id='password'
                                                    placeholder='Password'
                                                    name='password'
                                                    required
                                                />
                                                <label
                                                    onClick={() => {
                                                        this.setState({
                                                            hideNewPassword: !this.state.hideNewPassword
                                                        });
                                                    }}
                                                    className='showHide'>
                                                    <i className={"far " + (this.state.hideNewPassword ? "fa-eye" : "fa-eye-slash")}></i>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='password-validation-ctnr mobileView'>
                                            <span>Password must have: </span> <br></br>
                                            <span className={this.state.validations.greaterThan8 ? "valid" : "invalid"}>at least 8 characters in length </span> <br></br>
                                            <span className={this.state.validations.hasLowerCase ? "valid" : "invalid"}>at least 1 lowercase letter (a-z) </span> <br></br>
                                            <span className={this.state.validations.hasUpperCase ? "valid" : "invalid"}>at least 1 uppercase letter (A-Z) </span> <br></br>
                                            <span className={this.state.validations.hasNumber ? "valid" : "invalid"}>at least 1 number (i.e. 0-9) </span> <br></br>
                                            <span className={this.state.validations.newConfirmMatch ? "valid" : "invalid"}>New and Confirm password must match </span> <br></br>
                                        </div>
                                        <div className='form-group'>
                                            <div className='confirmpassword'>
                                                <div htmlFor='confirmpassword'>Confirm Password</div>
                                                <input
                                                    onKeyDown={this.handleKeyDown}
                                                    className='form-control password'
                                                    onChange={this.handleChange}
                                                    type={this.state.hideConfirmPassword ? "password" : "text"}
                                                    id='confirmpassword'
                                                    placeholder='Confirm Password'
                                                    name='confirmPassword'
                                                    required
                                                />
                                                <label
                                                    onClick={() => {
                                                        this.setState({
                                                            hideConfirmPassword: !this.state.hideConfirmPassword
                                                        });
                                                    }}
                                                    className='showHide'>
                                                    <i className={"far " + (this.state.hideConfirmPassword ? "fa-eye" : "fa-eye-slash")}></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="leftCol desktopView">
                                        <div className='password-validation-ctnr'>
                                            <span>Password must have: </span> <br></br>
                                            <span className={this.state.validations.greaterThan8 ? "valid" : "invalid"}>at least 8 characters in length </span> <br></br>
                                            <span className={this.state.validations.hasLowerCase ? "valid" : "invalid"}>at least 1 lowercase letter (a-z) </span> <br></br>
                                            <span className={this.state.validations.hasUpperCase ? "valid" : "invalid"}>at least 1 uppercase letter (A-Z) </span> <br></br>
                                            <span className={this.state.validations.hasNumber ? "valid" : "invalid"}>at least 1 number (i.e. 0-9) </span> <br></br>
                                            <span className={this.state.validations.newConfirmMatch ? "valid" : "invalid"}>New and Confirm password must match </span> <br></br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="backgroundSetColor">
                                <div className='formLayout'>
                                    <h2 className="headingText">Security Question and Answer</h2>
                                    <div className='securityQuestion'>

                                        <div>Select a security question</div>
                                        <select value={this.state.selectedQuestion} name='selectedQuestion' onChange={this.handleChange} className='questionAnswer'>
                                            <option value=''>Please Select</option>
                                            {this.state.questions.map(value => (
                                                <option key={value.questionId} value={value.question}>
                                                    {value.question}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='form-group'>
                                        <div className='answer'>
                                            <div htmlFor='answer'>Answer</div>
                                            <input
                                                onKeyDown={this.handleKeyDown}
                                                className='form-control'
                                                type='text'
                                                id='answer'
                                                onChange={this.handleChange}
                                                // placeholder="Enter username"
                                                name='selectedAnswer'
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="roundBottomButton">
                                <button
                                    //
                                    disabled={!(this.state.isFormValid)}
                                    className={"btn btn-primary agiaBlueButton mt-3 "}
                                    onClick={this.onSubmit}
                                    value='Create Account'>
                                    Submit
                </button>
                                <Link to={{
                                    pathname: `/`,
                                }} >Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}