import React from "react";
import Modal from "react-bootstrap/Modal";

export class ConfirmPayNow extends React.Component {

  componentDidMount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: '/payment-authorization',
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${JSON.parse(localStorage.getItem("initialIds")).clientName} - Payment Authorization`
    })
  }

  componentWillUnmount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: this.props.location.pathname,
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
    })
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.show}
        onHide={() => {
          this.props.hide();
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Authorization</Modal.Title>
        </Modal.Header>
        <Modal.Body><div dangerouslySetInnerHTML={{ __html: this.props.content }}></div></Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              this.props.hide();
            }}
            type='button'
            className='btn agiaBlueButton-outline'
            data-dismiss='modal'>
            I Do Not Agree
          </button>

          <button
            onClick={() => {
              this.props.hide(true);
            }}
            type='button'
            className='btn agiaBlueButton'
            data-dismiss='modal'>
            I Agree
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
