import React from "react";
import "./ResetPassword.scss";
import { Link } from "react-router-dom";
import { SharedService } from "../../_services/shared.service";
import { AuthenticationService } from "../../_services/auth.service";
import { listenersService } from "../../_services/listeners.service";
import { Notification } from '../shared/notifications/notifications';
import { PAGE_TITLES, PAGES_TITLE_KEYS } from "../../_constants/constants";
import { Helmet } from "react-helmet";
const publicIp = require('public-ip');

export class ResetPassword extends React.Component {
  sharedService = new SharedService();
  authenticationService = new AuthenticationService();

  state = {
    newPassword: "",
    confirmPassword: "",
    hideNewPassword: true,
    hideConfirmPassword: true,
    isFormValid: false,
    validations: {
      greaterThan8: false,
      hasLowerCase: false,
      hasUpperCase: false,
      hasNumber: false,
      newConfirmMatch: false
    },
    pageTitle: ""
  };

  componentDidMount() {

    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: clientName + PAGE_TITLES.RESET_PASSWORD })

    if (this.props.location.pathname.indexOf('/reset-password/') !== -1) {
      this.props.globalContext.setShowNavigationBar(false);
    }
  }

  componentWillUnmount() {
    this.props.globalContext.setShowNavigationBar(true);
  }

  checkValidation = () => {
    this.setState(
      {
        validations: {
          greaterThan8: this.state.newPassword.length >= 8,
          hasLowerCase: this.state.newPassword.match(/^(.*[a-z].*)$/),
          hasUpperCase: this.state.newPassword.match(/^(.*[A-Z].*)$/),
          hasNumber: !this.state.newPassword.match(/^([^0-9]*)$/),
          newConfirmMatch: this.state.newPassword !== "" && this.state.confirmPassword !== "" && this.state.newPassword === this.state.confirmPassword
        }
      },
      () => {
        if (
          this.state.validations.greaterThan8 &&
          this.state.validations.hasLowerCase &&
          this.state.validations.hasUpperCase &&
          this.state.validations.hasNumber &&
          this.state.validations.newConfirmMatch
        ) {
          this.setState({ isFormValid: true });
        } else {
          this.setState({ isFormValid: false });
        }
      }
    );
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.checkValidation();
    });
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  onSubmit = () => {
    if (this.state.isFormValid) {
      let { domainGroupId, client } = JSON.parse(localStorage.getItem("initialIds"));
      let hostName = document.location.hostname;

      let params = {
        password: this.state.newPassword,
        ticket: this.props.match.params.id,
        domainGroupId: domainGroupId,
        hostName: hostName,
        client: client
      };

      this.props.setLoading(true);
      this.authenticationService.resetPassword(params).subscribe(response => {
        let { isSubdomain, companyId, domainGroupId, client } = JSON.parse(localStorage.getItem("initialIds"));

        if (response.status === 200) {
          let params = {
            username: response.data.userName,
            password: this.state.newPassword,
            companyId: companyId,
            domainGroupId: domainGroupId,
            isSubdomain: isSubdomain,
            isCustomerUser: false,
            client: client
          };

          let username = response.data.userName
          this.authenticationService.login(params).subscribe(response => {
            if (response.status === 200) {
              localStorage.setItem("user", JSON.stringify(response.data));
              (async () => {
                let ip = await publicIp.v4();
                let params = {
                  "username": username,
                  "companyId": companyId,
                  "domainGroupId": domainGroupId,
                  "isCustomerUser": false,
                  "client": client,
                  clientLoginIp: ip
                };
                this.authenticationService.updateLoginInformations(params).subscribe(_response => {
                  if (_response.status !== 403) {
                    listenersService.triggreAuthStateChanged(JSON.stringify(response.data));
                    this.props.history.push("/dashboard");
                  } else {
                    this.props.setLoading(false);
                  }
                })
              })();


            } else {
              this.props.setLoading(false);
              this.props.notificationContext.processMessages(response.data.messages);
            }
          });
        } else {
          this.props.setLoading(false);
          this.props.notificationContext.processMessages(response.data.messages);
        }
      });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.RESET_PASSWORD] || this.state.pageTitle}</title>
        </Helmet>
        <div className='resetPwd usernameWrapper'>
          <div className='lusernameColumn'>
            <h1 className='mainHeadingT'>Reset your password</h1>
            {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
            {/* {this.state.errorMessage !== "" ? <div className='warningAlert p-2 mt-3'>{this.state.errorMessage}</div> : null} */}
            {/* <p>Enter a new password for your account. </p> */}
            <div className='backgroundSetColor'>
              <div className='columnLayout'>
                <div className='leftCol'>
                  <div className='password mt-3'>
                    <label htmlFor='pwd'>New Password</label>
                    <input
                      className='form-control password'
                      type={this.state.hideNewPassword ? "password" : "text"}
                      name='newPassword'
                      onKeyDown={this.handleKeyDown}
                      noValidate
                      onChange={this.handleChange}
                    />
                    <label
                      onClick={() => {
                        this.setState({
                          hideNewPassword: !this.state.hideNewPassword
                        });
                      }}
                      className='showHide'>
                      <i className={"far " + (this.state.hideNewPassword ? "fa-eye" : "fa-eye-slash")}></i>
                    </label>
                    <div className='invalid-feedback'>Please fill out this field.</div>
                  </div>

                  <div className='password mt-3'>
                    <label htmlFor='pwd'>Confirm Password</label>
                    <input
                      className='form-control password'
                      type={this.state.hideConfirmPassword ? "password" : "text"}
                      name='confirmPassword'
                      onKeyDown={this.handleKeyDown}
                      noValidate
                      onChange={this.handleChange}
                    />
                    <label
                      onClick={() => {
                        this.setState({
                          hideConfirmPassword: !this.state.hideConfirmPassword
                        });
                      }}
                      className='showHide'>
                      <i className={"far " + (this.state.hideConfirmPassword ? "fa-eye" : "fa-eye-slash")}></i>
                    </label>
                    <div className='invalid-feedback'>Please fill out this field.</div>
                  </div>
                </div>
                <div className='leftCol'>
                  <div className='password-validation-ctnr'>
                    <span className='passwordHeadingText'>Password must have: </span> <br></br>
                    <span className={this.state.validations.greaterThan8 ? "valid" : "invalid"}>at least 8 characters in length </span> <br></br>
                    <span className={this.state.validations.hasLowerCase ? "valid" : "invalid"}>at least 1 lowercase letter (a-z) </span> <br></br>
                    <span className={this.state.validations.hasUpperCase ? "valid" : "invalid"}>at least 1 uppercase letter (A-Z) </span> <br></br>
                    <span className={this.state.validations.hasNumber ? "valid" : "invalid"}>at least 1 number (i.e. 0-9) </span> <br></br>
                    <span className={this.state.validations.newConfirmMatch ? "valid" : "invalid"}>New and Confirm password must match </span> <br></br>
                  </div>
                </div>
              </div>
            </div>
            <div className='roundBottomButton'>
              <button className={"btn btn-primary agiaBlueButton mt-3 " + (!this.state.isFormValid && "disabled")} type='button' value='Submit' onClick={this.onSubmit}>
                Submit new password
            </button>
              <Link to='/'>Back to login</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
