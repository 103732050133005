import React, { Component } from "react";
import { DuePaymentCard } from "../../../shared/due-payment-card/DuePaymentCard";
import { NoDuePaymentCard } from "../../../shared/no-due-payment-card/NoDuePaymentCard";
import { ActiveProductsDashboard } from "../../../shared/active-products-dashboard/ActiveProductsDashboard";

export class SummaryHorizontal extends Component {
  render() {
    return (
      <div className='desktopView homePayDues singleColLayout'>
        <div className='leftCol'>
          <h2 className='homeHederText'>Payments Due</h2>
          {this.props.dueProducts.length !== 0 && <DuePaymentCard clientName={this.props.clientName} dueProduct={this.props.dueProducts[0]} {...this.props} />}
          {this.props.dueProducts.length === 0 && <NoDuePaymentCard />}
        </div>
        <div className='rightCol'>
          <h2 className='homeHederText'>Your Active Products</h2>
          <ActiveProductsDashboard
            activeProducts={this.props.activeProducts}
            hasEAPlusProduct={this.props.hasEAPlusProduct}
            globlWebContents={this.props.globlWebContents}
          />
        </div>
      </div>
    );
  }
}
