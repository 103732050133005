import React from "react";
import Modal from "react-bootstrap/Modal";
import { ARTICLES } from '../../../../_constants/constants'

export class MobileCallNow extends React.Component {

  componentDidMount() {
    document.getElementById(`globalWC_${ARTICLES.HEADER_PHONE_INFO}`).innerHTML = this.props.globlWebContents[ARTICLES.HEADER_PHONE_INFO];
    document.querySelector('#globalWC_login-header-phone a').style.pointerEvents = 'none';

    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: '/mobile-call-now',
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${JSON.parse(localStorage.getItem("initialIds")).clientName} - Contact us`
    })
  }

  componentWillUnmount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: this.props.location.pathname,
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
    })
  }

  handleCallNow = () => {
    document.querySelector(`#globalWC_${ARTICLES.HEADER_PHONE_INFO} a`).click()
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.show}
        onHide={() => {
          this.props.hide();
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Contact us</Modal.Title>
        </Modal.Header>
        <Modal.Body className="callNowBody">
          <div id={`globalWC_${ARTICLES.HEADER_PHONE_INFO}`}></div>
        </Modal.Body>
        <Modal.Footer className="callNow">
          {/* <a href='tel:+14123815500'>1 (412) 381-5500</a> */}
          <button
            onClick={this.handleCallNow}
            type='button'
            className='btn agiaBlueButton'
            data-dismiss='modal'>
            Call Now
          </button>
          <button
            onClick={() => {
              this.props.hide();
            }}
            type='button'
            className='btn agiaBlueButton-outline grayButton'
            data-dismiss='modal'>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
