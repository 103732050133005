import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthenticationService } from "../../../_services/auth.service";
import { listenersService } from "../../../_services/listeners.service";
import "./HomeNavigationBar.scss";
import { MobileCallNow } from "../modals/mobile-call-now/MobileCallNow";
import { isCustomer } from "../../../_routes/app-routes";

export default class HomeNavigationBar extends Component {
  authenticationService = new AuthenticationService();

  state = {
    showCallPopup: false,
    isUserLoggedIn: this.authenticationService.isUserLoggedIn(),
    impersonateUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).hasOwnProperty('csUserid') : false,
  };

  logout = () => {
    let user = localStorage.getItem("user") || localStorage.getItem("ccuser");
    let access_token = JSON.parse(user).access_token;

    this.props.setLoading(true);
    this.authenticationService.logout(access_token).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {
        localStorage.removeItem("user");
        localStorage.removeItem("ccuser");
        listenersService.triggreAuthStateChanged(null);
        this.props.history.push("/");
      }
      else {
        this.props.notificationContext.processMessages(response.data.messages);
      }
    });
  };

  showNotifications = () => {
    this.props.notificationContext.setInfoNotification(this.props.notificationContext.state.infoNotificationOriginal);
  };

  handleCallPopupClose = data => {
    this.setState({ showCallPopup: false });

    if (data) {
      // Implement logic for call now
    }
  };

  render() {
    let isCustomerServiceSite = JSON.parse(localStorage.getItem('initialIds')).isCustomerSite;

    return (
      <>
        {this.state.showCallPopup &&
          <MobileCallNow
            {...this.props}
            globlWebContents={this.props.globlWebContents}
            show={this.state.showCallPopup}
            hide={this.handleCallPopupClose}
          />}

        {this.props.globalContext.state.deviceWidth <= 767 && (
          <div className='navbarText mobileView'>
            <ul>
              {/* {this.props.isUserLoggedIn &&
                <li className='nav-item notifiacations'>
                  <span title={""} className='nav-link' onClick={this.showNotifications}>
                    <label className='materialicons'>flag</label>
                    {this.props.notificationContext.state.infoNotificationCount > 0 ? (
                      <label className='navText'>
                        <sup className='whiteColor'>{this.props.notificationContext.state.infoNotificationCount}</sup>
                      </label>
                    ) : (
                        ""
                      )}
                  </span>
                </li>
              } */}

              {isCustomerServiceSite && this.props.isUserLoggedIn && <li className='nav-item'>
                <Link
                  title={""}
                  className='nav-link phoneIcon'
                  to='#'
                  onClick={e => {
                    e.preventDefault();
                    this.logout();
                  }}>
                  <label className='materialicons'>logout</label>
                </Link>
              </li>
              }

              {!isCustomerServiceSite && <li className='nav-item'>
                <Link
                  title={""}
                  className='nav-link phoneIcon'
                  to='#'
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ showCallPopup: true });
                  }}>
                  <label className='materialicons'>phone</label>
                </Link>
              </li>}

            </ul>
          </div>
        )}


        {this.props.isUserLoggedIn && this.props.globalContext.state.deviceWidth >= 768 &&
          <div className='navigationHeader'>
            <div className='navbarText desktopView'>
              {!isCustomer() ? (
                <nav className='navbar navbar-expand-lg'>
                  <ul className='navbar-nav'>
                    <li className='nav-item'>
                      <NavLink id="ma-dt-nav-home" title={""} className='nav-link' activeClassName='active' to='/dashboard'>
                        <label className='materialicons'>home</label>
                        <label className='navText'>Home</label>
                      </NavLink>
                    </li>

                    <li className='nav-item'>
                      <NavLink id="ma-dt-nav-paymentCenter" title={""} className='nav-link ' activeClassName='active' to='/payment-center'>
                        <label className='materialicons attachMoney'>attach_money</label>
                        <label className='navText'>Payment Center</label>
                      </NavLink>
                    </li>

                    <li id="ma-dt-nav-products" className='nav-item'>
                      <NavLink title={""} className='nav-link' activeClassName='active' to='/products-main'>
                        <label className='materialicons'>description</label>
                        <label className='navText'>Products</label>
                      </NavLink>
                    </li>

                    {/* <li className='nav-item notifiacations'>
                      <span title={""} className='nav-link' onClick={this.showNotifications}>
                        <label className='materialicons'>flag</label>
                        {this.props.notificationContext.state.infoNotificationCount > 0 ? (
                          <label className='navText'>
                            <sup className='whiteColor'>{this.props.notificationContext.state.infoNotificationCount}</sup>
                          </label>
                        ) : (
                            ""
                          )}
                      </span>
                    </li> */}
                  </ul>
                </nav>
              ) : (
                  ""
                )}
              {!isCustomer()
                ? this.props.isUserLoggedIn && (
                  <div className='loginProfile'>
                    <span className='profile'>
                      <NavLink id="ma-dt-nav-profile" className='header-nav-link' title={"Profile"} activeClassName='active' to='/my-profile'>
                        Profile
                      </NavLink>
                    </span>
                    {this.props.globalContext.isCustomerServiceUser() === false && (
                      <>
                        <span className='devider'>&nbsp;</span>
                        <span className='logOut' onClick={this.logout}>
                          <span id="ma-dt-nav-logout" to='#' className='logout-btn'>
                            Logout
                        </span>
                        </span>
                      </>
                    )}

                  </div>
                )
                : this.props.isUserLoggedIn && (
                  <div className='loginProfile'>
                    <span className='logOut' onClick={this.logout}>
                      <span id="ma-dt-nav-logout" to='#' className='logout-btn'>
                        Logout
                      </span>
                    </span>
                  </div>
                )}
            </div>
          </div>}

      </>
    );
  }
}
