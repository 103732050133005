import React from "react";
import Modal from "react-bootstrap/Modal";
import { PaymentCenterService } from "../../../../_services/payment-center.service";
import { Notification } from "../../../shared/notifications/notifications";

export class DeletePaymentMethod extends React.Component {
  paymentCenterService = new PaymentCenterService();

  state = {
    message: {
      type: "",
      content: [""]
    }
  };

  componentDidMount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: '/confirm-delete-payment-method',
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${JSON.parse(localStorage.getItem("initialIds")).clientName} - Delete Payment Method?`
    })
  }

  componentWillUnmount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: this.props.location.pathname,
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
    })
  }

  deletePaymentMethod = () => {
    let { companyId, client, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let user = JSON.parse(localStorage.getItem("user"));
    let paymentMethod = this.props.selectedPaymentMethod;

    let params = {
      client: client,
      domainGroupId: domainGroupId,
      accountNumber: user.accountNumber,
      policyNumber: user.policyNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      userid: user.userid,
      recordId: paymentMethod.paymentRecordId,
      companyId: companyId,
      methodType: paymentMethod.bankAccount ? paymentMethod.bankAccount.paymentType : paymentMethod.creditCard.paymentType
    };

    this.props.setLoading(true);
    this.paymentCenterService.deletePaymentMethod(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {
        this.props.hide(response.data.paymentMethods || [], response.data.messages);
      } else {
        this.props.hide(null, response.data.messages);
      }
    });
  };

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.show}
        onHide={() => {
          this.props.hide();
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Payment Method?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.message.type !== "" ? <Notification close={() => { }} message={this.state.message} /> : ""}
          <div dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              this.props.hide();
            }}
            type='button'
            className='btn agiaBlueButton-outline'
            data-dismiss='modal'>
            Cancel
          </button>

          <button
            disabled={this.props.selectedPaymentMethod.managePaymentMethodOption.deletePaymentMethodConfirmation.disable === 'Y'}
            onClick={this.deletePaymentMethod}
            type='button'
            className='btn btn-danger'
            data-dismiss='modal'>
            Delete Method
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
