import React from "react";
import { Link } from "react-router-dom";
import { SharedService } from "../../_services/shared.service";
import { AuthenticationService } from "../../_services/auth.service";
import { LOCALIZATION } from "../../_constants/localization";
import { ARTICLES } from '../../_constants/constants'
import { Notification } from '../shared/notifications/notifications';

export class ForgotPasswordSecurityQues extends React.Component {

  sharedService = new SharedService();
  authenticationService = new AuthenticationService();

  state = {
    hasData: false,
    answer: "",
    isFormValid: false
  };

  componentDidMount() {
    this.globalWC_getContent([ARTICLES.FORGOT_PASSWORD_INFO]);
  }

  componentWillMount() {

    if (this.props.location.data == null) {
      this.props.history.push("/");
    } else {
      this.setState({ hasData: true });
    }
  }

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        response.data.items && response.data.items.forEach(element => {
          document.getElementById(`globalWC_${element.title}`).innerHTML = element.content;
        });
      }
    });
  };

  handleSubmit = () => {
    if (this.checkValidation()) {
      let { companyId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
      let hostName = document.location.hostname;
      let params = {
        companyId: companyId,
        securityAnswer: this.state.answer,
        userName: this.props.location.data.userName,
        hostName: hostName,
        isCustomerUser: false,
        domainGroupId: domainGroupId
      };

      this.props.setLoading(true);
      this.authenticationService
        .forgotPassword2(params)
        .subscribe(response => {
          if (response.status === 200) {
            this.props.notificationContext.setInterScreenNotification(response.data.messages);
            this.props.history.push("/");
          } else {
            this.props.notificationContext.processMessages(response.data.messages)
          }
          this.props.setLoading(false);
        });
    }
  };

  handleChange = e => {
    // e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.checkValidation();
    });
  };

  checkValidation = () => {
    if (/^\s*$/.test(this.state.answer)) {
      // setErrMsg && this.setState({ errorMessage: 'Please provide a username' });
      this.setState({ isFormValid: false });
      return false;
    }

    this.setState({ isFormValid: true });
    // setErrMsg && this.setState({ errorMessage: '' });
    return true;
  };
  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };
  render() {
    return (
      <div>
        {this.state.hasData && (
          <div className="resetPwd usernameWrapper">
            <div className="lusernameColumn">
              <h1 className="mainHeadingT">{LOCALIZATION.EN.FORGOT_PASSWORD_2}</h1>
              {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
              <div id={`globalWC_${ARTICLES.FORGOT_PASSWORD_INFO}`}></div>
              <div className="backgroundSetColor">
                <div className="formLayout">
                  <h2 className="homeHederText col-12">
                    Security Identification
                      </h2>
                  <div
                    className="formLayout lastName"
                  >
                    <label htmlFor="pwd">
                      {this.props.location.data.securityQuestion}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="answer"
                      onKeyDown={this.handleKeyDown}
                      name="answer"
                      required
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      Please fill out this field.
                        </div>
                  </div>
                </div>
              </div>
              <div className="roundBottomButton">
                {/* <button
                          className="btn agiaBlueButton-outline mt-3 mr-3"
                          value="Return To Login"
                            onClick={() => { this.props.history.replace('/') }}
                        >
                          Back to login
                        </button> */}
                <button
                  className={
                    "btn agiaBlueButton mt-3  " +
                    (!this.state.isFormValid && "disabled")
                  }
                  // className="btn agiaBlueButton mt-3 mr-3"
                  onClick={this.handleSubmit}
                >
                  Submit
                      </button>
                <Link to="/">Back to login</Link>
              </div>

            </div>
          </div>
        )}
      </div>
    );
  }
}