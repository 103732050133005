import React, { Component } from "react";

export const NotificationContext = React.createContext();

export class NotificationContextProvider extends Component {

    state = {
        infoNotificationOriginal: [], // Notification we get from server
        infoNotification: [], // Notification we get from server - this can be deleted and fetched again via infoNotificationOriginal
        infoNotificationCount: 0,
        nonInfoNotificaiton: [], // Red and Green notifications
        interScreenNotification: [],  // Notification passed from one page to another
        popupNotifications: [] //  Notification inside a popup window
    }

    setInfoNotification = (infoNotification) => {
        window.scrollTo(0, 0);
        this.setState({
            infoNotification: infoNotification
        })
    }

    setInterScreenNotification = (notification) => {
        window.scrollTo(0, 0);
        this.setState({
            interScreenNotification: notification
        })
    }

    setPopupNotifications = (messages) => {
        if (document.getElementsByClassName("modal-body")[0]) {
            document.getElementsByClassName("modal-body")[0].scrollTop = 0
        }

        let _messages = this.getCleanMessages(messages); // clean messages 

        if (_messages) {
            let popupNotifications = [];

            _messages.forEach((msg) => {
                // if (msg.type !== 'INFO') {
                popupNotifications.push(msg);
                // }
            })

            this.setState({
                popupNotifications: popupNotifications
            })
        }
    }

    resetInfoNotification = () => {
        this.setState({
            infoNotification: []
        })
    }

    resetNonInfoNotification = () => {
        this.setState({
            nonInfoNotificaiton: []
        })
    }

    resetPopupNotifications = () => {
        this.setState({
            popupNotifications: []
        })
    }

    getCleanMessages = (messages) => { // remove bad data in message
        let _messages = [];

        if (messages) {
            // messages can be [null]
            // message can be [... content = ['']]
            messages.forEach((message) => {
                if (message && message.type && message.content[0] !== '') {
                    _messages.push(message)
                }
            })

            return _messages
        } else {
            return null
        }
    }

    processMessages = (messages) => {
        window.scrollTo(0, 0);
        let _messages = this.getCleanMessages(messages); // clean messages 

        if (_messages) {
            let infoMessages = [];
            let nonInfoMessages = [];

            _messages.forEach((msg) => {
                if (msg.type === 'INFO') {
                    infoMessages.push(msg);
                } else {
                    nonInfoMessages.push(msg)
                }
            })

            this.setState({
                infoNotification: infoMessages,
                infoNotificationOriginal: infoMessages,
                infoNotificationCount: infoMessages.length,
                nonInfoNotificaiton: nonInfoMessages
            })
        }
    }

    resetMessages = () => {
        this.setState({
            infoNotification: [],
            nonInfoNotificaiton: [],
            infoNotificationOriginal: [],
            infoNotificationCount: 0
        })
    }

    pushNonInfoMsg = (msg) => {
        let nonInfoNotificaiton = this.state.nonInfoNotificaiton;
        let result = nonInfoNotificaiton.concat(msg);
        this.setState({ nonInfoNotificaiton: result })
    }

    popNonInfoMsg = (msgId) => {
        let nonInfoNotificaiton = this.state.nonInfoNotificaiton;
        let index = nonInfoNotificaiton.findIndex((item) => item.messageId === msgId);
        index !== -1 && nonInfoNotificaiton.splice(index, 1);
        index !== -1 && this.setState({ nonInfoNotificaiton: nonInfoNotificaiton });
    }

    render() {
        return (
            <NotificationContext.Provider
                value={{
                    state: this.state,
                    processMessages: this.processMessages,
                    resetMessages: this.resetMessages,
                    setInfoNotification: this.setInfoNotification,
                    setInterScreenNotification: this.setInterScreenNotification,
                    setPopupNotifications: this.setPopupNotifications,
                    resetPopupNotifications: this.resetPopupNotifications,
                    resetInfoNotification: this.resetInfoNotification,
                    getCleanMessages: this.getCleanMessages,
                    pushNonInfoMsg: this.pushNonInfoMsg,
                    popNonInfoMsg: this.popNonInfoMsg
                }}>
                {this.props.children}
            </NotificationContext.Provider>
        );
    }
}
