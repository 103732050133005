import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ARTICLES } from "../../../../_constants/constants";
import { DuePaymentCard } from "../../../shared/due-payment-card/DuePaymentCard";
import { NoDuePaymentCard } from "../../../shared/no-due-payment-card/NoDuePaymentCard";

export class SummaryMobile extends Component {

  state = {
    showClaimNow: false
  }

  componentWillMount() {
    let hasInsuredProduct = this.props.activeProducts.find((product) => product.isInsuranceProduct === 'Y') || false;
    this.setState({ showClaimNow: hasInsuredProduct });
  }


  getDisclaimers = () => {
    let disclaimerTypesMap = [
      { name: 'autopay', content: this.props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER] },
      { name: 'combinedbill', content: this.props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING] },
      { name: 'nocosttoyou', content: this.props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_NOCOST] },
    ]
    let components = [];
    let iterated = [];
    this.props.activeProducts.forEach((product, index) => {
      let disclaimerObj = disclaimerTypesMap.find((item) => item.name === product.paymentStatusDisplayStyle);
      let hasAlreadyIterated = !iterated.includes(product.paymentStatusDisplayStyle);

      if (disclaimerObj && hasAlreadyIterated) {
        components.push(<div key={index} dangerouslySetInnerHTML={{ __html: disclaimerObj.content }} ></div>)
        iterated.push(product.paymentStatusDisplayStyle);
      }
    });

    return components;
  }

  render() {
    return (
      <div className='mobileView homePayDues'>
        <div className='singleCol'>
          <h2 className='homeHederText'>Payments Due</h2>
          {this.props.dueProducts.length !== 0 && this.props.dueProducts.map(value => <DuePaymentCard key={value.policyNumber} clientName={this.props.clientName} dueProduct={value} {...this.props} />)}
          {this.props.dueProducts.length === 0 && <NoDuePaymentCard />}

          {/* <div className="flexWhiteBox col-lg-12 col-md-12 float-left p-0">
            <div className="leftColumn col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0">
              {this.props.dueProducts.length !== 0 &&
                this.props.dueProducts.map(value => (
                  <DuePaymentCard key={value.policyName} dueProduct={value} />
                ))}
              {this.props.dueProducts.length === 0 && <NoDuePaymentCard />}
            </div>
          </div> */}
        </div>

        <div className='singleCol activeRowTable'>
          <h2 className='homeHederText'>Your Active Products</h2>

          {this.props.activeProducts.map(value => (
            <div key={value.policyNumber} className='flexWhiteBox col-lg-12 col-md-12 float-left p-0'>
              <div className='leftColumn col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0'>
                <div className='headerColumn'>
                  <h3>
                    <Link title={""} to={`/product-details/${value.policyNumber}`}>
                      {value.productDesc}
                    </Link>
                  </h3>
                  <div className='btn-links'>
                    <span className={'paymentStatusBadge ' + value.paymentStatusDisplayStyle}>
                      {value.paymentStatus}
                    </span>
                  </div>
                </div>
                <div className='bodyRow'>
                  <div className='flexBox'>
                    <ul>
                      <li>
                        <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                        <span className='value'>{value.policyNumber}</span>
                      </li>
                      <li>
                        <span className='label'>PAYMENT FREQUENCY</span>
                        <span className='value'>{value.currentPaymentFrequecyType}</span>
                      </li>
                      <li>
                        <span className='label'>AMOUNT DUE</span>
                        <span className='value'>
                          {value.amountDue}
                          <sup dangerouslySetInnerHTML={{ __html: value.amountDueSpecialCharacter }}></sup>
                        </span>
                      </li>
                      <li>
                        <span className='label'>DUE DATE</span>
                        <span className='value'>{value.dueDate}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {this.props.activeProducts.length !== 0 && <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.AUTOPAY_MARKETTING] }} ></div>}
          {this.state.showClaimNow && <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.CLAIM_NOW] }} ></div>}
          {this.props.hasEAPlusProduct === 'Y' && <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.EA_PLUS_MEMBER_NOTE] }} ></div>}
          <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
          <br /><br />
          {this.getDisclaimers()}
          <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.TRANSACTION_DISCLAIMER] }}></div>
        </div>
      </div>
    );
  }
}
