import React, { Component } from "react";
import "./NoDuePaymentCard.scss";

export class NoDuePaymentCard extends Component {
  render() {
    return (
      <div className='flexWhiteBox col-lg-12 col-md-12 float-left p-0'>
        <div className='leftColumn col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0'>
          <div className='noDuesContent'>
            <div className='bodyRow'>
              <div className='flexBox'>
                <div className='noDues'>
                  <label className='materialicons'>done</label>
                  <label className='dueText'>No Payments Due</label>
                </div>
              </div>
            </div>
            <div className='footerCol'></div>
          </div>
        </div>
      </div>
    );
  }
}
