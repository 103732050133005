import React from "react";
import { ARTICLES } from '../../../_constants/constants';
import { SharedService } from '../../../_services/shared.service';
import TagManager from 'react-gtm-module';
import { withRouter } from "react-router-dom";

class InitOperations extends React.Component {
    sharedService = new SharedService();

    componentDidMount() {
        let { gtmId } = JSON.parse(localStorage.getItem("initialIds"));

        this.routeChangeSubscription = this.props.history.listen(location => {
            window.scrollTo(0, 0);
            this.props.notificationContext.resetMessages();

            // Update GTM Page Views if we have GTM id
            setTimeout(() => {
                this.props.globalContext.triggerGTMDataLayer({
                    event: 'MyAccountPageView',
                    pagePath: location.pathname,
                    pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
                })
            }, 500)

        });


        this.props.globalContext.getCreditCardsRegex();
        this.getPagesTitle();

        // Set Header Tags from web content
        let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
        let params = {
            globalGroupId: globalGroupId,
            domainGroupId: domainGroupId,
            titles: [ARTICLES.HEADER_TAGS, ARTICLES.CUSTOM_SCRIPTS_BODY_START, ARTICLES.CUSTOM_SCRIPTS_BODY_END]
        };

        this.sharedService.getContents(params).subscribe(response => {
            if (response.status === 200 && response.data.items) {
                let hasHeadersTags = response.data.items.find((item) => item.title === ARTICLES.HEADER_TAGS);
                let hasBodyStart = response.data.items.find((item) => item.title === ARTICLES.CUSTOM_SCRIPTS_BODY_START);
                let hasBodyEnd = response.data.items.find((item) => item.title === ARTICLES.CUSTOM_SCRIPTS_BODY_END);

                // Append tags in Head
                if (hasHeadersTags) {
                    let tagsStr = hasHeadersTags.content;
                    document.getElementsByTagName('head')[0].insertAdjacentHTML('beforeend', tagsStr);

                    setTimeout(() => {
                        // execute scirpt in head src
                        var s = document.getElementsByTagName('head')[0].getElementsByTagName('script');
                        for (var i = 0; i < s.length; i++) {
                            var node = s[i], parent = node.parentElement, d = document.createElement('script');
                            d.async = node.async;
                            d.src = node.src;
                            parent.insertBefore(d, node);
                            parent.removeChild(node);
                        }
                    }, 500)
                }

                // Append Custom Scripts at body start
                if (hasBodyStart) {
                    this.loadCustomScript(hasBodyStart)
                }

                // Append Custom Scripts at body end
                if (hasBodyEnd) {
                    this.loadCustomScript(hasBodyEnd)
                }
            }
        });

        // Initialize GTM

        if (gtmId) {
            let tagManagerArgs = { gtmId: gtmId }
            TagManager.initialize(tagManagerArgs);

            setTimeout(() => { // Send the first pages that is loaded to GTM
                this.props.globalContext.triggerGTMDataLayer({
                    event: 'MyAccountPageView',
                    pagePath: this.props.location.pathname,
                    pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
                })
            }, 3000)
        }
    }

    getPagesTitle = () => {
        let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
        let params = {
            globalGroupId: globalGroupId,
            domainGroupId: domainGroupId,
            titles: [ARTICLES.PAGES_TITLE]
        };

        this.sharedService.getPagesTitle(params).subscribe(response => {
            if (response.status === 200) {
                this.props.globalContext.setPagesTitle(JSON.parse(response.data.items[0].content))
            }
        });
    };

    loadCustomScript = (scriptsData) => {
        var parser = new DOMParser();
        let doc = parser.parseFromString(scriptsData.content, 'text/html');
        let body = document.getElementsByTagName('body')[0]
        let scriptTags = doc.getElementsByTagName('script');
        scriptTags.forEach((tag) => {
            var newScript = document.createElement("script");
            var inlineScript = document.createTextNode(tag.innerHTML);
            newScript.appendChild(inlineScript);
            if (scriptsData.title === ARTICLES.CUSTOM_SCRIPTS_BODY_START) {
                body.insertBefore(newScript, body.firstChild)
            } else {
                body.appendChild(newScript)
            }
        })
    }

    render() {
        return (null
        )
    }
}

export default withRouter(InitOperations);