import React from "react";
import Modal from "react-bootstrap/Modal";
import { CreditCardForm } from "../form-credit-card/CreditCardForm";
import { BankAccountForm } from "../form-bank-account/BankAccountForm";
import { NOTIFICATIONS, CARDS_TYPE } from "../../../../_constants/constants";
import { SharedService } from "../../../../_services/shared.service";
import { PaymentCenterService } from "../../../../_services/payment-center.service";
import { Notification } from "../../../shared/notifications/notifications";
import "./UpdatePaymentMethod.scss";

export class UpdatePaymentMethods extends React.Component {
  sharedService = new SharedService();
  paymentCenterService = new PaymentCenterService();
  rememberMethodForPaths = ['/payment-center/pay-now'] // The paths for which we need to show remember checkbox

  state = {
    creditCardForm: {
      selectedCardType: "",
      cardHolderName: "",
      cardNumber: "",
      expMonth: "",
      expYear: "",
      address1: "",
      address2: "",
      address3: "",
      selectedState: "",
      city: "",
      zip: "",
      editMode: false
    },
    bankAccountForm: {
      type: "C",
      accountName: "",
      originalRoutingNumber: "",
      maskedRoutingNumber: "",
      confirmRountingNum: "",
      accountNum: "",
      confirmAccountNum: "",
      address1: "",
      address2: "",
      address3: "",
      selectedState: "",
      city: "",
      zip: "",
      editMode: false
    },

    showRememberMe: false,
    rememberMe: false,
    selectedForm: "",
    isFormValid: false,
    message: {
      type: "",
      content: [""]
    }
  };

  componentDidMount() {

    this.setState({ showRememberMe: this.rememberMethodForPaths.includes(this.props.location.pathname) });

    if (this.props.selectedPaymentMethod) {
      if (this.props.selectedPaymentMethod.bankAccount) {
        let { bankAccountForm } = this.state;
        bankAccountForm["type"] = this.props.selectedPaymentMethod.bankAccount.paymentType === "Checking" ? "C" : "S";
        bankAccountForm["accountName"] = this.props.selectedPaymentMethod.cardHolderName;
        bankAccountForm["originalRoutingNumber"] = this.props.selectedPaymentMethod.bankAccount.originalRoutingNumber;
        bankAccountForm["maskedRoutingNumber"] = this.props.selectedPaymentMethod.bankAccount.maskedRoutingNumber;
        bankAccountForm["confirmRountingNum"] = this.props.selectedPaymentMethod.bankAccount.originalRoutingNumber;
        bankAccountForm["accountNum"] = this.props.selectedPaymentMethod.bankAccount.accountNumber;
        bankAccountForm["confirmAccountNum"] = this.props.selectedPaymentMethod.bankAccount.accountNumber;
        bankAccountForm["address1"] = this.props.selectedPaymentMethod.address.addressLine1;
        bankAccountForm["address2"] = this.props.selectedPaymentMethod.address.addressLine2 || "";
        bankAccountForm["address3"] = this.props.selectedPaymentMethod.address.addressLine3 || "";
        bankAccountForm["selectedState"] = this.props.selectedPaymentMethod.address.stateCode;
        bankAccountForm["city"] = this.props.selectedPaymentMethod.address.cityName;
        bankAccountForm["zip"] = this.props.selectedPaymentMethod.address.zipCode;
        bankAccountForm["editMode"] = true;
        this.setState(
          {
            bankAccountForm: bankAccountForm,
            selectedForm: "BANK_ACCOUNT"
          },
          () => {
            this.checkRequiredFieldsForBankAcc();
          }
        );
      } else {
        let { creditCardForm } = this.state;
        creditCardForm["selectedCardType"] = CARDS_TYPE[CARDS_TYPE.findIndex(x => x.name === this.props.selectedPaymentMethod.creditCard.paymentType)].id;
        creditCardForm["cardHolderName"] = this.props.selectedPaymentMethod.cardHolderName;
        creditCardForm["cardNumber"] = this.props.selectedPaymentMethod.creditCard.cardNumber;
        creditCardForm["expMonth"] = this.props.selectedPaymentMethod.creditCard.expirationDate.split("/")[0];
        creditCardForm["expYear"] = this.props.selectedPaymentMethod.creditCard.expirationDate.split("/")[1];
        creditCardForm["address1"] = this.props.selectedPaymentMethod.address.addressLine1;
        creditCardForm["address2"] = this.props.selectedPaymentMethod.address.addressLine2 || "";
        creditCardForm["address3"] = this.props.selectedPaymentMethod.address.addressLine3 || "";
        creditCardForm["selectedState"] = this.props.selectedPaymentMethod.address.stateCode;
        creditCardForm["city"] = this.props.selectedPaymentMethod.address.cityName;
        creditCardForm["zip"] = this.props.selectedPaymentMethod.address.zipCode;
        creditCardForm["editMode"] = true;
        this.setState(
          {
            creditCardForm: creditCardForm,
            selectedForm: "CREDIT_CARD"
          },
          () => {
            this.checkRequiredFieldsForCard();
          }
        );
      }
    } else {
      this.setState(
        {
          selectedForm: "CREDIT_CARD"
        },
        () => {
          this.checkRequiredFieldsForCard();
        }
      );
    }

    setTimeout(() => {
      this.props.globalContext.triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: `/${this.props.selectedPaymentMethod ? "edit" : "add"}-payment-method`,
        pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${JSON.parse(localStorage.getItem("initialIds")).clientName} - ${this.props.selectedPaymentMethod ? "Edit" : "Add"} Payment Method`
      })
    }, 1000)
  }

  componentWillUnmount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: this.props.location.pathname,
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
    })
  }

  checkRequiredFieldsForCard() {
    if (/^\s*$/.test(this.state.creditCardForm.selectedCardType)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.creditCardForm.cardHolderName)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.creditCardForm.cardNumber)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.creditCardForm.expMonth)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.creditCardForm.expYear)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.creditCardForm.address1)) {
      this.setState({ isFormValid: false });
      return;
    }

    // if (/^\s*$/.test(this.state.creditCardForm.address2)) {
    //   this.setState({ isFormValid: false });
    //   return;
    // }

    // if (/^\s*$/.test(this.state.creditCardForm.address3)) {
    //   this.setState({ isFormValid: false });
    //   return;
    // }

    if (/^\s*$/.test(this.state.creditCardForm.selectedState)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.creditCardForm.city)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.creditCardForm.zip)) {
      this.setState({ isFormValid: false });
      return;
    }

    this.setState({ isFormValid: true });
    return;
  }

  validateBankAccountDetails = () => {
    let errorString = "";

    if (this.state.bankAccountForm.accountNum !== this.state.bankAccountForm.confirmAccountNum) {
      // errorString.push(NOTIFICATIONS.ACCOUNT_NUM_NOT_MATCH);
      errorString = errorString + NOTIFICATIONS.ACCOUNT_NUM_NOT_MATCH + "\n";
    }

    if (this.state.bankAccountForm.originalRoutingNumber !== this.state.bankAccountForm.confirmRountingNum) {
      // errorString.push(NOTIFICATIONS.ROUTE_NUM_NOT_MATCH);
      errorString = errorString + NOTIFICATIONS.ROUTE_NUM_NOT_MATCH + "\n";
    }

    if (errorString !== "") {
      return errorString;
    } else {
      return null;
    }
  };

  validationLocation = ({ selectedState, city, zip }) => {
    return new Promise((res, rej) => {
      // let { zip } = this.state;
      this.sharedService.validationLocation(zip).subscribe(response => {
        if (response.status === 200 && response.data.state_short === selectedState && response.data.postal_code === zip && response.data.city === city) {
          res({
            type: "SUCCESS",
            content: [""]
          });
        } else {
          res({
            type: "FAILURE",
            content: [NOTIFICATIONS.INVALID_LOCATION]
          });
        }
      });
    });
  };

  submitCreditCard = async () => {

    let cardValidation = await this.props.globalContext.validateCardDetails(this.state.creditCardForm.cardNumber, this.state.creditCardForm.selectedCardType);

    if (this.props.selectedPaymentMethod || cardValidation.status === 'SUCCESS') {  // If edit, then card validation is not mandatory
      if (this.state.isFormValid) {
        let { companyId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
        let { userid } = JSON.parse(localStorage.getItem("user"));
        let params = {
          compnayId: companyId,
          domainGroupId: domainGroupId,
          userName: userid,
          isCreditCard: this.state.selectedForm === "CREDIT_CARD",
          isBankAccount: this.state.selectedForm === "BANK_ACCOUNT",
          cardType: this.state.creditCardForm.selectedCardType,
          cardHolderName: this.state.creditCardForm.cardHolderName,
          cardNumber: this.state.creditCardForm.cardNumber,
          expirationDate: this.state.creditCardForm.expMonth + "/" + this.state.creditCardForm.expYear,
          addressLine1: this.state.creditCardForm.address1,
          addressLine2: this.state.creditCardForm.address2,
          addressLine3: this.state.creditCardForm.address3,
          city: this.state.creditCardForm.city,
          state: this.state.creditCardForm.selectedState,
          zipCode: this.state.creditCardForm.zip,
          methodType: CARDS_TYPE[CARDS_TYPE.findIndex(x => x.id === this.state.creditCardForm.selectedCardType)].name
        };

        if (this.props.selectedPaymentMethod) {
          params["paymentRecordId"] = this.props.selectedPaymentMethod.paymentRecordId;
          this.updatePaymentMethod(params);
        } else {
          this.addPaymentMethod(params);
        }
      }
    } else {
      this.props.notificationContext.setPopupNotifications([{
        type: cardValidation.status,
        content: [cardValidation.message]
      }])
      this.setState({ loading: false });
    }
  };

  updatePaymentMethod = params => {
    this.props.setLoading(true);
    this.paymentCenterService.updatePaymentMethod(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {
        this.props.notificationContext.resetPopupNotifications();
        this.props.hide(response.data.paymentMethods, response.data.messages);
      } else {
        this.props.notificationContext.setPopupNotifications(response.data.messages)
      }
    });
  };

  addPaymentMethod = params => {

    if (this.state.showRememberMe) {
      params["addPaymentMethodFrom"] = 'paynow';
      params["rememberPaymentMethod"] = this.state.rememberMe ? 'Y' : 'N';
    }
    else {
      params["addPaymentMethodFrom"] = null;
      params["rememberPaymentMethod"] = null;
    }

    this.props.setLoading(true);
    this.paymentCenterService.addPaymentMethod(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {
        this.props.notificationContext.resetPopupNotifications();
        this.props.hide(response.data.paymentMethods, response.data.messages);
      } else {
        this.props.notificationContext.setPopupNotifications(response.data.messages)
      }
    });
  };

  submitBankAccount = () => {
    if (this.state.isFormValid) {
      let hasValidationError = this.validateBankAccountDetails();
      if (hasValidationError === null) {
        let { companyId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
        let { userid } = JSON.parse(localStorage.getItem("user"));
        let params = {
          compnayId: companyId,
          domainGroupId: domainGroupId,
          userName: userid,
          isCreditCard: this.state.selectedForm === "CREDIT_CARD",
          isBankAccount: this.state.selectedForm === "BANK_ACCOUNT",
          isCheckingAccount: this.state.bankAccountForm.type === "C",
          isSavingAccount: this.state.bankAccountForm.type === "S",
          nameOnAccount: this.state.bankAccountForm.accountName,
          originalRoutingNumber: this.state.bankAccountForm.originalRoutingNumber,
          accountNumber: this.state.bankAccountForm.accountNum,
          cardHolderName: this.state.bankAccountForm.accountName,
          paymentType: this.state.bankAccountForm.type,
          addressLine1: this.state.bankAccountForm.address1,
          addressLine2: this.state.bankAccountForm.address2,
          addressLine3: this.state.bankAccountForm.address3,
          city: this.state.bankAccountForm.city,
          state: this.state.bankAccountForm.selectedState,
          zipCode: this.state.bankAccountForm.zip,
          methodType: this.state.bankAccountForm.type === "C" ? "Checking" : "Savings"
        };

        if (this.props.selectedPaymentMethod) {
          params["paymentRecordId"] = this.props.selectedPaymentMethod.paymentRecordId;
          this.updatePaymentMethod(params);
        } else {
          this.addPaymentMethod(params);
        }
      } else {
        this.props.notificationContext.setPopupNotifications([{
          type: "ERROR",
          content: [hasValidationError]
        }])
        this.setState({ loading: false });
      }
    }
  };

  handleSubmit = () => {
    this.state.selectedForm === "CREDIT_CARD" ? this.submitCreditCard() : this.submitBankAccount();
  };

  handleCCInputChange = e => {
    const { name, value } = e.target;
    let { creditCardForm } = this.state;
    creditCardForm[name] = value;
    this.setState(
      {
        creditCardForm: creditCardForm
      },
      () => {
        this.checkRequiredFieldsForCard();
      }
    );
  };

  checkRequiredFieldsForBankAcc = () => {
    if (/^\s*$/.test(this.state.bankAccountForm.originalRoutingNumber)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.bankAccountForm.accountName)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.bankAccountForm.confirmRountingNum)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.bankAccountForm.accountNum)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.bankAccountForm.confirmAccountNum)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.bankAccountForm.address1)) {
      this.setState({ isFormValid: false });
      return;
    }

    // if (/^\s*$/.test(this.state.bankAccountForm.address2)) {
    //   this.setState({ isFormValid: false });
    //   return;
    // }

    // if (/^\s*$/.test(this.state.creditCardForm.address3)) {
    //   this.setState({ isFormValid: false });
    //   return;
    // }

    if (/^\s*$/.test(this.state.bankAccountForm.selectedState)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.bankAccountForm.city)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.bankAccountForm.zip)) {
      this.setState({ isFormValid: false });
      return;
    }

    this.setState({ isFormValid: true });
    return;
  };

  handleBAInputChange = e => {
    const { name, value } = e.target;
    let { bankAccountForm } = this.state;
    bankAccountForm[name] = value;
    this.setState(
      {
        bankAccountForm: bankAccountForm
      },
      () => {
        this.checkRequiredFieldsForBankAcc();
      }
    );
  };

  toggleTabs = type => {
    this.props.notificationContext.setPopupNotifications([])
    this.setState({
      selectedForm: type
    });
    type === "CREDIT_CARD" ? this.checkRequiredFieldsForCard() : this.checkRequiredFieldsForBankAcc();
  };

  handleKeyDownCards = e => {
    if (e.key === "Enter") {
      this.submitCreditCard();
    }
  };

  handleKeyDownBankAccount = e => {
    if (e.key === "Enter") {
      this.submitBankAccount();
    }
  };

  handleRememberChkBox = () => {
    this.setState({ rememberMe: !this.state.rememberMe });
  };

  render() {
    return (
      <Modal
        className="addPaymentMethodPopup"
        backdrop='static'
        show={this.props.show}
        onHide={() => {
          this.props.notificationContext.resetPopupNotifications();
          this.props.hide();
        }}>
        <Modal.Header closeButton>
          <div className='col-12 p-0'>
            <Modal.Title>{this.props.selectedPaymentMethod ? "Edit" : "Add"} Payment Method</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.props.selectedPaymentMethod === null && (
            <ul className='nav'>
              <li className='nav-item'>
                <div className='custom-control-add custom-radio'>
                  <input
                    type='radio'
                    id='defaultUnchecked1'
                    name='defaultExampleRadios'
                    checked={this.state.selectedForm === "CREDIT_CARD"}
                    value='CREDIT_CARD'
                    onChange={() => {
                      this.toggleTabs("CREDIT_CARD");
                    }}
                    className={"custom-control-input nav-link" + (this.state.selectedForm === "CREDIT_CARD" && "active")}
                  />
                  <span className='materialicons uncheck' htmlFor='defaultUnchecked1'>
                    radio_button_unchecked
                  </span>
                  <span className='materialicons check' htmlFor='defaultUnchecked1'>
                    radio_button_checked
                  </span>
                  <span
                    onClick={() => {
                      this.toggleTabs("CREDIT_CARD");
                    }}
                    className={"accountInfoTab " + (this.state.selectedForm === "CREDIT_CARD" && "active")}>
                    Credit Card
                  </span>
                </div>
              </li>
              <li className='nav-item'>
                <div className='custom-control custom-radio'>
                  <input
                    type='radio'
                    id='defaultUnchecked'
                    name='defaultExampleRadios'
                    checked={this.state.selectedForm === "BANK_ACCOUNT"}
                    value='BANK_ACCOUNT'
                    onChange={() => {
                      this.toggleTabs("BANK_ACCOUNT");
                    }}
                    className={"custom-control-input nav-link " + (this.state.selectedForm === "BANK_ACCOUNT" && "active")}
                  />
                  <span className='materialicons uncheck' htmlFor='defaultUnchecked'>
                    radio_button_unchecked
                  </span>
                  <span className='materialicons check' htmlFor='defaultUnchecked'>
                    radio_button_checked
                  </span>
                  <span
                    onClick={() => {
                      this.toggleTabs("BANK_ACCOUNT");
                    }}
                    className={"accountInfoTab " + (this.state.selectedForm === "BANK_ACCOUNT" && "active")}>
                    Bank Account
                  </span>
                </div>
              </li>
            </ul>
          )}
          {this.props.notificationContext.state.popupNotifications.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
          {this.state.selectedForm === "CREDIT_CARD" && (
            <CreditCardForm form={this.state.creditCardForm} years={this.props.years} inputChange={this.handleCCInputChange} handleKeyDown={this.handleKeyDownCards} />
          )}
          {this.state.selectedForm === "BANK_ACCOUNT" && (
            <BankAccountForm form={this.state.bankAccountForm} inputChange={this.handleBAInputChange} handleKeyDown={this.handleKeyDownBankAccount} />
          )}

          {!this.props.selectedPaymentMethod && this.state.showRememberMe && <div className='rememberMethod'>
            <input type='checkbox' name='remember' checked={this.state.rememberMe} onChange={this.handleRememberChkBox} />
            <span className="material-icons blankCheckbox">check_box_outline_blank</span>
            <span className="material-icons selectedCheckbox">check_box</span>
            <span>
              Remember Payment Method
            </span>
          </div>}

        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn agiaBlueButton-outline'
            data-dismiss='modal'
            onClick={() => {
              this.props.notificationContext.resetPopupNotifications();
              this.props.hide();
            }}>
            Cancel
          </button>

          <button
            id="ma-pm-saveChanges"
            disabled={(this.state.isFormValid === false) || (this.props.selectedPaymentMethod && this.props.selectedPaymentMethod.managePaymentMethodOption.saveChanges.disable === 'Y') || this.props.globalContext.isCustomerServiceUser()}
            type='button'
            className='btn agiaBlueButton'
            data-dismiss='modal'
            onClick={this.handleSubmit}>
            Save changes
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
