import React from "react";
import Modal from "react-bootstrap/Modal";
import { SharedService } from "../../../../_services/shared.service";
import { AuthenticationService } from "../../../../_services/auth.service";
import { Notification } from "../../../shared/notifications/notifications";

export class ChangePassword extends React.Component {
  sharedService = new SharedService();
  authenticationService = new AuthenticationService();

  state = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    hideOldPassword: true,
    hideNewPassword: true,
    hideConfirmPassword: true,
    isFormValid: false,
    validations: {
      greaterThan8: false,
      hasLowerCase: false,
      hasUpperCase: false,
      hasNumber: false,
      newConfirmMatch: false,
      oldPasswordProvided: false
    }
  };

  componentDidMount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: '/change-password',
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${JSON.parse(localStorage.getItem("initialIds")).clientName} - Change Password`
    })
  }

  componentWillUnmount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: this.props.location.pathname,
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
    })
  }

  checkValidation = () => {
    return new Promise((res, rej) => {
      this.setState(
        {
          validations: {
            greaterThan8: this.state.newPassword.length >= 8,
            hasLowerCase: this.state.newPassword.match(/^(.*[a-z].*)$/),
            hasUpperCase: this.state.newPassword.match(/^(.*[A-Z].*)$/),
            hasNumber: !this.state.newPassword.match(/^([^0-9]*)$/),
            newConfirmMatch: this.state.newPassword !== "" && this.state.confirmPassword !== "" && this.state.newPassword === this.state.confirmPassword,
            oldPasswordProvided: !/^\s*$/.test(this.state.oldPassword)
          }
        },
        () => {
          if (
            this.state.validations.greaterThan8 &&
            this.state.validations.hasLowerCase &&
            this.state.validations.hasUpperCase &&
            this.state.validations.hasNumber &&
            this.state.validations.newConfirmMatch &&
            this.state.validations.oldPasswordProvided
          ) {
            res(true);
          } else {
            res(false);
          }
        }
      );
    });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, async () => {
      let isFormValid = await this.checkValidation();
      this.setState({ isFormValid: isFormValid });
    });
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  onSubmit = async () => {
    let isFormValid = await this.checkValidation();

    if (isFormValid) {
      let { companyId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
      let hostName = document.location.hostname;
      let userId = JSON.parse(localStorage.getItem("user")).userid;
      let params = {
        username: userId,
        currentPassword: this.state.oldPassword,
        password: this.state.newPassword,
        companyId: companyId,
        hostName: hostName,
        domainGroupId: domainGroupId
      };

      this.props.setLoading(true);
      this.sharedService.changePassword(params).subscribe(response => {
        this.props.setLoading(false);
        if (response.status === 200) {
          this.props.notificationContext.resetPopupNotifications();
          this.props.hide({
            messages: response.data.messages
          });
        } else {
          this.props.notificationContext.setPopupNotifications(response.data.messages)
        }
        // this.props.setLoading(false);
      });
    }
  };

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.show}
        onHide={() => {
          this.props.notificationContext.resetPopupNotifications();
          this.props.hide();
        }}>
        <Modal.Header closeButton>
          <div className='col-12 p-0'>
            <Modal.Title>Change Password</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.props.notificationContext.state.popupNotifications.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
          <div className='formPanel'>
            <div className='form-group'>
              <div className='oldPassword'>
                <label htmlFor='oldpassword'>Old Password</label>
                <input
                  className='form-control password'
                  type={this.state.hideOldPassword ? "password" : "text"}
                  onKeyDown={this.handleKeyDown}
                  name='oldPassword'
                  noValidate
                  onChange={this.handleChange}
                />
                <label
                  onClick={() => {
                    this.setState({
                      hideOldPassword: !this.state.hideOldPassword
                    });
                  }}
                  className='showHide'>
                  <i className={"far " + (this.state.hideOldPassword ? "fa-eye" : "fa-eye-slash")}></i>
                </label>
              </div>
            </div>
            <div className='form-group'>
              <div className='npassword'>
                <label htmlFor='npassword'>New Password</label>
                <input
                  className='form-control password'
                  type={this.state.hideNewPassword ? "password" : "text"}
                  onKeyDown={this.handleKeyDown}
                  name='newPassword'
                  noValidate
                  onChange={this.handleChange}
                />
                <label
                  onClick={() => {
                    this.setState({
                      hideNewPassword: !this.state.hideNewPassword
                    });
                  }}
                  className='showHide'>
                  <i className={"far " + (this.state.hideNewPassword ? "fa-eye" : "fa-eye-slash")}></i>
                </label>
              </div>
            </div>
            <div className='form-group'>
              <div className='passwordPolicy'>
                <p className='heading'>Password must have</p>
                <span className={this.state.validations.greaterThan8 ? "valid" : "invalid"}>at least 8 characters in length </span> <br></br>
                <span className={this.state.validations.hasLowerCase ? "valid" : "invalid"}>at least 1 lowercase letter (a-z) </span> <br></br>
                <span className={this.state.validations.hasUpperCase ? "valid" : "invalid"}>at least 1 uppercase letter (A-Z) </span> <br></br>
                <span className={this.state.validations.hasNumber ? "valid" : "invalid"}>at least 1 number (i.e. 0-9) </span> <br></br>
                <span className={this.state.validations.newConfirmMatch ? "valid" : "invalid"}>New and Confirm password must match </span> <br></br>
              </div>
            </div>

            <div className='form-group'>
              <div className='email'>
                <label htmlFor='email'>Confirm New Password</label>
                <input
                  className='form-control password'
                  type={this.state.hideConfirmPassword ? "password" : "text"}
                  name='confirmPassword'
                  onKeyDown={this.handleKeyDown}
                  noValidate
                  onChange={this.handleChange}
                />
                <label
                  onClick={() => {
                    this.setState({
                      hideConfirmPassword: !this.state.hideConfirmPassword
                    });
                  }}
                  className='showHide'>
                  <i className={"far " + (this.state.hideConfirmPassword ? "fa-eye" : "fa-eye-slash")}></i>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn agiaBlueButton-outline'
            data-dismiss='modal'
            onClick={() => {
              this.props.notificationContext.resetPopupNotifications();
              this.props.hide();
            }}>
            Cancel
          </button>

          <button type='button' disabled={!this.state.isFormValid} className='btn agiaBlueButton' data-dismiss='modal' onClick={this.onSubmit}>
            Save Password changes
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
