import * as axios from 'axios';
import { from } from 'rxjs';
import { NOTIFICATIONS } from '../../_constants/constants'
import { listenersService } from '../../_services/listeners.service'
import { isCustomer } from '../../_routes/app-routes';
import { byPassCsUserId } from '../../_constants/url.constants';
export class HttpRequest {
  getRequestHeaders() {
    let user = isCustomer() ? localStorage.getItem('ccuser') : localStorage.getItem('user');
    let access_token = user ? JSON.parse(user).access_token : null;
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: access_token ? ("Bearer " + access_token) : ''
      }

    }
  }

  get(url) {
    var request = axios.get(url, this.getRequestHeaders()).then(res => res).catch(error => {
      return this.handleError(error);
    });
    return from(request);
  }

  post(url, data) {
    if (localStorage.getItem('user')) {
      let csUser = JSON.parse(localStorage.getItem('user'));
      if (csUser.hasOwnProperty('csUserid') && byPassCsUserId.indexOf(url) === -1) {
        data['csUserid'] = csUser.csUserid;
      }
    }

    var request = axios.post(url, data, this.getRequestHeaders()).then(res => res).catch(error => {
      return this.handleError(error);
    });
    return from(request);
  }

  put(url, data) {
    var request = axios.put(url, data, this.getRequestHeaders()).then(res => res).catch(error => {
      return this.handleError(error);
    });
    return from(request);
  }

  delete(url, data) {
    var request = axios.delete(url, this.getRequestHeaders()).then(res => res).catch(error => {
      return this.handleError(error);
    });
    return from(request);
  }

  handleError(error) {
    if (error.message === 'Network Error') { // Handle Network Error
      return {
        status: 'Network Error',
        data: {
          message: NOTIFICATIONS.CONNECTIVITY_ERROR
        }
      }
    } else { // Server Error

      if (error.response.status === 403) {
        localStorage.removeItem("user");
        localStorage.removeItem("ccuser");
        listenersService.triggreAuthStateChanged(null);
        return error.response;
      } else {
        return error.response;
      }
    }
  }
}