import React, { useState } from "react";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { ARTICLES } from "../../../../../_constants/constants";
import { convertStringToAmount } from '../../../../../_services/utility.service';

export const ProductsMobile = props => {
  const [open, setOpen] = useState(false);

  const toggleOpen = async id => {
    let selectedProduct = props.products.find((product) => product.policyNumber === id)
    if (open === false || open[id] === false || open[id] === undefined) {
      !selectedProduct.paymentInfoLoaded && await props.getPaymentInfo(id)
    }

    setOpen(value => ({
      ...value,
      [id]: !value[id]
    }));
  };

  const getDisclaimers = () => {
    let disclaimerTypesMap = [
      { name: 'autopay', content: props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER] },
      { name: 'combinedbill', content: props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING] },
      { name: 'nocosttoyou', content: props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_NOCOST] },
    ]
    let components = [];
    let iterated = [];
    props.products.forEach((product, index) => {
      let disclaimerObj = disclaimerTypesMap.find((item) => item.name === product.paymentStatusDisplayStyle);
      let hasAlreadyIterated = !iterated.includes(product.paymentStatusDisplayStyle);

      if (disclaimerObj && hasAlreadyIterated) {
        components.push(<div key={index} dangerouslySetInnerHTML={{ __html: disclaimerObj.content }} ></div>)
        iterated.push(product.paymentStatusDisplayStyle);
      }
    });

    return components;
  }

  const getFooterBtns = value => {
    let enablePayNow = value.paymentAction.payNowLink.disable !== 'Y';
    let enableFrequency = value.paymentAction.changeAutoPayOrFrequency.disable !== 'Y';
    let payNowBtnLabel = value.paymentAction.payNowLink.label;
    let freqBtnLabel = value.paymentAction.changeAutoPayOrFrequency.label;

    if (value.paymentStatus && enablePayNow) {
      return (
        <div className='footerCol'>
          {enableFrequency &&
            <div className='leftText'>
              <Link
                className="ma-pc-autopayFrequency"
                to='#'
                onClick={() => {
                  props.handleChangeFreqAutoPay(value);
                }}>
                {freqBtnLabel}
              </Link>
            </div>
          }

          <div className='rightBtn'>
            <div className='squareButton'>
              <button
                onClick={() => {
                  props.handlePayNow(value);
                }}
                className='ma-pc-payNow btn agiaBlueButton payNow'
                type='button'
                value='Submit'>
                {payNowBtnLabel}
              </button>
            </div>
          </div>
        </div>
      );
    } else if (enableFrequency) {
      return (
        <div className='footerCol'>
          <div className='leftText singleCol'>
            <Link
              className="ma-pc-autopayFrequency"
              to='#'
              onClick={() => {
                props.handleChangeFreqAutoPay(value);
                // props.handlePayNow(value);
              }}>
              {freqBtnLabel}
            </Link>
          </div>
        </div>
      );
    }
  };

  return (
    <div className='mobileView homePayDues'>
      <div className='singleCol activeRowTable'>
        {props.products.map(value => (
          <div key={value.policyNumber} className='flexWhiteBox col-lg-12 col-md-12 float-left p-0'>
            <div className='leftColumn col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0'>
              <div className={"headerColumn " + (value.paymentStatus && value.paymentStatus.toLowerCase() === "due now" ? "selected" : "")}>
                <h3>
                  <Link title={""} to={`/product-details/${value.policyNumber}`}>
                    {" "}
                    {value.productDesc}
                  </Link>
                </h3>
                <div className='btn-links'>
                  <span className={'paymentStatusBadge ' + value.paymentStatusDisplayStyle}>
                    {value.paymentStatus}
                  </span>
                </div>
              </div>
              <div className='bodyRow'>
                <div className='flexBox'>
                  <ul>
                    <li>
                      <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                      <span className='value'>{value.policyNumber}</span>
                    </li>
                    <li>
                      <span className='label'>PAYMENT FREQUENCY</span>
                      <span className='value'>{value.currentPaymentFrequecyType}</span>
                    </li>
                    <li>
                      <span className='label'>AUTO PAY METHOD</span>
                      <span className='value'>{value.currentPaymentMethod}</span>
                    </li>
                    <li>
                      <span className='label'>AMOUNT DUE</span>
                      <span className='value'>
                        {value.showPaymentAmountBreakdown === false && <span>{value.amountDue}<sup dangerouslySetInnerHTML={{ __html: value.amountDueSpecialCharacter }}></sup></span>}
                        {value.showPaymentAmountBreakdown === true && (
                          <>
                            <span>
                              <Link
                                onClick={e => {
                                  e.preventDefault();
                                  toggleOpen(value.policyNumber);
                                }}
                                aria-controls='payNow-collapse-text'
                                aria-expanded={open[value.policyNumber] === undefined ? false : open[value.policyNumber]}
                                className='payNow-collapse-text'
                                to='#'>
                                <span className='priceValue'>{value.amountDue}<sup dangerouslySetInnerHTML={{ __html: value.amountDueSpecialCharacter }}></sup></span>
                                <span className='materialicons leftArrow'>chevron_left</span>
                                <span className='materialicons downArrow'>expand_more</span>
                              </Link>
                            </span>
                          </>
                        )}
                      </span>
                    </li>
                    {value.paymentInfo && (
                      <Collapse in={open[value.policyNumber]}>
                        <div id='payNowCollapseText'>
                          <div className='bodyRow'>
                            <div className='flexBox'>
                              <ul>
                                <li>
                                  <span className='label'>AMOUNT DUE</span>
                                  <span className='value'>{value.paymentInfo.amountDue || "-"}</span>
                                  <span className="tooltip-break-special-chars"><sup>{value.paymentInfo.amountDueSpecialCharacter}</sup></span>
                                </li>
                                <li>
                                  <span className='label'>PAST DUE</span>
                                  <span className='value'>{value.paymentInfo.pastDueAmount || "-"}</span>
                                  <span className="tooltip-break-special-chars"></span>
                                </li>
                                <li>
                                  <span className='label'>ADJUSTMENT</span>
                                  <span className='value'>{value.paymentInfo.adjustment || "-"}</span>
                                  <span className="tooltip-break-special-chars">{true && '\u00A0CR'}</span>
                                </li>
                                {convertStringToAmount(value.paymentInfo.billingCredit) !== 0 &&
                                  < li >
                                    <span className="label">Billing Credit</span>
                                    <span className="value">{value.paymentInfo.billingCredit || '-'}</span>
                                    <span className="tooltip-break-special-chars">{convertStringToAmount(value.paymentInfo.billingCredit) > 0 && '\u00A0CR'}</span>
                                  </li>
                                }
                                <li>
                                  <span className='label'>TOTAL AMOUNT DUE</span>
                                  <span className='value'>{value.paymentInfo.totalAmountDue || "-"}</span>
                                  <span className="tooltip-break-special-chars"></span>
                                </li>
                                {value.paymentInfo.disclaimer &&
                                  <li>
                                    <span className="tooltip-breakdown-disclaimer">{value.paymentInfo.disclaimer}</span>
                                  </li>
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    )}
                    <li>
                      <span className='label'>DUE DATE</span>
                      <span className='value'>{value.dueDate}</span>
                    </li>
                  </ul>
                </div>
              </div>

              {getFooterBtns(value)}
            </div>
          </div>
        ))}

        <div className='footerTextPara'>
          <div dangerouslySetInnerHTML={{ __html: props.globlWebContents[ARTICLES.AUTOPAY_MARKETTING] }} ></div>
          <div dangerouslySetInnerHTML={{ __html: props.globlWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
          {getDisclaimers()}
          <div dangerouslySetInnerHTML={{ __html: props.globlWebContents[ARTICLES.TRANSACTION_DISCLAIMER] }}></div>
        </div>

      </div>
    </div>
  );
};
