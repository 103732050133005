import React from "react";
import Modal from "react-bootstrap/Modal";
import { SharedService } from "../../../../_services/shared.service";
import { ProfileService } from "../../../../_services/profile.service";
import { Notification } from "../../../shared/notifications/notifications";

export class ChangeSecurityQuestionPopup extends React.Component {
  sharedService = new SharedService();
  profileService = new ProfileService();

  state = {
    selectedQuestion: this.props.selectedQuestion,
    selectedAnswer: "",
    isFormValid: false
  };

  componentDidMount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: '/change-security-question-answer',
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${JSON.parse(localStorage.getItem("initialIds")).clientName} - Change Security Q&A`
    })
  }

  componentWillUnmount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: this.props.location.pathname,
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
    })
  }

  checkRequiredFields = () => {
    if (/^\s*$/.test(this.state.selectedQuestion)) {
      this.setState({ isFormValid: false });
      return;
    }

    if (/^\s*$/.test(this.state.selectedAnswer)) {
      this.setState({ isFormValid: false });
      return;
    }

    this.setState({ isFormValid: true });
    return;
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.checkRequiredFields();
    });
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleSubmit = async () => {
    if (this.state.isFormValid) {
      let { companyId } = JSON.parse(localStorage.getItem("initialIds"));
      let { userid } = JSON.parse(localStorage.getItem("user"));
      let params = {
        companyId: companyId,
        securityQuestion: this.state.selectedQuestion,
        securityAnswer: this.state.selectedAnswer,
        userName: userid
      };

      this.props.setLoading(true);
      this.profileService.updateSecurityDetails(params).subscribe(response => {
        this.props.setLoading(false);
        if (response.status === 200) {
          this.props.notificationContext.resetPopupNotifications();
          this.props.hide({
            messages: response.data.messages,
            selectedQuestion: this.state.selectedQuestion
          });
        } else {
          this.props.notificationContext.setPopupNotifications(response.data.messages)
        }
      });
    }
  };

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.show}
        onHide={() => {
          this.props.notificationContext.resetPopupNotifications();
          this.props.hide();
        }}>
        <Modal.Header closeButton>
          <div className='col-12 p-0'>
            <Modal.Title>Change Security Q&A</Modal.Title>
          </div>
          {this.props.notificationContext.state.popupNotifications.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
        </Modal.Header>
        <Modal.Body>
          <div className='formPanel'>
            <div className='form-group'>
              <div className='oldPassword cardType'>
                <label htmlFor='oldpassword'>Select a Security Question</label>
                <select value={this.state.selectedQuestion} name='selectedQuestion' onChange={this.handleChange} className='questionAnswer'>
                  <option value=''>Please Select</option>
                  {this.props.questions.map(value => (
                    <option key={value.questionId} value={value.question}>
                      {value.question}
                    </option>
                  ))}
                </select>
                {/* <select>
                  <option>In what town were you born?</option>
                </select> */}
              </div>
            </div>
            <div className='form-group'>
              <div className='answerqa'>
                <label htmlFor='answerqa'>Answer</label>
                <input onChange={this.handleChange} onKeyDown={this.handleKeyDown} className='form-control' type='text' name='selectedAnswer' />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn agiaBlueButton-outline'
            data-dismiss='modal'
            onClick={() => {
              this.props.notificationContext.resetPopupNotifications();
              this.props.hide();
            }}>
            Cancel
          </button>

          <button
            //
            disabled={!this.state.isFormValid}
            type='button'
            className='btn agiaBlueButton'
            data-dismiss='modal'
            onClick={this.handleSubmit}>
            Save changes
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
