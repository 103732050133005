import React from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationService } from '../../../_services/auth.service';
import { listenersService } from '../../../_services/listeners.service'

class SessionManager extends React.Component {
    authenticationService = new AuthenticationService();
    originalTimer = 900000;
    originalTimeout;

    componentDidMount() {
        this.originalTimeout = setTimeout(() => {
            clearTimeout(this.originalTimeout);
            this.handleIdleTimeout();
        }, this.originalTimer);

        window.addEventListener('mousemove', () => {
            this.resetOriginalTimer()
        });

        window.addEventListener('keypress', () => {
            this.resetOriginalTimer()
        });
    }

    resetOriginalTimer() {
        clearTimeout(this.originalTimeout);

        this.originalTimeout = setTimeout(() => {
            this.handleIdleTimeout();
        }, this.originalTimer);
    }

    handleIdleTimeout = () => {
        this.resetOriginalTimer();
        this.authenticationService.isUserLoggedIn() && this.logout();
    }

    logout = () => {
        let user = localStorage.getItem("user") || localStorage.getItem("ccuser");
        let access_token = JSON.parse(user).access_token;

        this.props.setLoading(true);
        this.authenticationService.logout(access_token).subscribe(response => {
            this.props.setLoading(false);
            if (response.status === 200) {
                localStorage.removeItem("user");
                localStorage.removeItem("ccuser");
                listenersService.triggreAuthStateChanged(null);
                this.props.history.push("/");
            }
            else {
                this.props.notificationContext.processMessages(response.data.messages);
            }
        });
    };

    render() {
        return null;
    }
}

export default withRouter(SessionManager);