import React from "react";
import { CARDS_TYPE, MONTHS } from "../../../../_constants/constants";
import { formatCard, numberAndDash } from "../../../../_services/utility.service";
import { GlobalContext } from "../../../../_context/global.context";

export class CreditCardForm extends React.Component {
  formatCardNumber = ({ target: { value, name } }) => {
    const isValid = numberAndDash(value);
    if (isValid) {
      const formattedNum = formatCard(value);
      this.props.inputChange({
        target: {
          name: name,
          value: formattedNum
        }
      });
    }
  };

  checkZip = ({ target: { value, name } }) => {
    const isValid = numberAndDash(value);
    isValid &&
      this.props.inputChange({
        target: {
          name: name,
          value: value
        }
      });
  };

  render() {
    return (
        <div className='formPanel mainPopupWraper'>
        <GlobalContext.Consumer>
          {context => (
            <>
         <div className="leftPanel"> 
           <h2 className='bInfoText'>Payment Information</h2>
              <div className='form-group'>
                <div className='cardType'>
                  <label htmlFor='cardType'>Card Type</label>
                  <select disabled={this.props.form.editMode} value={this.props.form.selectedCardType} onChange={this.props.inputChange} name='selectedCardType'>
                    <option value=''>Select</option>
                    {CARDS_TYPE.map(value => (
                      <option key={value.id} value={value.id}>
                        {value.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='form-group'>
                <div className='nameOnCard '>
                  <label htmlFor='nameoncard'>Cardholder Name</label>
                  <input
                    // disabled={this.props.form.editMode}
                    className='form-control'
                    type='text'
                    name='cardHolderName'
                    value={this.props.form.cardHolderName}
                    onKeyDown={this.props.handleKeyDown}
                    onChange={this.props.inputChange}
                    maxLength='30'
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='ccNum'>
                  <label htmlFor='ccNum'>Credit Card Number</label>
                  <input
                    disabled={this.props.form.editMode}
                    className='form-control'
                    type='text'
                    name='cardNumber'
                    value={this.props.form.cardNumber}
                    onKeyDown={this.props.handleKeyDown}
                    onChange={this.formatCardNumber}
                  />
                </div>
              </div>

              <div className='form-group'>
                <div className='state'>
                  <label htmlFor='expdate'>Exp Date</label>
                  {/* <input className='form-control' type='text' name='expMonth' value={this.props.form.expMonth} onChange={this.props.inputChange} /> */}
                  <select value={this.props.form.expMonth} onChange={this.props.inputChange} name='expMonth'>
                    <option value=''>Select</option>
                    {MONTHS.map(value => (
                      <option key={value.id} value={value.id}>
                        {value.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='zipCode'>
                  <select value={this.props.form.expYear} onChange={this.props.inputChange} name='expYear'>
                    <option value=''>Select Year</option>
                    {this.props.years.map(value => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              </div>
             <div className="rightPanel"> 
                <h2 className='bInfoText'>Billing Information</h2>
              <div className='form-group'>
                <div className='address'>
                  <label htmlFor='address'>Address 1</label>
                  <input
                    className='form-control'
                    type='text'
                    name='address1'
                    value={this.props.form.address1}
                    onChange={this.props.inputChange}
                    onKeyDown={this.props.handleKeyDown}
                    maxLength='30'
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='address'>
                  <label htmlFor='address'>Address 2</label>
                  <input
                    className='form-control'
                    type='text'
                    name='address2'
                    value={this.props.form.address2}
                    onChange={this.props.inputChange}
                    onKeyDown={this.props.handleKeyDown}
                    maxLength='30'
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='address'>
                  <label htmlFor='address'>Address 3</label>
                  <input
                    className='form-control'
                    type='text'
                    name='address3'
                    value={this.props.form.address3}
                    onChange={this.props.inputChange}
                    onKeyDown={this.props.handleKeyDown}
                    maxLength='30'
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='city'>
                  <label htmlFor='city'>City</label>
                  <input
                    className='form-control'
                    type='text'
                    name='city'
                    value={this.props.form.city}
                    onChange={this.props.inputChange}
                    onKeyDown={this.props.handleKeyDown}
                    maxLength='30'
                  />
                </div>
              </div>

              <div className='form-group displayFlex'>
                <div className='state'>
                  <label htmlFor='state'>State</label>
                  <select name='selectedState' value={this.props.form.selectedState} onChange={this.props.inputChange}>
                    <option value=''>Please Select</option>
                    {context.state.states.map(value => (
                      <option key={value.stateCode} value={value.stateCode}>
                        {value.stateCode}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='zipCode'>
                  <label htmlFor='zipCode'>Zip</label>
                  <input
                    className='form-control'
                    type='text'
                    name='zip'
                    value={this.props.form.zip}
                    onChange={this.checkZip}
                    onKeyDown={this.props.handleKeyDown}
                    maxLength='10'
                    required='required'
                  />
                </div>

                {/* <div className='zipCode'>
            <label htmlFor='zipCode'>Zip Code</label>
            <input className='form-control' type='text' id='zipcode' name='Zip Code' />
          </div> */}
              </div>
              </div>
            </>
          )}
        </GlobalContext.Consumer>
      </div>
     );
  }
}
