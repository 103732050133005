import React, { Component } from "react";
import _ from 'lodash';
import { AuthenticationService } from "../../_services/auth.service";
import { SearchService } from "../../_services/search-client.service";
import { SharedService } from "../../_services/shared.service";
import { Notification } from '../shared/notifications/notifications';
import "../../style.scss";
import "./CustomerHomePage.scss";
// import { Notification } from '../../_components/shared/notifications/notifications';
import { PAGE_TITLES, PAGES_TITLE_KEYS } from "../../_constants/constants";
import { ConfirmBox } from "../shared/modals/confirm-box/ConfirmBox";
import { Helmet } from "react-helmet";

export class CustomerHomePage extends Component {
  isLoggedIn = false;
  authenticationService = new AuthenticationService();
  sharedService = new SharedService();
  searchService = new SearchService();
  loginAttempts = 0;

  state = {
    searchValue: "",
    formSubmit: false,
    placeholder: {
      "policy-number": 'Search by client\'s Policy Number',
      "account-number": 'Search by client\'s Account Number',
      "user-name": 'Search by Username',
      "confirmation-number": 'Search by Payment Confirmation Number'
    },
    title: [],
    searchResult: [],
    searchBy: '',
    pageTitle: "",
    showConfirmBox: false,
    confirmPopup: {
      title: 'Are you sure?',
      content: 'Are you sure you want to delete this user\'s My Account registration?',
      positiveBtnText: 'Delete',
      negativeBtnText: 'Cancel'
    },
    selectedUser: null
  };

  componentDidMount() {

    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: clientName + PAGE_TITLES.HOME })

    this.props.setLoading(false);
    // Customer Support Search Placeholder default value.
    this.setState({
      searchBy: 'policy-number'
    });
  }
  // Search the client information based on search criteria.
  search = () => {
    this.setState({
      formSubmit: true
    });
    this.props.notificationContext.resetMessages();
    if (this.checkValidation()) {
      let companyId = JSON.parse(localStorage.getItem('initialIds')).companyId;
      let params = {
        companyId: companyId,
        search: this.state.searchBy,
        value: this.state.searchValue,
        userId: JSON.parse(localStorage.getItem('ccuser')).userid
      };
      this.props.setLoading(true);
      this.searchService.searchClient(params).subscribe((res) => {
        this.props.setLoading(false);
        if (res.status === 200) {
          this.setState({
            searchResult: []
          });

          if (params.search !== 'account-number' && params.search !== 'user-name') {
            this.prepareResult(res.data);
          } else {
            res.data.hasOwnProperty('items') &&
              res.data.items.forEach((item) => {
                this.prepareResult(item);
              });
          }
        } else {
          this.props.notificationContext.processMessages(res.data.messages);
          this.setState({
            searchResult: []
          });
        }
      });
    }
  };

  getPolicyNumber(paymentDetails) {
    let policy_numbers = [];
    for (let policy in paymentDetails) {
      policy_numbers.push(paymentDetails[policy].paymentDetail.policyNumber);
    }
    return policy_numbers.join(',\n\t');
  };

  getPolicyPaymentMethod(paymentDetails) {
    let payment = [];
    for (let policy in paymentDetails) {
      if (payment.indexOf(paymentDetails[policy].paymentDetail.policyNumber) === -1)
        payment.push(paymentDetails[policy].paymentDetail.policyNumber);
    }
    return payment.join(',\n\t');
  };

  prepareResult = (result) => {
    let hasPaymentDetails = result.paymentDetail ? true : false;
    let paymentMethodType = hasPaymentDetails && result.paymentDetail[0].paymentType === 'CREDITCARD' ? 'creditCard' : 'bankAccount';
    let paymentMethodStr = hasPaymentDetails && result.paymentDetail[0][paymentMethodType].paymentType + ' ' + result.paymentDetail[0][paymentMethodType].accountNumberLast4Digit

    let data = {
      account_number: result.accountNumber,
      policy_number: result.policyNumber === null ? '' : result.policyNumber,
      user_name: result.userid,
      full_name: [result.policyHolder.hasOwnProperty('firstName') ? result.policyHolder.firstName : '', result.policyHolder.hasOwnProperty('lastName') !== '' ? result.policyHolder.lastName : ' '].join(' '),
      client: result.client,
      comments: {
        payment_confirmation_number: [result.paymentConfirmationNumber ? ('Payment Confirmation No: ' + result.paymentConfirmationNumber) : ''].join('\n'),
        payment_amount: [hasPaymentDetails ? ('Payment Amount:' + result.paymentDetail[0].paymentAmount) : ''].join(':\n'),
        payment_date: [result.paymentDate ? ('Payment Date:' + result.paymentDate) : ''].join(':\n'),
        policy_numbers: [hasPaymentDetails ? ('Policy Number(s) Paid:' + result.paymentDetail[0].policyNumber) : ''].join('\n'),
        payment_method: [paymentMethodStr ? ('Payment Methods: ' + paymentMethodStr) : ''].join('\n'),
        billing_day: [result.billingDay ? ('Billing day: ' + result.billingDay) : ''].join('\n')
      },
      actualUserId: result.actualUserid
    };
    let titles = {
      account_number: "Account Number",
      policy_number: 'Policy Number',
      user_name: 'Username',
      full_name: 'Fullname',
      client: 'Client',
      comments: 'Comments',
    };

    let resultSet = this.state.searchResult;
    resultSet.push(data);
    this.setState({
      searchResult: resultSet,
      title: titles
    });
  }
  // Check validation for the search filter.
  checkValidation = () => {
    if (/^$/.test(this.state.searchValue)) {
      this.setState({ isFormValid: false });
      return false;
    }
    this.setState({ isFormValid: true });
    return true;
  };

  checkRequiredFields() {
    if (/^\s*$/.test(this.state.searchValue)) {
      this.setState({ isFormValid: false });
      return false;
    }

    this.setState({ isFormValid: true });
    return true;
  }

  // Change the search filter.
  handleChange = e => {
    // e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.checkRequiredFields();
    });
  };

  handleSearchFilter = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.search();
    }
  };

  impersonateUser(user) {
    let params = {
      companyId: JSON.parse(localStorage.getItem('initialIds')).companyId,
      userId: user.actualUserId
    };
    this.authenticationService.impersonateUser(params).subscribe((res) => {
      if (res.status === 200) {
        let userSession = JSON.parse(localStorage.getItem('ccuser'));
        userSession.userid = user.userId;
        Object.assign(userSession, res.data);
        userSession.csUserid = JSON.parse(localStorage.getItem('ccuser')).userid;
        // window.open('http://' + res.data.siteURL + ':3000/#/logging-in/' + JSON.stringify(userSession));
        window.open('http://' + res.data.siteURL + '/#/logging-in/' + JSON.stringify(userSession));
      }
    })
  }

  resetUserPassword(user) {
    let params = {
      "companyId": JSON.parse(localStorage.getItem('initialIds')).companyId,
      "userName": user.actualUserId,
      "isCustomerUser": true,
      "domainGroupId": JSON.parse(localStorage.getItem('initialIds')).domainGroupId
    }
    this.authenticationService.resetUserPassword(params).subscribe((res) => {
      this.props.notificationContext.processMessages(res.data.messages);
    });
  }

  deleteUser(user) {
    this.setState({
      showConfirmBox: true,
      selectedUser: user
    });
  }

  handleConfirmBoxClose = (confirmation) => {
    this.setState({ showConfirmBox: false });

    if (confirmation) {
      let params = {
        companyId: JSON.parse(localStorage.getItem('initialIds')).companyId,
        userId: this.state.selectedUser.actualUserId
      };
      this.authenticationService.deleteUser(params).subscribe((res) => {
        if (res.status === 200) {
          this.setState({
            searchResult: [],
           // searchValue: ''
          });
        }
        this.props.notificationContext.processMessages(res.data.messages);
      })
    } 
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.HOME] || this.state.pageTitle}</title>
        </Helmet>
        {this.state.showConfirmBox && (
          <ConfirmBox
            show={this.state.showConfirmBox}
            hide={this.handleConfirmBoxClose}
            title={this.state.confirmPopup.title}
            content={this.state.confirmPopup.content}
            positiveBtnText={this.state.confirmPopup.positiveBtnText}
            negativeBtnText={this.state.confirmPopup.negativeBtnText}
            {...this.props}
          />
        )}
        <div className="innerBodyWrapper cunsumerHome">
          <div className='cunsumerHomeInner'>
            <h1 className="mainHeadingT">Search Clients</h1>
            <div className="notification-container">
              {this.props.notificationContext.state.infoNotification.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
              {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
            </div>

            {/* {this.state.infoMessage.type !== "" ? <Notification close={() => {}} message={this.state.infoMessage} /> : ""} */}
            <div className="cunsumerSearchBody">
              <div className="row">
                <div className="col-sm-5 col-xs-12">
                  <select className="form-control" name="searchBy" onChange={this.handleSearchFilter}>
                    {_.map(Object.keys(this.state.placeholder), (value) => <option value={value}>{this.state.placeholder[value]}</option>)}
                  </select>
                </div>
                <div className="col-sm-5 col-xs-12">
                  <input onKeyDown={this.handleKeyDown} className={"form-control " + (!this.state.isFormValid && this.state.formSubmit ? 'invalid-input' : '')} type="text" name="searchValue" noValidate placeholder="Enter value" value={this.state.searchValue} onChange={this.handleChange} />
                </div>
                <div className="col-sm-2 col-xs-12">
                  <button
                    type="button"
                    className="btn agiaBlueButton btn-primary form-control"
                    onClick={this.search}
                    disabled={!this.state.isFormValid}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-xs-12">
                {
                  Object.keys(this.state.searchResult).length > 0 && (
                    <div className="table-wrapper">
                      <table className={"table table-light borderless " + (this.props.globalContext.state.deviceWidth <= 767 ? "card-list-table" : "productTable searchCustomer table-responsive")}>
                        <thead>
                          <tr>
                            {_.map(Object.keys(this.state.title), (value) =>
                              <th className={value}>{this.state.title[value]}</th>
                            )}
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.map(Object.keys(this.state.searchResult), value =>
                            <tr>
                              {_.map(Object.keys(this.state.searchResult[value]), (val) =>
                                this.state.title[val] &&
                                <td data-title={this.state.title[val]}>
                                  {typeof (this.state.searchResult[value][val]) !== 'object' ? this.state.searchResult[value][val] : _.map(Object.keys(this.state.searchResult[value][val]), v => <div>{this.state.searchResult[value][val][v]}</div>)}
                                </td>
                              )}
                              <td className="row-btns">
                                {/* <a href={this.state.searchResult[value].domain} target="_blank" className={"btn btn-primary form-control"}>Impersonate</a> */}
                                <button type="button" className={"btn agiaBlueButton btn-primary form-control"} onClick={() => this.impersonateUser(this.state.searchResult[value])}>Impersonate</button>
                                <button type="button" className={"btn agiaBlueButton btn-primary form-control"} onClick={() => this.resetUserPassword(this.state.searchResult[value])}>Reset Password</button>
                                <button type="button" className={"btn btn-danger form-control"} onClick={() => this.deleteUser(this.state.searchResult[value])}>Delete Account</button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
