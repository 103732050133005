import React from "react";
import { Link } from "react-router-dom";
import { ARTICLES } from '../../../../../_constants/constants';
import { convertStringToAmount } from '../../../../../_services/utility.service';

export class ProductDetailsDesktop extends React.Component {
  productOverview = this.props.productOverview;
  additionalInsured = this.props.additionalInsured;

  render() {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.PRODUCT_DETAILS_BENEFITS] }}></div>
        <h1 className='mainHeadingT desktopView'>
          <Link
            to='#'
            onClick={e => {
              e.preventDefault();
              this.props.history.goBack();
            }}
            className='materialicons'>
            arrow_back
          </Link>
          {this.productOverview.productDesc}
        </h1>{" "}
        <div className='desktopView productsDescTemplate'>
          <div className='twoColLayout'>
            <div className='leftCol'>
              <h2 className='homeHederText'>Product Overview</h2>
              <div className='flexWhiteBox'>
                <div className='leftColumn'>
                  <div className='bodyRow'>
                    <div className='flexBox'>
                      <ul>
                        <li>
                          <span className='label'>{this.productOverview.isInsuranceProduct === 'Y' ? 'CERTIFICATE NUMBER' : 'PLAN NUMBER'}</span>
                          <span className='value'>{this.productOverview.policyNumber}</span>
                        </li>
                        <li>
                          <span className='label'>{this.productOverview.isInsuranceProduct === 'Y' ? 'COVERAGE TYPE' : 'PLAN TYPE'}</span>
                          <span className='value'>{this.productOverview.coverageType}</span>
                        </li>
                        <li>
                          <span className='label'>STATUS</span>
                          <span className='value'>
                            {/* <span className='autoPayButton' value='Auto Pay'> */}
                            {this.productOverview.policyStatus}
                            {/* </span> */}
                          </span>
                        </li>
                        <li>
                          <span className='label'>COVERAGE EFFECTIVE DATE</span>
                          <span className='value'>{this.productOverview.coverageEffectiveDate}</span>
                        </li>
                        {
                          this.productOverview.suppressPaymentDueDate === 'N' &&
                          <li>
                            {/* <span className='label'>{this.productOverview.isInsuranceProduct === 'Y' ? 'PAYMENT DUE DATE' : 'PAID THROUGH'}</span> */}
                            <span className='label'>{this.productOverview.paymentDueDateLabel}</span>
                            <span className='value'>{this.productOverview.paymentDueDate}</span>
                          </li>
                        }
                        {
                          // Fix for #3 in IAD-674
                          convertStringToAmount(this.productOverview.benefitAmount) !== 1 &&
                          <li>
                            <span className='label'>{this.productOverview.isInsuranceProduct === 'Y' ? (this.productOverview.isCancerOrHipOrHap === 'Y' ? 'DAILY HOSPITAL BENEFIT' : 'BASE BENEFIT AMOUNT') : 'MEMBERSHIP FEE'}</span>
                            <span className='value'>{this.productOverview.benefitAmount}</span>
                          </li>
                        }
                        {
                          this.productOverview.isInsuranceProduct === 'N' &&
                          <li>
                            <span className='label'>OWNER</span>
                            <span className='value'>{this.productOverview.owner}</span>
                          </li>
                        }
                        <li>
                          <span className='label'>{this.productOverview.isInsuranceProduct === 'Y' ? 'ANNUAL PREMIUM' : 'ANNUAL PRODUCT COST'}</span>
                          <span className='value'>{this.productOverview.annualPremium}
                            {this.productOverview.isInsuranceProduct === 'Y' && <sup>{this.productOverview.annualPremiumSpecialCharacter}</sup>}
                          </span>
                        </li>
                        <li>
                          <span className='label'>PAYMENT FREQUENCY</span>
                          <span className='value'>{this.productOverview.paymentFrequency}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='leftCol'>
              {this.additionalInsured && this.additionalInsured.length > 0 && (
                <>
                  <h2 className='homeHederText'>Additional Insured</h2>
                  <div className='flexWhiteBox tableForm'>
                    <div className='leftColumn'>
                      <div className='bodyRow'>
                        <div className='flexBox'>
                          <table className='table table-light borderless productTable'>
                            <thead>
                              <tr>
                                <th scope='col'>FULL NAME</th>
                                <th scope='col'>RELATIONSHIP</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.additionalInsured.map((value, index) => (
                                <tr key={index}>
                                  <td>{value.fullName}</td>
                                  <td>{value.relationship}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {this.props.optionalRiders.length > 0 && (
            <>
              <div className='optionalRider'>
                {/* <h2 className='homeHederText'>{this.productOverview.isInsuranceProduct === 'Y' ? 'Optional Riders' : 'Additional Riders'}</h2> */}
                <h2 className='homeHederText'>Additional Riders</h2>
                <table className='table table-light borderless productTable'>
                  <thead>
                    <tr>
                      <th scope='col'>PRODUCT</th>
                      {/* <th scope='col'>COVERAGE TYPE</th> */}
                      <th scope='col'>STATUS</th>
                      <th scope='col'>COVERAGE EFFECTIVE DATE</th>
                      <th scope='col' className='amount'>BENEFIT AMOUNT</th>
                      <th scope='col' className='amount'>ANNUAL PREMIUM</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.optionalRiders.map((value, index) => (
                      <tr key={index}>
                        <td>
                          <span className='nonAnchorText'>{value.productDesc}</span>
                          <span className='smallTableText'>{value.policyNumber}</span>
                        </td>
                        {/* <td>Optional Rider</td> */}
                        <td>{value.policyStatus}</td>
                        <td>{value.coverageEffectiveDate}</td>
                        <td className='amount'>{value.benefitAmount}</td>
                        <td className='amount'>{value.annualPremium}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          <div className='twoColLayout'>
            {this.props.productDocuments.length > 0 && (
              <div className='leftCol'>
                <h2 className='homeHederText'>Product Documents</h2>
                <div className='flexWhiteBox productDocs'>
                  <div className='leftColumn'>
                    <div className='bodyRow'>
                      <div className='flexBox'>
                        <ul>
                          {this.props.productDocuments.map((correspondence, index) =>
                            (<li key={index}>
                              <span className='label'>
                                <span className='smallTableText'>{correspondence.documentCreatedDate}</span>
                                <span className='nonAnchorText'>{correspondence.documentName}</span>
                              </span>
                              <span className='value'>
                                <Link
                                  className={`ma-pd-${correspondence.documentCategory}-${correspondence.documentTypeId}-${this.productOverview.policyNumber}`}
                                  title={correspondence.documentName}
                                  to='/#'
                                  onClick={e => {
                                    e.preventDefault();
                                    this.props.openDocument(correspondence, 'PRODUCT_DOCS')
                                  }}
                                >View Document</Link>
                                {correspondence.showDocError === true && <p dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.DOCUMENT_ERROR] }} className='document-error'></p>}
                              </span>
                            </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.props.correspondences.length > 0 && (
              <div className='leftCol'>
                <h2 className='homeHederText'>Correspondence</h2>
                <div className='flexWhiteBox productDocs'>
                  <div className='leftColumn'>
                    <div className='bodyRow'>
                      <div className='flexBox'>
                        <ul>
                          {this.props.correspondences.map((correspondence, index) =>
                            (<li key={index}>
                              <span className='label'>
                                <span className='smallTableText'>{correspondence.documentCreatedDate}</span>
                                <span className='nonAnchorText'>{correspondence.documentName}</span>
                              </span>
                              <span className='value'>
                                <Link
                                  title={correspondence.documentName}
                                  to='/#'
                                  onClick={e => {
                                    e.preventDefault();
                                    this.props.openDocument(correspondence, 'CORRESPONDENCE_DOCS')
                                  }}
                                >View Document</Link>
                                {correspondence.showDocError === true && <p dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.DOCUMENT_ERROR] }} className='document-error'></p>}
                              </span>
                            </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="footerTextPara" >
          <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.PRODUCT_DETAILS_MISMATCH_DATA] }}></div>
          {this.productOverview.isInsuranceProduct === 'Y' && <p dangerouslySetInnerHTML={{ __html: this.productOverview.message }}></p>}
          <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
        </div>
      </div>
    );
  }
}
