import React, { Component } from "react";
import { DashboardService } from "../../../_services/dashboard.service";
import { SharedService } from "../../../_services/shared.service";
import { ARTICLES } from "../../../_constants/constants";
import { SummaryHorizontal } from "./horizontal/SummaryHorizontal";
import { SummaryVertical } from "./vertical/SummaryVertical";
import { SummaryMobile } from "./mobile/SummaryMobile";
import { Notification } from "../../shared/notifications/notifications";
import { PAGE_TITLES, PAGES_TITLE_KEYS } from "../../../_constants/constants";
import { Helmet } from "react-helmet";

export class Dashboard extends Component {
  user = JSON.parse(localStorage.getItem("user"));
  dashboardService = new DashboardService();
  sharedService = new SharedService();

  state = {
    activeProducts: [],
    dueProducts: [],
    hasEAPlusProduct: false,
    clientName: '',
    showSummary: false,
    globlWebContents: {},
    pageTitle: ""
  };

  componentDidMount() {

    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: client + PAGE_TITLES.HOME })

    // Check for inter-screen message
    setTimeout(() => { // to avoid race condition present in index.jsx file
      if (this.props.notificationContext.state.interScreenNotification && this.props.notificationContext.state.interScreenNotification.length !== 0) {
        this.props.notificationContext.processMessages(this.props.notificationContext.state.interScreenNotification);
        // Clear the message
        this.props.notificationContext.setInterScreenNotification([])
      }
    }, 100)

    this.getDashboardData();
    this.globalWC_getContent([
      ARTICLES.CLAIM_NOW,
      ARTICLES.PRODUCT_DISCLAIMER,
      ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING,
      ARTICLES.PRODUCT_DISCLAIMER_NOCOST,
      ARTICLES.SITE_DISCLAIMER,
      ARTICLES.AUTOPAY_MARKETTING,
      ARTICLES.TRANSACTION_DISCLAIMER,
      ARTICLES.EA_PLUS_MEMBER_NOTE
    ]);

  }

  getDashboardData = () => {
    let { client } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      client: client,
      accountNumber: this.user.accountNumber,
      policyNumber: this.user.policyNumber,
      policyHolder: {
        firstName: this.user.firstName,
        lastName: this.user.lastName
      },
      userid: this.user.userid
    };

    this.props.setLoading(true);
    this.dashboardService.getDashboardData(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {

        this.setState({
          activeProducts: response.data.activeProducts || [],
          dueProducts: response.data.paymentsDue || [],
          hasEAPlusProduct: response.data.hasEAPlusProduct,
          clientName: response.data.clientName,
          showSummary: true,
          // message: response.data.hasOwnProperty("messages") ? response.data.messages : []
        });
      } else {
        this.setState({
          activeProducts: [],
          dueProducts: []
        });
      }
      this.props.notificationContext.processMessages(response.data.messages);
    });
  };

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items.forEach(element => {
          webContents[element.title] = element.content;
        });
        this.setState({
          globlWebContents: webContents
        });
        // document.getElementById("globalWC_headerPhoneInfo").innerHTML = response.data.content;
      }
    });
  };

  getDisclaimers = () => {
    let disclaimerTypesMap = [
      { name: 'autopay', content: this.state.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER] },
      { name: 'combinedbill', content: this.state.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING] },
      { name: 'nocosttoyou', content: this.state.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_NOCOST] },
    ]
    let components = [];
    let iterated = [];
    this.state.activeProducts.forEach((product, index) => {
      let disclaimerObj = disclaimerTypesMap.find((item) => item.name === product.paymentStatusDisplayStyle);
      let hasAlreadyIterated = !iterated.includes(product.paymentStatusDisplayStyle);

      if (disclaimerObj && hasAlreadyIterated) {
        components.push(<div key={index} dangerouslySetInnerHTML={{ __html: disclaimerObj.content }} ></div>)
        iterated.push(product.paymentStatusDisplayStyle);
      }
    });

    return components;
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.HOME] || this.state.pageTitle}</title>
        </Helmet>
        <div className='innerBodyWrapper homePage'>
          <div className='blueBackground'></div>
        
          {this.props.notificationContext.state.infoNotification.length > 0 &&(
            <div className='notification-dashboard'>
              {/* <h1>55555 => {this.props.globalContext.state.states.toString()}</h1> */}
              <div className='col-12'>
                {this.props.notificationContext.state.infoNotification
                  ? this.props.notificationContext.state.infoNotification.map((notification, index) => <Notification key={index} id={index} message={notification} />)
                  : ""}
              </div>
            </div>
          )}

          <div className='col-md-12 text-left p-0'>
            <h1 className='welcomeText'>
              <span className='welcome'> Welcome </span>
              <span className='nameColor'>
                {this.user.firstName} {this.user.lastName}
              </span>
            </h1>

            {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
            {this.state.showSummary && (
              <div>
                {this.props.globalContext.state.deviceWidth >= 768 && (
                  <div>
                    {this.state.dueProducts.length <= 1 && (
                      <SummaryHorizontal
                        {...this.props}
                        clientName={this.state.clientName}
                        hasEAPlusProduct={this.state.hasEAPlusProduct}
                        dueProducts={this.state.dueProducts}
                        activeProducts={this.state.activeProducts}
                        globlWebContents={this.state.globlWebContents}
                      />
                    )}
                    {this.state.dueProducts.length > 1 && (
                      <SummaryVertical
                        {...this.props}
                        clientName={this.state.clientName}
                        hasEAPlusProduct={this.state.hasEAPlusProduct}
                        dueProducts={this.state.dueProducts}
                        activeProducts={this.state.activeProducts}
                        globlWebContents={this.state.globlWebContents}
                      />
                    )}
                    <div className='footerTextPara'>
                      {this.getDisclaimers()}
                      <div dangerouslySetInnerHTML={{ __html: this.state.globlWebContents[ARTICLES.TRANSACTION_DISCLAIMER] }}></div>
                    </div>
                  </div>
                )}
                {this.props.globalContext.state.deviceWidth <= 767 && (
                  <SummaryMobile
                    {...this.props}
                    clientName={this.state.clientName}
                    hasEAPlusProduct={this.state.hasEAPlusProduct}
                    dueProducts={this.state.dueProducts}
                    activeProducts={this.state.activeProducts}
                    globlWebContents={this.state.globlWebContents}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}