import React, { useState } from "react";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { ARTICLES } from '../../../../../_constants/constants';
import { convertStringToAmount } from '../../../../../_services/utility.service';

export const ProductDetailsMobile = props => {
  const BATCH_SIZE_OPTIONAL_RIDER = 5;
  let optionalRiderTmp = props.optionalRiders.slice();

  const [open, setOpen] = useState(false);
  const [showMoreOptionalCounter, setShowMoreOptionalCounter] = useState(BATCH_SIZE_OPTIONAL_RIDER);
  const [optionalRiders, setOptionalRiders] = useState(optionalRiderTmp.splice(0, showMoreOptionalCounter));

  const toggleOpen = id => {
    setOpen(value => ({
      ...value,
      [id]: !value[id]
    }));
  };

  const showMoreOptionalRider = () => {
    let newCounter = showMoreOptionalCounter + BATCH_SIZE_OPTIONAL_RIDER;
    let optionalRiderTmp = props.optionalRiders.slice();
    setOptionalRiders(optionalRiderTmp.splice(0, newCounter));
    setShowMoreOptionalCounter(newCounter);
  };

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: props.globlWebContents[ARTICLES.PRODUCT_DETAILS_BENEFITS] }}></div>
      <h1 className='mainHeadingT mobileView'>
        <Link
          to='#'
          onClick={e => {
            e.preventDefault();
            props.history.goBack();
          }}
          className='materialicons'>
          chevron_left
        </Link>
        {props.productOverview.productDesc}
      </h1>{" "}
      <div className='mobileView productsDescTemplate'>
        <div className='singleCol'>
          <div className='leftCol'>
            <h2 className='homeHederText'>Product Overview</h2>
            <div className='flexWhiteBox'>
              <div className='leftColumn'>
                <div className='bodyRow'>
                  <div className='flexBox'>
                    <ul>
                      <li>
                        <span className='label'>{props.productOverview.isInsuranceProduct === 'Y' ? 'CERTIFICATE NUMBER' : 'PLAN NUMBER'}</span>
                        <span className='value'>{props.productOverview.policyNumber}</span>
                      </li>
                      <li>
                        <span className='label'>{props.productOverview.isInsuranceProduct === 'Y' ? 'COVERAGE TYPE' : 'PLAN TYPE'}</span>
                        <span className='value'>{props.productOverview.coverageType}</span>
                      </li>
                      <li>
                        <span className='label'>STATUS</span>
                        <span className='value'>
                          {/* <span className='autoPayButton' value='Auto Pay'> */}
                          {props.productOverview.policyStatus}
                          {/* </span> */}
                        </span>
                      </li>
                      <li>
                        <span className='label'>COVERAGE EFFECTIVE DATE</span>
                        <span className='value'>{props.productOverview.coverageEffectiveDate}</span>
                      </li>
                      {
                        props.productOverview.suppressPaymentDueDate === 'N' &&
                        <li>
                          {/* <span className='label'>{props.productOverview.isInsuranceProduct === 'Y' ? 'PAYMENT DUE DATE' : 'PAID THROUGH'}</span> */}
                          <span className='label'>{props.productOverview.paymentDueDateLabel}</span>
                          <span className='value'>{props.productOverview.paymentDueDate}</span>
                        </li>
                      }

                      {
                        convertStringToAmount(props.productOverview.benefitAmount) !== 1 &&
                        <li>
                          <span className='label'>{props.productOverview.isInsuranceProduct === 'Y' ? (props.productOverview.isCancerOrHipOrHap === 'Y' ? 'DAILY HOSPITAL BENEFIT' : 'BASE BENEFIT AMOUNT') : 'MEMBERSHIP FEE'}</span>
                          <span className='value'>{props.productOverview.benefitAmount}</span>
                        </li>
                      }
                      {
                        props.productOverview.isInsuranceProduct === 'N' &&
                        <li>
                          <span className='label'>OWNER</span>
                          <span className='value'>{props.productOverview.owner}</span>
                        </li>
                      }

                      <li>
                        <span className='label'>{props.productOverview.isInsuranceProduct === 'Y' ? 'ANNUAL PREMIUM' : 'ANNUAL PRODUCT COST'}</span>
                        <span className='value'>{props.productOverview.annualPremium}
                          {props.productOverview.isInsuranceProduct === 'Y' && <sup>{props.productOverview.annualPremiumSpecialCharacter}</sup>}
                        </span>
                      </li>
                      <li>
                        <span className='label'>PAYMENT FREQUENCY</span>
                        <span className='value'>{props.productOverview.paymentFrequency}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.additionalInsured && props.additionalInsured.length > 0 && (
            <div className='leftCol'>
              <h2 className='homeHederText'>Additional Insured</h2>
              <div className='flexWhiteBox tableForm'>
                <div className='leftColumn'>
                  <div className='bodyRow'>
                    <div className='flexBox'>
                      <table className='table table-light borderless productTable'>
                        <thead>
                          <tr>
                            <th scope='col'>FULL NAME</th>
                            <th scope='col'>RELATIONSHIP</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.additionalInsured.map((value, index) => (
                            <tr key={index}>
                              <td>{value.fullName}</td>
                              <td>{value.relationship}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {props.optionalRiders.length > 0 && (
          <div className='optionalRider singleCol'>
            {/* <h2 className='homeHederText'>{props.productOverview.isInsuranceProduct === 'Y' ? 'Optional Riders' : 'Additional Riders'}</h2> */}
            <h2 className='homeHederText'>Additional Riders</h2>
            <div className='flexWhiteBox productDocs'>
              <div className='leftColumn'>
                <div className='bodyRow'>
                  <div className='flexBox'>
                    <ul>
                      {optionalRiders.map((value, index) => (
                        <div key={index}>
                          <li
                            onClick={() => toggleOpen(index)}
                            aria-controls='collapse-text'
                            aria-expanded={open[index] === undefined ? false : open[index]}
                            className='collapse-text'>
                            <span className='label'>{value.productDesc}</span>
                            <span className='value'>
                              <span className='priceValue'>{value.annualPremium}</span>
                              <span className='materialicons leftArrow'>chevron_left</span>
                              <span className='materialicons downArrow'>expand_more</span>
                            </span>
                          </li>
                          <Collapse in={open[index]}>
                            <div id='collapseText' className='collapseText'>
                              <div className='bodyRow'>
                                <div className='flexBox'>
                                  <ul>
                                    <li>
                                      <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                                      <span className='value'>{value.policyNumber}</span>
                                    </li>
                                    {/* <li>
                                      <span className='label'>COVERAGE TYPE</span>
                                      <span className='value'>{value.coverageType}</span>
                                    </li> */}
                                    <li>
                                      <span className='label'>STATUS</span>
                                      <span className='value'>
                                        {/* <span className='autoPayButton' value='Auto Pay'> */}
                                          {value.policyStatus}
                                        {/* </span> */}
                                      </span>
                                    </li>
                                    <li>
                                      <span className='label'>BENEFIT AMOUNT</span>
                                      <span className='value'>{value.benefitAmount}</span>
                                    </li>
                                    <li>
                                      <span className='label'>RIDER PREMIUM</span>
                                      <span className='value'>{value.annualPremium}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      ))}
                      {showMoreOptionalCounter < props.optionalRiders.length + 1 - BATCH_SIZE_OPTIONAL_RIDER && (
                        <li
                          onClick={() => {
                            showMoreOptionalRider();
                          }}
                          className='footPagination'>
                          Show {BATCH_SIZE_OPTIONAL_RIDER} more
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='singleCol'>
          {props.productDocuments.length > 0 && (
            <div className='leftCol'>
              <h2 className='homeHederText'>Product Documents</h2>
              <div className='flexWhiteBox productDocs'>
                <div className='leftColumn'>
                  <div className='bodyRow'>
                    <div className='flexBox'>
                      <ul>
                        {props.productDocuments.map((correspondence, index) =>
                          (<li key={index}>
                            <span className='label'>
                              <span className='smallTableText'>{correspondence.documentCreatedDate}</span>
                              <span className='nonAnchorText'>{correspondence.documentName}</span>
                            </span>
                            <span className='value'>
                              <Link
                                className={`ma-pd-${correspondence.documentCategory}-${correspondence.documentTypeId}-${props.productOverview.policyNumber}`}
                                title={correspondence.documentName}
                                to='/#'
                                onClick={e => {
                                  e.preventDefault();
                                  props.openDocument(correspondence, 'PRODUCT_DOCS')
                                }}
                              >View Document</Link>
                              {correspondence.showDocError === true && <p dangerouslySetInnerHTML={{ __html: props.globlWebContents[ARTICLES.DOCUMENT_ERROR] }} className='document-error'></p>}
                            </span>
                          </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {props.correspondences.length > 0 && (
            <div className='leftCol'>
              <h2 className='homeHederText'>Correspondence</h2>
              <div className='flexWhiteBox productDocs'>
                <div className='leftColumn'>
                  <div className='bodyRow'>
                    <div className='flexBox'>
                      <ul>
                        {props.correspondences.map((correspondence, index) =>
                          (<li key={index}>
                            <span className='label'>
                              <span className='smallTableText'>{correspondence.documentCreatedDate}</span>
                              <span className='nonAnchorText'>{correspondence.documentName}</span>
                            </span>
                            <span className='value'>
                              <Link
                                title={correspondence.documentName}
                                to='/#'
                                onClick={e => {
                                  e.preventDefault();
                                  props.openDocument(correspondence, 'CORRESPONDENCE_DOCS')
                                }}
                              >View Document</Link>
                              {correspondence.showDocError === true && <p dangerouslySetInnerHTML={{ __html: props.globlWebContents[ARTICLES.DOCUMENT_ERROR] }} className='document-error'></p>}
                            </span>
                          </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footerTextPara" >
        <div dangerouslySetInnerHTML={{ __html: props.globlWebContents[ARTICLES.PRODUCT_DETAILS_MISMATCH_DATA] }}></div>
        {props.productOverview.isInsuranceProduct === 'Y' && <p dangerouslySetInnerHTML={{ __html: props.productOverview.message }}></p>}
        <div dangerouslySetInnerHTML={{ __html: props.globlWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
      </div>
    </div>
  );
};
