import React from "react";
import { LOCALIZATION } from "../../_constants/localization";

export class ForgotUserConfirmation extends React.Component {
  componentDidMount() {
    document.getElementById("globalWC_emailConfirmContent").innerHTML = this.props.location.content;
  }

  render() {
    return (
      <div className="cnfMessage usernameWrapper">
        <div className="lusernameColumn">
          <h1 className="mainHeadingT">{LOCALIZATION.EN.EMAIL_SENT_CONFIRMATION}</h1>
          <div className="backgroundSetColor">
            <div className='col-lg-12 col-md-12 col-12 p-3'>
          <div id="globalWC_emailConfirmContent"></div>
          </div>
          </div>
          <div className="roundBottomButton"> 
            <button
              className="btn agiaBlueButton mt-2 mr-3 mb-5"
              value="Back to login"
                onClick={() => { this.props.history.replace('/') }}
            >
              Back to login
            </button>
          </div>
        </div>
      </div>
    );
  }
}
