import React from "react";
import { NavLink } from "react-router-dom";
import { PaymentCenterRoutesComp } from "../../../_routes/app-routes";
import { Notification } from "../../shared/notifications/notifications";
import { PAGE_TITLES, PAGES_TITLE_KEYS } from "../../../_constants/constants";
import { Helmet } from "react-helmet";

export class PaymentCenter extends React.Component {
  hideTabsForRoutes = ["/payment-center/pay-now"];

  routeChangeSubscription;

  state = {
    currentPath: this.props.location.pathname,
    message: [],
    isCustomer: JSON.parse(localStorage.getItem('user')).hasOwnProperty('csUserid') ? true : false,
    pageTitle: ""
  };

  componentDidMount() {

    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: clientName + PAGE_TITLES.PAYMENT_CENTER })

    this.props.location.pathname === "/payment-center" && this.props.history.push("/payment-center/products");

    this.routeChangeSubscription = this.props.history.listen(location => {
      if (location.pathname === "/payment-center") {
        this.props.history.push("/payment-center/products");
      } else {
        this.setState({ currentPath: location.pathname });
      }
    });

  }

  componentWillUnmount() {
    this.routeChangeSubscription();
  }

  closeNotification = index => {
    let notifications = Object.assign([], this.state.message);
    if (notifications.length > 0) notifications.splice(index, 1);
    this.setState({
      message: notifications
    });
  };

  render() {
    // let paymentMethodsRef = PaymentCenterRoutes.routes[PaymentCenterRoutes.routes.findIndex(item => item.path === "/payment-center/payment-methods")];
    let showTabs = this.hideTabsForRoutes.findIndex(item => item === this.state.currentPath) === -1;
    return (
      <>
      <Helmet>
        <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.PAYMENT_CENTER] || this.state.pageTitle}</title>
      </Helmet>
      <div className='innerBodyWrapper'>
        {this.props.notificationContext.state.infoNotification
          ? this.props.notificationContext.state.infoNotification.map((notification, index) => <Notification key={index} id={index} message={notification} />)
          : ""}
        <div className='paymentCenterContent'>
          {showTabs && (
            <>
              <h1 className='mainHeadingT'>Payment Center</h1>
              <div className='col-md-12 float-left p-0'>
                <div className='innerNavHeader col-lg-8 col-md-8 col-xs-12 col-12 float-left p-0'>
                  <div className='navbarText'>
                    <nav className='navbar navbar-expand-lg'>
                      <ul className='navbar-nav'>
                        <li className='nav-item'>
                          <NavLink className='nav-link payment-center-header' activeClassName='active' to='/payment-center/products'>
                            <label className='navText'>Products</label>
                          </NavLink>
                        </li>
                        <li className='nav-item '>
                          <NavLink className='nav-link payment-center-header' activeClassName='active' to='/payment-center/payment-methods'>
                            <label className='navText'>Payment Methods</label>
                          </NavLink>
                        </li>
                        {/* {this.state.isCustomer ?
                          (
                            <li className='nav-item '>
                              <NavLink className='nav-link payment-center-header' activeClassName='active' to='/payment-center/payment-history'>
                                <label className='navText'>Payment History</label>
                              </NavLink>
                            </li>
                          ) : ''
                        } */}

                      </ul>
                    </nav>
                  </div>
                </div>
                {/* {this.state.currentPath === "/payment-center/payment-methods" && (
                  <div className='innerNavbutton col-lg-4 col-md-4 col-xs-12 col-12 float-right text-right p-0'>
                    <Link
                      title={""}
                      to='/#'
                      onClick={e => {
                        e.preventDefault();
                        paymentMethodsRef.ref.current.addNewPaymentMethod();
                      }}>
                      <span className='materialicons'>add</span>
                      <span className='navText'>Add Payment Method</span>
                    </Link>
                  </div>
                )} */}
              </div>
            </>
          )}

          <PaymentCenterRoutesComp {...this.props} />
        </div>
      </div>
      </>
    );
  }
}
