import React from "react";
import { SharedService } from '../../_services/shared.service';
import { Notification } from '../shared/notifications/notifications';
import { PAGE_TITLES, PAGES_TITLE_KEYS } from "../../_constants/constants";
import { Helmet } from "react-helmet";

export class Unsubscribe extends React.Component {
    sharedService = new SharedService();

    state = {
        message: '',
        pageTitle: ""
    }

    componentDidMount() {

        let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
        this.setState({ pageTitle: clientName + PAGE_TITLES.UNSUBSCRIBE_EMAILS })

        this.handleUnsubscribe();
        if (this.props.location.pathname.indexOf('/unsubscribe/') !== -1) {
            this.props.globalContext.setShowNavigationBar(false);
        }
    }

    componentWillUnmount() {
        this.props.globalContext.setShowNavigationBar(true);
    }

    handleUnsubscribe = () => {
        let userId = this.props.match.params.id
        this.props.setLoading(true);
        this.sharedService.unsubscribe(userId).subscribe(response => {
            this.props.setLoading(false);
            this.props.notificationContext.processMessages(response.data.messages);
        });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.UNSUBSCRIBE_EMAILS] || this.state.pageTitle}</title>
                </Helmet>
                <div className="innerBodyWrapper">
                    {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
                    <h1 className="mainHeadingT">{this.state.message}</h1>
                </div>
            </>
        )
    }
}