import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AuthenticationService } from "../../_services/auth.service";
import { LOCALIZATION } from "../../_constants/localization";
import { listenersService } from "../../_services/listeners.service";
import { Notification } from '../shared/notifications/notifications';
import { ARTICLES } from '../../_constants/constants';
import { SharedService } from "../../_services/shared.service";
import { StaticContentModal } from '../shared/modals/static-content/StaticContentModal';
import { Helmet } from "react-helmet";
import { PAGE_TITLES, PAGES_TITLE_KEYS } from '../../_constants/constants';
const publicIp = require('public-ip');

export class SignInCreds extends Component {
  authenticationService = new AuthenticationService();
  sharedService = new SharedService();
  state = {
    planNumber: "",
    userName: "",
    password: "",
    confirmPassword: "",
    selectedQuestion: "",
    selectedAnswer: "",
    iAgree: false,
    hideNewPassword: true,
    hideConfirmPassword: true,

    validations: {
      greaterThan8: false,
      hasLowerCase: false,
      hasUpperCase: false,
      hasNumber: false,
      newConfirmMatch: false
    },

    isVerified: false,
    showPopup: false,
    isFormValid: false,
    setOpenPanel: false,
    staticContent: {
      title: '',
      content: ''
    },
    globalContent: {
      ARTICLE: {
        TERMS_OF_SERVICE: {},
        PRIVACY_POLICY: {}
      }
    },
    pageTitle: ""
  };

  constructor(props, context) {
    super(props, context);
    this.questions = this.props.location.data ? this.props.location.data.questions : [];
  }

  componentDidMount() {
    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: clientName + PAGE_TITLES.REGISTRATION_LOGIN })

    if (!this.props.location.data) {
      this.props.history.push("account-details");
      return;
    }
    this.getContents();
  }

  passwordValidation = () => {
    return new Promise((res, rej) => {
      this.setState(
        {
          validations: {
            greaterThan8: this.state.password.length >= 8,
            hasLowerCase: this.state.password.match(/^(.*[a-z].*)$/),
            hasUpperCase: this.state.password.match(/^(.*[A-Z].*)$/),
            hasNumber: !this.state.password.match(/^([^0-9]*)$/),
            newConfirmMatch: this.state.newPassword !== "" && this.state.confirmPassword !== "" && this.state.password === this.state.confirmPassword
          }
        },
        () => {
          if (
            this.state.validations.greaterThan8 &&
            this.state.validations.hasLowerCase &&
            this.state.validations.hasUpperCase &&
            this.state.validations.hasNumber &&
            this.state.validations.newConfirmMatch
          ) {
            this.setState({ isFormValid: true });
            res(true);
          } else {
            this.setState({ isFormValid: false });
            res(false);
          }
        }
      );
    });
  };

  checkRequiredFields = () => {
    if (/^\s*$/.test(this.state.userName)) {
      this.setState({ isFormValid: false });
      return false;
    }

    if (/^\s*$/.test(this.state.password)) {
      this.setState({ isFormValid: false });
      return false;
    }

    if (/^\s*$/.test(this.state.confirmPassword)) {
      this.setState({ isFormValid: false });
      return false;
    }

    if (/^\s*$/.test(this.state.selectedQuestion)) {
      this.setState({ isFormValid: false });
      return false;
    }

    if (/^\s*$/.test(this.state.selectedAnswer)) {
      this.setState({ isFormValid: false });
      return false;
    }

    if (!this.state.iAgree) {
      this.setState({ isFormValid: false });
      return false;
    }

    this.setState({ isFormValid: true });
    return true;
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, async () => {
      let isValid = await this.passwordValidation();
      isValid && this.checkRequiredFields();
    });
  };

  handleRememberChkBox = () => {
    this.setState({ iAgree: !this.state.iAgree }, async () => {
      let isValid = await this.passwordValidation();
      isValid && this.checkRequiredFields();
    });
  };

  handleCreateAccount = async () => {
    let isPasswordValid = await this.passwordValidation();
    let isRequiredValid = this.checkRequiredFields();
    if (isPasswordValid && isRequiredValid && this.state.iAgree) {
      let { companyId, isSubdomain, domainGroupId, client } = JSON.parse(localStorage.getItem("initialIds"));
      let clientName = localStorage.getItem("clientName");
      let hostName = document.location.hostname;

      let params = {
        hostName: hostName,
        planNumber: this.props.location.data.personalDetails.planNumber,
        accountNumber: this.props.location.data.accountNumber,
        firstName: this.props.location.data.personalDetails.firstName,
        lastName: this.props.location.data.personalDetails.lastName,
        email: this.props.location.data.personalDetails.email,
        phoneNumber: this.props.location.data.personalDetails.phoneNumber,
        zipcode: this.props.location.data.personalDetails.zipcode,
        userName: this.state.userName,
        password: this.state.password,
        securityQuestion: this.state.selectedQuestion,
        securityAnswer: this.state.selectedAnswer,
        groupId: domainGroupId,
        companyId: companyId,
        client: client,
        clientName: clientName,
        personNumber: this.props.location.data.personNumber,
      };

      this.props.setLoading(true);
      this.authenticationService.verifySignInCreds(params).subscribe(response => {
        if (response.status === 200) {
          let params = {
            username: this.state.userName,
            password: this.state.password,
            companyId: companyId,
            domainGroupId: domainGroupId,
            isSubdomain: isSubdomain,
            client: client,
            isCustomerUser: false
          };

          this.authenticationService.login(params).subscribe(response => {
            this.props.setLoading(false);
            if (response.status === 200) {
              localStorage.setItem("user", JSON.stringify(response.data));
              (async () => {
                let ip = await publicIp.v4();
                let params = {
                  "username": this.state.userName,
                  "companyId": companyId,
                  "domainGroupId": domainGroupId,
                  "isCustomerUser": false,
                  "client": client,
                  clientLoginIp: ip
                };
                this.authenticationService.updateLoginInformations(params).subscribe(_response => {
                  if (_response.status !== 403) {
                    localStorage.setItem("hideWelcomeTxt", true);
                    listenersService.triggreAuthStateChanged(JSON.stringify(response.data));
                    this.props.history.push("dashboard");
                  } else {
                    this.props.setLoading(false);
                  }
                })
              })();
            } else {
              this.props.notificationContext.processMessages(response.data.messages);
            }
          });
        } else {
          this.props.setLoading(false);
          this.props.notificationContext.processMessages(response.data.messages);
        }
      });
    }
  };

  getContents() {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: [ARTICLES.TERMS_OF_SERVICE, ARTICLES.PRIVACY_POLICY]
    };
    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items && response.data.items.forEach(element => {
          element.title = element.title.toUpperCase().replace(/-/ig, '_');
          webContents[element.title] = element.content;
        });
        this.setState({
          globalContent: {
            ARTICLES: webContents
          }
        });
      }
    });
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleCreateAccount();
    }
  };

  handleStaticCotentClose = () => {
    this.setState({
      showPopup: false
    });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.REGISTRATION_LOGIN] || this.state.pageTitle}</title>
        </Helmet>
        {this.state.showPopup && (
          <StaticContentModal
            show={this.state.showPopup}
            hide={this.handleStaticCotentClose}
            title={this.state.staticContent.title}
            content={this.state.staticContent.content}
            {...this.props}
          />
        )}
        <div className='registerWrapper'>
          <div className='registerHeader'>
            <h1 className='mainHeadingT'>{LOCALIZATION.EN.REGISTRATION_ALL_STEPS}</h1>
            {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
            {/* {this.state.errorMessage !== "" ? <div className='warningAlert p-2 mt-3'>{this.state.errorMessage}</div> : null} */}
          </div>
          <div className='nav nav-pills'>
            <div className="innerBorder"><img alt={""} src={require("../../_assets/images/navBorder.svg").default}></img></div>
            <div className='nav-item visited'>
              <div className='navbar-link'>
                <span className="numberText">1</span>
                <img alt={""} src={require("../../_assets/images/visitedCircle.svg").default}></img>
                <br />
                <span className="menuText">Identification Information</span>
              </div>
            </div>
            <div className='nav-item visited'>
              <div className='navbar-link'>
                <span className="numberText">2</span>
                <img alt={""} src={require("../../_assets/images/visitedCircle.svg").default}></img>
                <br />
                <span className="menuText">Personal Information</span>
              </div>
            </div>
            <div className='nav-item active'>
              <div className='navbar-link'>
                <span className="numberText">3</span>
                <img alt={""} src={require("../../_assets/images/activeCircle.svg").default}></img>
                <br />
                <span className="menuText">Login Credentials</span>
              </div>
            </div>
          </div>

          <div className='usernameWrapper'>
            <div className='lusernameColumn mobileButton loginCred'>
              {/* <h3>{LOCALIZATION.EN.SIGN_IN_CREDS}</h3>
              <p>Enter a username and password for your account.</p> */}
              <div className="mt-3 mb-3 mobileView" ><strong>Step 3: </strong> Login Credentials</div>
              <div className="backgroundSetColor">
                <div className="columnLayout">
                  <div className="leftCol">
                    <div className='form-group'>
                      <div className='username mt-3'>
                        <div htmlFor='username'> Username</div>
                        <input
                          onKeyDown={this.handleKeyDown}
                          className='form-control'
                          type='text'
                          id='Username'
                          onChange={this.handleChange}
                          placeholder='Username'
                          name='userName'
                          required
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='password mt-3'>
                        <div htmlFor='password'>Password</div>
                        <input
                          onKeyDown={this.handleKeyDown}
                          className='form-control password'
                          type={this.state.hideNewPassword ? "password" : "text"}
                          onChange={this.handleChange}
                          id='password'
                          placeholder='Password'
                          name='password'
                          required
                        />
                        <label
                          onClick={() => {
                            this.setState({
                              hideNewPassword: !this.state.hideNewPassword
                            });
                          }}
                          className='showHide'>
                          <i className={"far " + (this.state.hideNewPassword ? "fa-eye" : "fa-eye-slash")}></i>
                        </label>
                      </div>
                    </div>
                    <div className='password-validation-ctnr mobileView'>
                      <span>Password must have: </span> <br></br>
                      <span className={this.state.validations.greaterThan8 ? "valid" : "invalid"}>at least 8 characters in length </span> <br></br>
                      <span className={this.state.validations.hasLowerCase ? "valid" : "invalid"}>at least 1 lowercase letter (a-z) </span> <br></br>
                      <span className={this.state.validations.hasUpperCase ? "valid" : "invalid"}>at least 1 uppercase letter (A-Z) </span> <br></br>
                      <span className={this.state.validations.hasNumber ? "valid" : "invalid"}>at least 1 number (i.e. 0-9) </span> <br></br>
                      <span className={this.state.validations.newConfirmMatch ? "valid" : "invalid"}>New and Confirm password must match </span> <br></br>
                    </div>
                    <div className='form-group'>
                      <div className='confirmpassword'>
                        <div htmlFor='confirmpassword'>Confirm Password</div>
                        <input
                          onKeyDown={this.handleKeyDown}
                          className='form-control password'
                          onChange={this.handleChange}
                          type={this.state.hideConfirmPassword ? "password" : "text"}
                          id='confirmpassword'
                          placeholder='Confirm Password'
                          name='confirmPassword'
                          required
                        />
                        <label
                          onClick={() => {
                            this.setState({
                              hideConfirmPassword: !this.state.hideConfirmPassword
                            });
                          }}
                          className='showHide'>
                          <i className={"far " + (this.state.hideConfirmPassword ? "fa-eye" : "fa-eye-slash")}></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="leftCol desktopView">
                    <div className='password-validation-ctnr'>
                      <span>Password must have: </span> <br></br>
                      <span className={this.state.validations.greaterThan8 ? "valid" : "invalid"}>at least 8 characters in length </span> <br></br>
                      <span className={this.state.validations.hasLowerCase ? "valid" : "invalid"}>at least 1 lowercase letter (a-z) </span> <br></br>
                      <span className={this.state.validations.hasUpperCase ? "valid" : "invalid"}>at least 1 uppercase letter (A-Z) </span> <br></br>
                      <span className={this.state.validations.hasNumber ? "valid" : "invalid"}>at least 1 number (i.e. 0-9) </span> <br></br>
                      <span className={this.state.validations.newConfirmMatch ? "valid" : "invalid"}>New and Confirm password must match </span> <br></br>
                    </div>
                  </div>
                </div>
              </div>
              <div className="backgroundSetColor">
                <div className='formLayout'>
                  <h2 className="headingText">Security Question and Answer</h2>
                  <div className='securityQuestion'>

                    <div>Select a security question</div>
                    <select value={this.state.selectedQuestion} name='selectedQuestion' onChange={this.handleChange} className='questionAnswer'>
                      <option value=''>Please Select</option>
                      {this.questions.map(value => (
                        <option key={value.questionId} value={value.question}>
                          {value.question}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='form-group'>
                    <div className='answer'>
                      <div htmlFor='answer'>Answer</div>
                      <input
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        type='text'
                        id='answer'
                        onChange={this.handleChange}
                        // placeholder="Enter username"
                        name='selectedAnswer'
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='privacyPolicy'>
                <input onKeyDown={this.handleKeyDown} type='checkbox' name='iAgree' checked={this.state.iAgree} onChange={this.handleRememberChkBox} />
                <span className="material-icons blankCheckbox">check_box_outline_blank</span>
                <span className="material-icons selectedCheckbox">check_box</span>
                <span>
                  I agree to the AGIA&nbsp;
                  <Link
                    title={"Terms and Conditions"}
                    to='#'
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        staticContent: {
                          title: 'Terms and Conditions',
                          content: this.state.globalContent['ARTICLES']['TERMS_OF_SERVICE']
                        }
                      });
                      this.setState({ showPopup: true })
                    }}>
                    Terms of Service
                                </Link>
                  &nbsp;and&nbsp;
                  <Link
                    title={"Privacy Policy"}
                    to='#'
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        staticContent: {
                          title: 'Privacy Policy',
                          content: this.state.globalContent['ARTICLES']['PRIVACY_POLICY']
                        }
                      });
                      this.setState({ showPopup: true })
                    }}>
                    Privacy Policy
                                    </Link>
                </span>
              </div>
              <div className="roundBottomButton">
                <button
                  id="ma-ra-step3"
                  disabled={!(this.state.isFormValid && this.state.iAgree)}
                  className={"btn btn-primary agiaBlueButton mt-3 "}
                  onClick={this.handleCreateAccount}
                  value='Create Account'>
                  Finish registering my account
              </button>
                <Link
                  id="ma-ra-step3Login"
                  to={{
                    pathname: `/personal-info`,
                    state: this.props.location.data || ''
                  }} >Back</Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
