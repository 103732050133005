import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AuthenticationService } from "../../_services/auth.service";
import { SharedService } from "../../_services/shared.service";
import { ARTICLES } from "../../_constants/constants";
import { LOCALIZATION } from "../../_constants/localization";
import { listenersService } from "../../_services/listeners.service";
import "./Login.scss";
import { NotificationContext } from '../../_context/notifications.context';
import { Notification } from '../../_components/shared/notifications/notifications';
import { getCookie } from "../../_services/cookie.service";
import { Helmet } from "react-helmet";
import { PAGE_TITLES, PAGES_TITLE_KEYS } from '../../_constants/constants';
const publicIp = require('public-ip');

export class Login extends Component {
  isLoggedIn = false;
  authenticationService = new AuthenticationService();
  sharedService = new SharedService();
  loginAttempts = 0;

  state = {
    username: "",
    password: "",
    isFormValid: false,
    hidePassword: true,
    rememberMe: false,
    isCustomerUser: false,
    enableActions: false,
    hideWelcomeTxt: false,
    globlWebContents: {},
    pageTitle: ""
  };

  componentDidMount() {

    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: clientName + PAGE_TITLES.LOGIN })

    this.sharedService.insproservice().subscribe(response => {
      if (response.status === 200) {
        this.setState({
          enableActions: response.data.insproAvailableInd,
          registerAttempt: getCookie('Attempt') === "" ? 0 : parseInt(getCookie('Attempt'))
        });

      }
      this.props.notificationContext.processMessages(response.data.messages);
    });

    // Check for inter-screen message
    setTimeout(() => { // to avoid race condition present in index.jsx file
      if (this.context.state.interScreenNotification && this.context.state.interScreenNotification.length !== 0) {
        this.context.processMessages(this.context.state.interScreenNotification);
        // Clear the message
        this.context.setInterScreenNotification([])
      }
    }, 100)

    if (this.props.match.params.id) {
      localStorage.setItem('user', this.props.match.params.id);
      window.location.href = '/';
    }

    this.globalWC_getContent([ARTICLES.LOGIN_FORM_REG_INFO, ARTICLES.MYACCOUNT_WELCOME]);
    let isRememberedUsername = localStorage.getItem("rememberedUsername");
    isRememberedUsername !== null &&
      this.setState({
        username: isRememberedUsername,
        rememberMe: true
      });
  }

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items && response.data.items.forEach(element => {
          webContents[element.title] = element.content;
        });
        this.setState({
          globlWebContents: webContents
        }, () => {
          // To show the one time welcome message
          let hideWelcomeTxt = JSON.parse(localStorage.getItem('hideWelcomeTxt'));
          let hasContent = this.state.globlWebContents[ARTICLES.MYACCOUNT_WELCOME];
          if (!hideWelcomeTxt && hasContent) {
            let infoNotifications = this.props.notificationContext.state.infoNotification;
            let customInfo = [{
              type: 'INFO',
              content: [this.state.globlWebContents[ARTICLES.MYACCOUNT_WELCOME]]
            }]
            let result = infoNotifications.concat(customInfo);

            this.props.notificationContext.processMessages(result);
          }
        });
      }
    });
  };

  login = () => {
    if (this.checkValidation()) {
      let { isSubdomain, companyId, domainGroupId, client } = JSON.parse(localStorage.getItem("initialIds"));
      let params = {
        username: this.state.username,
        password: this.state.password,
        companyId: companyId,
        domainGroupId: domainGroupId,
        isSubdomain: isSubdomain,
        client: client,
        isCustomerUser: false
      };

      this.props.setLoading(true);
      this.authenticationService.login(params).subscribe(response => {
        if (response.status === 200) {
          if (response.data.isMigratedUser === 'N') {
            this.props.history.push({
              pathname: "reactivate-account",
              email: this.state.username
            });
            return;
          }
          if (response.data.access_token) {
            localStorage.setItem("user", JSON.stringify(response.data));
            (async () => {
              let ip = await publicIp.v4();
              let params = {
                "username": this.state.username,
                "companyId": companyId,
                "domainGroupId": domainGroupId,
                "isCustomerUser": false,
                "client": client,
                clientLoginIp: ip
              };
              this.authenticationService.updateLoginInformations(params).subscribe(_response => {
                if (_response.status !== 403) {
                  listenersService.triggreAuthStateChanged(JSON.stringify(response.data));
                  let { rememberMe, username } = this.state;
                  rememberMe ? localStorage.setItem("rememberedUsername", username) : localStorage.removeItem("rememberedUsername");
                  localStorage.setItem("hideWelcomeTxt", true);
                  this.props.history.push("dashboard");
                } else {
                  this.props.setLoading(false);
                }
              })
            })();
          } else {
            this.props.setLoading(false);
            // this.props.history.push("forgot-password");
          }
        } else {
          this.props.setLoading(false);
          this.props.notificationContext.processMessages(response.data.messages)
        }
      });
    }
  };

  checkValidation = () => {
    if (/^\s*$/.test(this.state.username)) {
      this.setState({ isFormValid: false });
      return false;
    }

    if (/^\s*$/.test(this.state.password)) {
      this.setState({ isFormValid: false });
      return false;
    }

    this.setState({ isFormValid: true });
    return true;
  };

  handleChange = e => {
    // e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.checkValidation();
    });
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.login();
    }
  };

  handleRememberChkBox = () => {
    this.setState({ rememberMe: !this.state.rememberMe });
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.LOGIN] || this.state.pageTitle}</title>
        </Helmet>
        {this.props.globalContext.state.deviceWidth >= 768 && (
          <div className="desktopView loginNotification">
            {this.props.notificationContext.state.infoNotification.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
            {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
          </div>
        )}
        <div className='loginWrapper'>
          <div className="loginWrapperInner">
            <div className="loginBodyRight">
              {this.props.globalContext.state.deviceWidth <= 767 && (
                <div className="mobileView loginNotification">
                  {this.props.notificationContext.state.infoNotification.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
                  {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
                </div>
              )}
              <div className='loginColumn'>
                <form action='/' className='was-validation' noValidate>
                  <h1 className='desktopView commonHeadText'>{LOCALIZATION.EN.LOGIN_TITLE}</h1>
                  <h1 className='mobileView mainHeadingT text-center'>{LOCALIZATION.EN.LOGIN_TITLE}</h1>
                  <div className='form-group'>
                    <div className='username'>
                      <label htmlFor='user-email'>Username</label>
                      <input className='form-control' onKeyDown={this.handleKeyDown} type='text' name='username' value={this.state.username} noValidate onChange={this.handleChange} disabled={(this.state.enableActions) ? "" : "disabled"} />
                      <div className='invalid-feedback'>Please fill out this field.</div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='password'>
                      <label htmlFor='user-password'>Password</label>
                      <input
                        className='form-control password'
                        type={this.state.hidePassword ? "password" : "text"}
                        name='password'
                        onKeyDown={this.handleKeyDown}
                        noValidate
                        onChange={this.handleChange}
                        disabled={(this.state.enableActions) ? "" : "disabled"}
                      />
                      <label
                        onClick={() => {
                          this.setState({ hidePassword: !this.state.hidePassword });
                        }}
                        className='showHide'>
                        <i className={"far " + (this.state.hidePassword ? "fa-eye" : "fa-eye-slash")}></i>
                      </label>
                      <div className='invalid-feedback'>Please fill out this field.</div>
                    </div>
                  </div>
                  <div className='checkRemember'>
                    <input type='checkbox' checked={this.state.rememberMe} onChange={this.handleRememberChkBox} id='rememberCheck' name='Remember me' />
                    <span className="material-icons blankCheckbox">check_box_outline_blank</span>
                    <span className="material-icons selectedCheckbox">check_box</span>
                    <label className='rememberCheck'>
                      Remember my username
                </label>
                  </div>
                  <div className="roundBottomButton">
                    <button id="ma-lg-login" className={"btn btn-primary agiaBlueButton mt-3 btn-width-110 " + (!this.state.isFormValid && "disabled")} type='button' value='Log In' onClick={this.login} disabled={(this.state.enableActions) ? "" : "disabled"}>
                      Log In
            </button>
                  </div>
                  <div className='forgotPwd bottomLinks '>
                    <div className='col-md-6 col-sm-6 col-xs-6 col-6 float-left pl-0 pr-0'>
                      <Link title={""} to='/forgot-username' onClick={(this.state.enableActions) ? () => { return } : e => e.preventDefault()}>
                        Forgot username?
                    </Link>
                    </div>
                    <div className='col-md-6 col-sm-6 col-xs-6 col-6 float-left pl-0 pr-0'>
                      <Link title={""} to='/forgot-password' onClick={(this.state.enableActions) ? () => { return } : e => e.preventDefault()} >
                        Forgot password?
                    </Link>
                    </div>
                  </div>
                </form>
              </div>
              <div className='registerWrapperMain'>
                <h2 className="loggedInText">First time logging in?</h2>
                {/* <div id={`globalWC_${ARTICLES.LOGIN_FORM_REG_INFO}`}></div> */}
                {/* {this.state.hideWelcomeTxt && <div dangerouslySetInnerHTML={{ __html: this.state.globlWebContents[ARTICLES.MYACCOUNT_WELCOME] }}></div>} */}
                <div className="roundBottomButton">
                  <button id="ma-ra-startRegister" onClick={() => { this.props.history.replace('/account-details') }} className={"btn register-btn agiaBlueButton-outline btn-width-110 "} type='button' value='Submit' disabled={(this.state.enableActions) ? "" : "disabled"}>
                    Register for your online account
              </button>
                </div>
              </div>
            </div>
          </div>
        </div >
      </>
    );
  }
}

Login.contextType = NotificationContext; 
