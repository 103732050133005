import { HttpRequest } from "./http/http.service";
import { apiRequest } from "../_constants/url.constants";

export class ProductsService {
  http = new HttpRequest();

  getProducts(params) {
    return this.http.post(apiRequest.base + apiRequest.getProductsMain, params);
  }

  getProductDetails(params) {
    return this.http.post(apiRequest.base + apiRequest.getProductDetails, params);
  }

  getDocument(documentId, documentType, documentCategory) {
    let documentUrl = apiRequest.viewDocument.replace('{documentId}', documentId).replace('{documentType}', documentType).replace('{documentCategory}', documentCategory);
    return this.http.get(apiRequest.base + documentUrl);
  }
}
