import React from "react";
import { Link } from "react-router-dom";
import { ARTICLES } from "../../../../_constants/constants";
import { InactiveProducts } from "./inactive-products/InactiveProducts";

export class ProductsMainDesktop extends React.Component {
  state = {
    showInactiveProducts: false
  };

  getDisclaimers = () => {
    let disclaimerTypesMap = [
      { name: 'autopay', content: this.props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER] },
      { name: 'combinedbill', content: this.props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING] },
      { name: 'nocosttoyou', content: this.props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_NOCOST] },
    ]
    let components = [];
    let iterated = [];
    this.props.products.forEach((product, index) => {
      let disclaimerObj = disclaimerTypesMap.find((item) => item.name === product.paymentStatusDisplayStyle);
      let hasAlreadyIterated = !iterated.includes(product.paymentStatusDisplayStyle);

      if (disclaimerObj && hasAlreadyIterated) {
        components.push(<div key={index} dangerouslySetInnerHTML={{ __html: disclaimerObj.content }} ></div>)
        iterated.push(product.paymentStatusDisplayStyle);
      }
    });

    return components;
  }

  render() {

    return (
      <div className='desktopView productDetailTemplate'>
        {this.props.products.length > 0 &&
          <>
            <h2 className='homeHederText'>Active Products</h2>
            <table className='table table-light borderless productTable'>
              <thead>
                <tr>
                  <th className="product-col" scope='col'>PRODUCT</th>
                  <th className="coverage-type-col" scope="col">COVERAGE TYPE</th>
                  <th className="coverage-eff-date-col" scope="col">COVERAGE EFFECTIVE DATE</th>
                  <th className="annual-product-cost-col amount" scope="col">ANNUAL PRODUCT COST</th>
                  <th className="payment-frequency-col" scope='col'>PAYMENT FREQUENCY</th>
                  <th className="duedate-col" scope='col'>DUE DATE</th>
                </tr>
              </thead>
              <tbody>
                {this.props.products.map(value => (
                  <tr key={value.policyNumber} tabIndex='0'>
                    <td>
                      <Link title={""} to={`/product-details/${value.policyNumber}`}>
                        <span className='anchorText'>{value.productDesc}</span>
                      </Link>
                      <span className='smallTableText'>{value.policyNumber}</span>
                    </td>
                    <td>{value.coverageType}</td>
                    <td>{value.coverageEffectiveDate}</td>
                    <td className="amount">{value.annualPremium}
                      {value.annualPremiumSpecialCharacter && <sup dangerouslySetInnerHTML={{ __html: value.annualPremiumSpecialCharacter }}></sup>}
                    </td>
                    <td>{value.currentPaymentFrequecyType}</td>
                    <td>
                      <span className='dateValue'>{value.dueDate}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='footerTextPara'>
              {/* <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.CLAIM_NOW] }} ></div> */}
              {this.getDisclaimers()}
              <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
              <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.ANNUAL_PRODUCT_COST_DISCLAIMER] }}></div>
            </div>
          </>
        }

        {this.props.inactiveProducts.length > 0 && (
          <div className='inActiveTable'>
            <div className='inavtiveHeader'>
              <h2 className='homeHederText'>Inactive Products</h2>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showInactiveProducts: !this.state.showInactiveProducts });
                }}
                aria-controls='collapse-text'
                aria-expanded={this.state.showInactiveProducts}
                className='collapse-text'
                to='#'>
                <span className='leftArrow'>Show</span>
                <span className='downArrow'>Hide</span>
              </Link>
            </div>
            {this.state.showInactiveProducts && <InactiveProducts inactiveProducts={this.props.inactiveProducts} />}
          </div>
        )}
      </div>
    );
  }
}
