import React from "react";
import Modal from "react-bootstrap/Modal";

export class StaticContentModal extends React.Component {

    state = {
        cntrClass: ''
    }

    componentDidMount() {
        if (this.props.title === 'Privacy Policy' || this.props.title === 'Terms and Conditions') {
            this.setState({ cntrClass: 'addPaymentMethodPopup' })
        }

        this.props.globalContext.triggerGTMDataLayer({
            event: 'MyAccountPageView',
            pagePath: `/${this.props.title.toLowerCase().replaceAll(' ', '-')}`,
            pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${JSON.parse(localStorage.getItem("initialIds")).clientName} - ${this.props.title}`
        })
    }

    componentWillUnmount() {
        this.props.globalContext.triggerGTMDataLayer({
            event: 'MyAccountPageView',
            pagePath: this.props.location.pathname,
            pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
        })
    }

    render() {
        return (
            <Modal
                className={this.state.cntrClass}
                backdrop='static'
                show={this.props.show}
                onHide={() => {
                    this.props.hide();
                }}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p dangerouslySetInnerHTML={{ __html: this.props.content }}></p>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className='btn agiaBlueButton-outline grayButton'
                        type='button'
                        onClick={() => { this.props.hide() }}
                    >Close
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}
