import React, { useState } from "react";
import "./PaymentHistory.scss";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";

export const PaymentHistory = props => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className='paymentHistory'>
        <div className='checkBoxShow'>
          <span className='showText'>Show</span>
          <select>
            <option selected='' value='All Products'>
              All Products
            </option>
          </select>
        </div>
        <div className='desktopView paymentHistoryTemplate'>
          <h2 class='homeHederText'>Scheduled Payments</h2>
          <table className='table table-light borderless productTable'>
            <thead>
              <tr>
                <th scope='col'>PRODUCT</th>
                <th scope='col' className='amount'>AMOUNT</th>
                <th scope='col' className='amount'>DATE REQUESTED</th>
                <th scope='col' className='amount'>DATE SCHEDULED</th>
                <th scope='col'>PAYMENT METHOD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Link to='/#'>
                    <span className='anchorText'>Accidental Death and D...</span>
                  </Link>
                  <span className='smallTableText'>8765309805</span>
                </td>
                <td className='amount'>$14.72</td>
                <td className='amount'>3/1/19</td>
                <td className='amount'>3/1/19</td>
                <td>Visa-1234</td>
              </tr>
              <tr>
                <td>
                  <Link to='/#'>
                    <span className='anchorText'>Term Life</span>
                  </Link>
                  <span className='smallTableText'>8765309855</span>
                </td>
                <td className='amount'>$14.72</td>
                <td className='amount'>3/1/19</td>
                <td className='amount'>3/1/19</td>
                <td>Visa-1234</td>
              </tr>
              <tr>
                <td>
                  <Link to='/#'>
                    <span className='anchorText'>AD Rider</span>
                  </Link>
                  <span className='smallTableText'>8765309855</span>
                </td>
                <td className='amount'>$14.72</td>
                <td className='amount'>3/1/19</td>
                <td className='amount'>3/1/19</td>
                <td>Visa-1234</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='desktopView paymentHistoryTemplate pastPayments'>
          <h2 class='homeHederText'>Past Payments</h2>
          <table className='table table-light borderless productTable'>
            <thead>
              <tr>
                <th scope='col'>PRODUCT</th>
                <th scope='col'>CONFIRMATION #</th>
                <th scope='col' className='amount'>AMOUNT PAID</th>
                <th scope='col' className='amount'>DATE REQUESTED</th>
                <th scope='col' className='amount'>DATE PAID</th>
                <th scope='col'>PAYMENT METHOD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Link to='/#'>
                    <span className='anchorText'>Accidental Death and D...</span>
                  </Link>
                  <span className='smallTableText'>8765309805</span>
                </td>
                <td>912938470987</td>
                <td className='amount'>$14.72</td>
                <td className='amount'>3/1/19</td>
                <td className='amount'>3/1/19</td>
                <td>Visa-1234</td>
              </tr>
              <tr>
                <td>
                  <Link to='/#'>
                    <span className='anchorText'>Accidental Death and D...</span>
                  </Link>
                  <span className='smallTableText'>8765309805</span>
                </td>
                <td>912938470987</td>
                <td className='amount'>$14.72</td>
                <td className='amount'>3/1/19</td>
                <td className='amount'>3/1/19</td>
                <td>Visa-1234</td>
              </tr>
              <tr>
                <td>
                  <Link to='/#'>
                    <span className='anchorText'>Accidental Death and D...</span>
                  </Link>
                  <span className='smallTableText'>8765309805</span>
                </td>
                <td>912938470987</td>
                <td className='amount'>$14.72</td>
                <td className='amount'>3/1/19</td>
                <td className='amount'>3/1/19</td>
                <td>Visa-1234</td>
              </tr>
              <tr>
                <td>
                  <Link to='/#'>
                    <span className='anchorText'>Accidental Death and D...</span>
                  </Link>
                  <span className='smallTableText'>8765309805</span>
                </td>
                <td>912938470987</td>
                <td className='amount'>$14.72</td>
                <td className='amount'>3/1/19</td>
                <td className='amount'>3/1/19</td>
                <td>Visa-1234</td>
              </tr>
              <tr>
                <td>
                  <Link to='/#'>
                    <span className='anchorText'>Accidental Death and D...</span>
                  </Link>
                  <span className='smallTableText'>8765309805</span>
                </td>
                <td>912938470987</td>
                <td className='amount'>$14.72</td>
                <td className='amount'>3/1/19</td>
                <td className='amount'>3/1/19</td>
                <td>Visa-1234</td>
              </tr>
            </tbody>
            <tfoot><tr><td colspan="6">
              <div class="pagination"><span class="rowPages">Rows per page: </span><select><option value="10">10</option><option value="20">20</option><option value="30">30</option><option value="40">40</option><option value="50">50</option></select><span class="middlePaginationContent">91 - 100 of 196</span><button><span class="materialicons">first_page</span></button> <button><span class="materialicons">chevron_left</span></button> <button><span class="materialicons">chevron_right</span></button> <button><span class="materialicons">last_page</span></button> </div>
              </td></tr>
              </tfoot>
          </table>
          </div>
          {/* Mobile View Started */}
        <div className='mobileView productsDesc paymentHistoryTemplate'>
          <div className="singleCol">
            <h2 class="homeHederText">Scheduled Payments</h2>
            <div class="flexWhiteBox productDocs">
              <div class="leftColumn">
                <div class="bodyRow">
                  <div class="flexBox">
                    <ul>
                      <li onClick={() => setOpen(!open)} aria-controls='collapse-text' aria-expanded={open} className='collapse-text'>
                        <span className='label'>Term Life</span>
                        <span className='value'>
                          <span className="priceValue">3/1/19</span>
                          <span className="materialicons leftArrow">chevron_left</span>
                          <span className="materialicons downArrow">expand_more</span>
                        </span>
                      </li>
                      <Collapse in={open}>
                        <div id='collapseText' className='collapseText'>
                          <div className='bodyRow'>
                            <div className='flexBox'>
                              <ul>
                                <li>
                                  <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                                  <span className='value'>8765309805</span>
                                </li>
                                <li>
                                  <span className='label'>AMOUNT</span>
                                  <span className='value'>$14.72</span>
                                </li>
                                <li>
                                  <span className='label'>DATE REQUESTED</span>
                                  <span className='value'>3/1/19</span>
                                </li>
                                <li>
                                  <span className='label'>DATE SCHEDULED</span>
                                  <span className='value'>3/1/19</span>
                                </li>
                                <li>
                                  <span className='label'>PAYMENT METHOD</span>
                                  <span className='value'>Visa-1234</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                      <li onClick={() => setOpen(!open)} aria-controls='collapse-text' aria-expanded={open} className='collapse-text'>
                        <span className='label'>Cancer Care</span>
                        <span className='value'>
                          <span className="priceValue">2/1/19</span>
                          <span className="materialicons leftArrow">chevron_left</span>
                          <span className="materialicons downArrow">expand_more</span>
                        </span>
                      </li>
                      <Collapse in={open}>
                        <div id='collapseText' className='collapseText'>
                          <div className='bodyRow'>
                            <div className='flexBox'>
                              <ul>
                                <li>
                                  <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                                  <span className='value'>8765309805</span>
                                </li>
                                <li>
                                  <span className='label'>AMOUNT</span>
                                  <span className='value'>$14.72</span>
                                </li>
                                <li>
                                  <span className='label'>DATE REQUESTED</span>
                                  <span className='value'>3/1/19</span>
                                </li>
                                <li>
                                  <span className='label'>DATE SCHEDULED</span>
                                  <span className='value'>3/1/19</span>
                                </li>
                                <li>
                                  <span className='label'>PAYMENT METHOD</span>
                                  <span className='value'>Visa-1234</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                      {/* <li className="footPagination">Show 5 more</li> */}
                    </ul>

                  </div>
                </div>
              </div>
            </div>
        </div>
          <div className="singleCol">
            <h2 class="homeHederText">Past Payments</h2>
            <div class="flexWhiteBox productDocs">
              <div class="leftColumn">
                <div class="bodyRow">
                  <div class="flexBox">
                    <ul>
                      <li onClick={() => setOpen(!open)} aria-controls='collapse-text' aria-expanded={open} className='collapse-text'>
                        <span className='label'>Term Life</span>
                        <span className='value'>
                          <span className="priceValue">3/1/19</span>
                          <span className="materialicons leftArrow">chevron_left</span>
                          <span className="materialicons downArrow">expand_more</span>
                        </span>
                      </li>
                      <Collapse in={open}>
                        <div id='collapseText' className='collapseText'>
                          <div className='bodyRow'>
                            <div className='flexBox'>
                              <ul>
                                <li>
                                  <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                                  <span className='value'>8765309805</span>
                                </li>
                                <li>
                                  <span className='label'>AMOUNT</span>
                                  <span className='value'>$14.72</span>
                                </li>
                                <li>
                                  <span className='label'>DATE REQUESTED</span>
                                  <span className='value'>3/1/19</span>
                                </li>
                                <li>
                                  <span className='label'>DATE SCHEDULED</span>
                                  <span className='value'>3/1/19</span>
                                </li>
                                <li>
                                  <span className='label'>PAYMENT METHOD</span>
                                  <span className='value'>Visa-1234</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                      <li onClick={() => setOpen(!open)} aria-controls='collapse-text' aria-expanded={open} className='collapse-text'>
                        <span className='label'>Cancer Care</span>
                        <span className='value'>
                          <span className="priceValue">2/1/19</span>
                          <span className="materialicons leftArrow">chevron_left</span>
                          <span className="materialicons downArrow">expand_more</span>
                        </span>
                      </li>
                      <Collapse in={open}>
                        <div id='collapseText' className='collapseText'>
                          <div className='bodyRow'>
                            <div className='flexBox'>
                              <ul>
                                <li>
                                  <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                                  <span className='value'>8765309805</span>
                                </li>
                                <li>
                                  <span className='label'>AMOUNT</span>
                                  <span className='value'>$14.72</span>
                                </li>
                                <li>
                                  <span className='label'>DATE REQUESTED</span>
                                  <span className='value'>3/1/19</span>
                                </li>
                                <li>
                                  <span className='label'>DATE SCHEDULED</span>
                                  <span className='value'>3/1/19</span>
                                </li>
                                <li>
                                  <span className='label'>PAYMENT METHOD</span>
                                  <span className='value'>Visa-1234</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                      <li className="footPagination">Show 5 more</li>
                    </ul>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View End */}   
      </div>
    </>
  );
};
