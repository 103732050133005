import React from "react";
import { Link } from "react-router-dom";
import { useTable, usePagination } from "react-table";

let Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    usePagination
  );

  return (
    <div className='inActiveContent'>
      <table {...getTableProps()} className='table table-light borderless productTable'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th className="product-col" {...headerGroup.headers[0].getHeaderProps()} scope='col'>
                {headerGroup.headers[0].render("Header")}
              </th>
              <th scope='col'>&nbsp;</th>
              <th scope='col'>&nbsp;</th>
              <th {...headerGroup.headers[1].getHeaderProps()} scope='col' className='amount amount-col'>
                {headerGroup.headers[1].render("Header")}
              </th>
              <th className="duedate-col" {...headerGroup.headers[2].getHeaderProps()} scope='col'>
                {headerGroup.headers[2].render("Header")}
              </th>

              {/* {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))} */}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td {...row.cells[0].getCellProps()}>
                  <Link to={`/product-details/${row.values.policyNumber}`}>
                    <span className='anchorText'>{row.values.productDesc}</span>
                  </Link>
                  <span className='smallTableText'>{row.values.policyNumber}</span>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td {...row.cells[1].getCellProps()} className='amount'>
                  {row.values.amountDue}
                </td>
                <td {...row.cells[2].getCellProps()}>{row.values.dueDate}</td>

                {/* {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })} */}
              </tr>
            );
          })}
        </tbody>
        {data.length >= 10 && (
          <tfoot>
            <tr>
              <td colSpan='5'>
                <div className='pagination'>
                  <span className='rowPages'>Rows per page: </span>
                  <select
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    }}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                  <span className='middlePaginationContent'>
                    {/* {pageIndex + 1} of {data.length} */}
                    {pageSize * (pageIndex + 1) - (pageSize - 1)} - {pageSize * (pageIndex + 1)} of {data.length}
                  </span>
                  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <span className='materialicons'>first_page</span>
                  </button>{" "}
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <span className='materialicons'>chevron_left</span>
                  </button>{" "}
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    <span className='materialicons'>chevron_right</span>
                  </button>{" "}
                  <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <span className='materialicons'>last_page</span>
                  </button>{" "}
                  {/* <span>
          | Go to page:{" "}
          <input
            type='number'
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "} */}
                </div>
              </td>
            </tr>
          </tfoot>
        )}

      </table>
    </div>
  );
};

export const InactiveProducts = props => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Product",
        accessor: "productDesc"
      },
      {
        Header: "Amount Paid",
        accessor: "amountDue"
      },
      {
        Header: "Paid Through",
        accessor: "dueDate"
      },
      {
        Header: "Policy Number",
        accessor: "policyNumber"
      }
    ],
    []
  );

  return <Table columns={columns} data={props.inactiveProducts} />;
};
