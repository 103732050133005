import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReCaptcha } from "react-recaptcha-v3";
import Modal from "react-bootstrap/Modal";
import { NOTIFICATIONS, API_KEYS, ARTICLES, PAGE_TITLES, PAGES_TITLE_KEYS } from "../../_constants/constants";
import { AuthenticationService } from "../../_services/auth.service";
import { SharedService } from "../../_services/shared.service";
import { LOCALIZATION } from "../../_constants/localization";
import "./forgot.scss";
import { Notification } from '../shared/notifications/notifications';
import { Helmet } from "react-helmet";

export class ForgotUserName extends Component {
  authenticationService = new AuthenticationService();
  sharedService = new SharedService();

  state = {
    planNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    isBot: true,
    showPopup: false,
    isFormValid: false,
    globlWebContents: {},
    message: {
      type: "",
      text: ""
    },
    infoMessage: {
      type: "",
      content: [""]
    },
    pageTitle: ""
  };

  componentDidMount() {
    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: client + PAGE_TITLES.FORGOT_USERNAME })

    this.props.setLoading(true);
    this.globalWC_getContent([ARTICLES.CERTI_NUM_INFO, ARTICLES.FORGOT_USERNAME_FORM_INFO]);
  }

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items && response.data.items.forEach(element => {
          webContents[element.title] = element.content;
        });
        this.setState({
          globlWebContents: webContents
        });
      }
    });
  };

  verifyCallback = recaptchaToken => {
    // Here you will get the final recaptchaToken!!!
    if (recaptchaToken) {
      let params = {
        token: recaptchaToken
      }
      
      this.props.setLoading(true);
      this.sharedService.verifyCaptcha(params).subscribe(response => {
        this.props.setLoading(false);
        if (response.status === 200) {
          this.setState({
            isBot: response.data.isBot
          });
        }
      });
    } else {
      this.props.setLoading(false);
    }
  };

  checkValidation = () => {
    let errorString = "";
    if (/^\s*$/.test(this.state.planNumber)) {
      // this.setState({ isFormValid: false });
    }

    if (!/^\d+$/.test(this.state.planNumber)) {
      errorString = errorString + NOTIFICATIONS.PLAN_NUM_NUMERIC + "\n";
    }

    if (this.state.planNumber.length !== 10) {
      errorString = errorString + NOTIFICATIONS.PLAN_NUM_10_LEN + "\n";
    }

    // Email validation
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(this.state.email.trim()).toLowerCase())) {
      errorString = errorString + NOTIFICATIONS.INVALID_EMAIL_FORMAT + "\n";
    }

    if (errorString !== "") {
      // this.setState({ isFormValid: false });
      return errorString;
    } else {
      // this.setState({ isFormValid: true });
      return null;
    }
  };

  checkRequiredFields = () => {
    if (/^\s*$/.test(this.state.planNumber)) {
      this.setState({ isFormValid: false });
      return false;
    }

    if (/^\s*$/.test(this.state.firstName)) {
      this.setState({ isFormValid: false });
      return false;
    }

    if (/^\s*$/.test(this.state.lastName)) {
      this.setState({ isFormValid: false });
      return false;
    }

    if (/^\s*$/.test(this.state.email)) {
      this.setState({ isFormValid: false });
      return false;
    }

    this.setState({ isFormValid: true });
    return true;
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: (name === 'planNumber') ? value.replace(/\s/g, '') : value }, () => {
      this.checkRequiredFields();
    });
  };

  handleSubmit = () => {
    if (!this.state.isBot) {
      if (this.state.isFormValid) {
        let hasValidationError = this.checkValidation();
        if (hasValidationError === null) {
          let { companyId, client, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
          let hostName = document.location.hostname;
          let params = {
            client: client,
            companyId: companyId,
            certificateNo: this.state.planNumber,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            emailAddress: this.state.email,
            hostName: hostName,
            domainGroupId: domainGroupId
          };
          this.props.setLoading(true);
          this.authenticationService.forgotUsername(params).subscribe(response => {
            if (response.status === 200) {
              this.props.notificationContext.setInterScreenNotification(response.data.messages);
              this.props.history.push("/");
            } else {
              this.props.notificationContext.processMessages(response.data.messages)
              // this.setState({
              //   infoMessage: {
              //     type: "FAILURE",
              //     content: [response.data.message]
              //   }
              // });
            }
            this.props.setLoading(false);
          });
        } else if (hasValidationError !== null) {
          this.props.notificationContext.processMessages([{
            type: "ERROR",
            content: [hasValidationError]
          }])
          this.setState({
            loading: false,
            // infoMessage: {
            //   type: "FAILURE",
            //   content: [hasValidationError]
            // }
          });
        }
      }
    } else {
      alert(NOTIFICATIONS.BOT_SUSPECTED);
    }
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.FORGOT_USERNAME] || this.state.pageTitle}</title>
        </Helmet>

        <div className='usernameWrapper'>
          <div className='lusernameColumn'>
            {/* <form action="/" className="was-validation" noValidate> */}
            <h1 className="mainHeadingT">{LOCALIZATION.EN.FORGOT_USERNAME}</h1>
            {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
            <p dangerouslySetInnerHTML={{ __html: this.state.globlWebContents[ARTICLES.FORGOT_USERNAME_FORM_INFO] }}></p>
            <div className="backgroundSetColor">
              <div className='formLayout'>
                <div className='form-group'>
                  <div className='planNumber mt-3'>
                    <label htmlFor='uname'>Certificate/Plan Number</label>
                    <input value={this.state.planNumber} onKeyDown={this.handleKeyDown} className='form-control' type='text' id='planNumber' onChange={this.handleChange} name='planNumber' required />

                    <Link
                      title={""}
                      to='/#'
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ showPopup: true });
                        this.props.globalContext.triggerGTMDataLayer({
                          event: 'MyAccountPageView',
                          pagePath: '/find-plan-number',
                          pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${JSON.parse(localStorage.getItem("initialIds")).client} - Where to find your Certificate/Plan Number?`
                        })
                      }}
                      className='findPlanText'>
                      Where to find your Certificate/Plan Number?
                  </Link>
                    <div className='invalid-feedback'>Please fill out this field.</div>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='lastName mt-3'>
                    <label htmlFor='pwd'>First Name</label>
                    <input onKeyDown={this.handleKeyDown} className='form-control' type='text' onChange={this.handleChange} name='firstName' required />
                    <div className='invalid-feedback'>Please fill out this field.</div>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='lastName mt-3'>
                    <label htmlFor='pwd'>Last Name</label>
                    <input onKeyDown={this.handleKeyDown} className='form-control' type='text' onChange={this.handleChange} name='lastName' required />
                    <div className='invalid-feedback'>Please fill out this field.</div>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='lastName mt-3'>
                    <label htmlFor='pwd'>Email</label>
                    <input onKeyDown={this.handleKeyDown} className='form-control' type='text' onChange={this.handleChange} name='email' required />
                    <div className='invalid-feedback'>Please fill out this field.</div>
                  </div>
                </div>
              </div>
              <ReCaptcha sitekey={API_KEYS.RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />        
              {/* <div className='captcha'>                
                <iframe className='recaptcha-v3-iframe' src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;logging=true&amp;k=6LeuMjIUAAAAAODtAglF13UiJys0y05EjZugej6b&amp;co=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbTo0NDM.&amp;hl=en&amp;v=pRiAUlKgZOMcFLsfzZTeGtOA&amp;size=invisible&amp;cb=mrb3yb91jnco" width="256" height="60" role="presentation" name="a-ox28xci2mgb0" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe>
              </div> */}
            </div>
            <div className="roundBottomButton">
              <button className={"btn btn-primary agiaBlueButton mt-3 " + (!this.state.isFormValid && "disabled")} type='button' onClick={this.handleSubmit} value='Submit'>
                Send recovery email
            </button>
              <Link to="/">Back to login</Link>
              {/* </form> */}
            </div>
          </div>
          <Modal
            backdrop='static'
            className="registarPop"
            show={this.state.showPopup}
            onHide={() => {
              this.setState({ showPopup: false });
            }}>
            <Modal.Header closeButton>
              <Modal.Title>Where to find your Certificate/Plan Number?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <img alt={""} src={require("../../_assets/images/Bill-image.jpg")}></img>
              <img alt={""} src={require("../../_assets/images/Cancer-FF-Image.jpg")}></img>
              <img alt={""} src={require("../../_assets/images/EA-FF-Image.jpg")}></img> */}
              <p dangerouslySetInnerHTML={{ __html: this.state.globlWebContents[ARTICLES.CERTI_NUM_INFO] }}></p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className={"btn agiaBlueButton-outline grayButton"}
                type='button'
                onClick={() => {
                  this.props.globalContext.triggerGTMDataLayer({
                    event: 'MyAccountPageView',
                    pagePath: this.props.location.pathname,
                    pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
                  })
                  this.setState({ showPopup: false });
                }}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
