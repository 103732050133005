import React from "react";
import Modal from "react-bootstrap/Modal";

export class ConfirmBox extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      this.props.globalContext.triggerGTMDataLayer({
        event: 'MyAccountPageView',
        pagePath: `/${this.props.title.toLowerCase().replaceAll(' ', '-')}`,
        pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${JSON.parse(localStorage.getItem("initialIds")).client} - ${this.props.title}`
      })
    }, 1000)
  }

  componentWillUnmount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: 'MyAccountPageView',
      pagePath: this.props.location.pathname,
      pageTitle: `${this.props.globalContext.isCustomerServiceUser() ? 'CS:' : ''} ${window.document.title}`
    })
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.show}
        onHide={() => {
          this.props.hide(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body><div dangerouslySetInnerHTML={{ __html: this.props.content }}></div></Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              this.props.hide(false);
            }}
            type='button'
            className='btn agiaBlueButton-outline'
            data-dismiss='modal'>
            {this.props.negativeBtnText}
          </button>

          <button
            onClick={() => {
              this.props.hide(true);
            }}
            type='button'
            className='btn agiaBlueButton'
            data-dismiss='modal'>
            {this.props.positiveBtnText}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
