import React from "react";
import { Link } from "react-router-dom";
import { Overlay, Popover } from "react-bootstrap";
import { convertStringToAmount } from '../../../../../_services/utility.service';

export class PayNowDesktop extends React.Component {
  state = {
    showToolTip: false,
    toolTipTarget: null,
    disableControls: true
  }

  componentDidMount() {
    let isSubmitDisabled = this.props.product.paymentAction.submitPayment && this.props.product.paymentAction.submitPayment.disable === 'Y';
    let hasMessage = this.props.product.paymentAction.payNowLink.hasOwnProperty('message');
    let disableControls = isSubmitDisabled && hasMessage;
    this.setState({
      disableControls: disableControls
    })
  }

  handleToolTip = (event) => {
    event.persist();
    this.setState({ showToolTip: false });
    setTimeout(async () => {
      this.setState({
        showToolTip: !this.state.showToolTip,
        toolTipTarget: event.target
      })
    }, 200)
  };

  render() {
    return (
      <div className='desktopView'>
        {this.props.product && this.props.product.showPaymentAmountBreakdown && <Overlay
          show={this.state.showToolTip}
          target={this.state.toolTipTarget}
          placement="auto"
          rootClose={true}
          rootCloseEvent={'click'}
          onHide={() => { this.setState({ showToolTip: false }) }}
        >
          <Popover id="popover-contained">
            <div className='tooltipHover payment-amount-breakdown'>
              <div className='flexBox'>
                <ul>
                  <li>
                    <span className="label">AMOUNT DUE</span>
                    <span className="value">{this.props.product.paymentInfo.amountDue || '-'}</span>
                    <span className="tooltip-break-special-chars"><sup>{this.props.product.paymentInfo.amountDueSpecialCharacter}</sup></span>
                  </li>
                  <li>
                    <span className="label">Past Due</span>
                    <span className="value">{this.props.product.paymentInfo.pastDueAmount || '-'}</span>
                    <span className="tooltip-break-special-chars"></span>
                  </li>
                  <li>
                    <span className="label">Adjustment</span>
                    <span className="value">{this.props.product.paymentInfo.adjustment || '-'}</span>
                    <span className="tooltip-break-special-chars">{convertStringToAmount(this.props.product.paymentInfo.adjustment) > 0 && '\u00A0CR'}</span>
                  </li>
                  {convertStringToAmount(this.props.product.paymentInfo.billingCredit) !== 0 &&
                    < li >
                      <span className="label">Billing Credit</span>
                      <span className="value">{this.props.product.paymentInfo.billingCredit || '-'}</span>
                      <span className="tooltip-break-special-chars">{convertStringToAmount(this.props.product.paymentInfo.billingCredit) > 0 && '\u00A0CR'}</span>
                    </li>
                  }
                  <li>
                    <span className="label">TOTAL AMOUNT DUE</span>
                    <span className="value">{this.props.product.paymentInfo.totalAmountDue || '-'}</span>
                    <span className="tooltip-break-special-chars"></span>
                  </li>
                  {this.props.product.paymentInfo.disclaimer &&
                    <li>
                      <span className="tooltip-breakdown-disclaimer">{this.props.product.paymentInfo.disclaimer}</span>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </Popover>
        </Overlay>}
        <div className='payNowWrapper'>
          <div className='flexWhiteBox leftCol'>
            <h2 className='homeHederText'>Product</h2>
            <div className='leftColumn'>
              <div className='headerColumn'>
                <h3>
                  <Link title={""} to={`/product-details/${this.props.product.policyNumber}`}>{this.props.product.productDesc} </Link>
                </h3>
              </div>
              <div className='bodyRow'>
                <div className='flexBox'>
                  <ul>
                    <li>
                      <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                      <span className='value'>{this.props.product.policyNumber}</span>
                    </li>
                    <li>
                      <span className='label'>PAYMENT FREQUENCY</span>
                      <span className='value'>{this.props.product.currentPaymentFrequecyType}</span>
                    </li>
                    <li>
                      <span className='label'>DUE DATE</span>
                      <span className='value'>{this.props.product.dueDate}</span>
                    </li>
                    <li>
                      <span className='label'>AMOUNT DUE</span>
                      <span className='value payNowTooltipLocation'>
                        {this.props.product.amountDue}
                        {this.props.product.showPaymentAmountBreakdown && (
                          <span variant='success' onClick={($event) => { this.handleToolTip($event) }} className='materialicons infoIcon'>
                            info_outline
                          </span>
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {this.props.hasAnyPaymentMethods && (
            <div className='flexWhiteBox rightCol'>
              <h2 className='homeHederText'>Payment Method</h2>
              <div className='leftColumn'>
                <div className='headerColumn'>
                  <select
                    value={this.props.selectedPaymentMethodRecId}
                    onChange={this.props.handleDropDownChange}
                    name='selectedPaymentMethodRecId'
                    disabled={this.state.disableControls}>>
                    {this.props.paymentMethods.map(value => (
                      <option key={value.paymentRecordId} value={value.paymentRecordId}>
                        {value.paymentMethodHeader}
                      </option>
                    ))}
                  </select>
                  <div className='btn-links-edit'>
                    <Link
                      className={this.state.disableControls ? 'disabled-link-btn' : ''}
                      title={""}
                      to='#'
                      onClick={(e) => {
                        e.preventDefault();
                        !this.state.disableControls && this.props.handleOnEdit(this.props.selectedPaymentMethodObj);
                      }}>
                      Edit method
                    </Link>
                  </div>
                </div>
                <div className='bodyRow'>
                  <div className='flexBox'>
                    {this.props.selectedPaymentMethodObj.bankAccount == null && (
                      <ul>
                        <li>
                          <span className='label'>PAYMENT TYPE</span>
                          <span className='value'>{this.props.selectedPaymentMethodObj.paymentMethodHeader}</span>
                        </li>
                        <li>
                          <span className='label'>CARDHOLDER NAME</span>
                          <span className='value single-line-ellipses' title={this.props.selectedPaymentMethodObj.cardHolderName}>
                            {this.props.selectedPaymentMethodObj.cardHolderName}
                          </span>
                        </li>
                        <li>
                          <span className='label'>CARD NUMBER</span>
                          <span className='value'>{this.props.selectedPaymentMethodObj.creditCard.cardNumber}</span>
                        </li>
                        <li>
                          <span className='label'>CARD EXPIRATION</span>
                          <span className='value'>{this.props.selectedPaymentMethodObj.creditCard.expirationDate}</span>
                        </li>
                      </ul>
                    )}
                    {this.props.selectedPaymentMethodObj.creditCard == null && (
                      <ul>
                        <li>
                          <span className='label'>PAYMENT TYPE</span>
                          <span className='value'>{this.props.selectedPaymentMethodObj.paymentMethodHeader}</span>
                        </li>
                        <li>
                          <span className='label'>ACCOUNT-HOLDER NAME</span>
                          <span className='value'>{this.props.selectedPaymentMethodObj.cardHolderName}</span>
                        </li>
                        <li>
                          <span className='label'>ACCOUNT NUMBER</span>
                          <span className='value'>{this.props.selectedPaymentMethodObj.bankAccount.accountNumber}</span>
                        </li>
                        <li>
                          <span className='label'>ROUTING NUMBER</span>
                          <span className='value'>{this.props.selectedPaymentMethodObj.bankAccount.maskedRoutingNumber}</span>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* change block */}
          {!this.props.hasAnyPaymentMethods && (
            <div className='flexWhiteBox payNowaddPaymentM rightCol'>
              <div className='leftColumn'>
                <div className='text'>No payment methods found</div>

                <div className='roundBottomButton'>
                  <button
                    onClick={() => {
                      this.props.handleOnEdit(null);
                    }}
                    disabled={this.state.disableControls}
                    className='btn agiaBlueButton'
                    type='button'
                    value='Submit'>
                    <span className='materialicons'>add</span>
                    <span className='navText'>Add Payment Method</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* change block */}
        </div>
        <div className='bottomButton'>
          <button
            id="ma-pn-submitPayment"
            onClick={() => {
              this.props.confirmPaynow();
            }}
            className='btn agiaBlueButton payNow'
            type='button'
            disabled={!this.props.isFormValid || !this.props.hasAnyPaymentMethods || this.state.disableControls}
            value='Submit'>
            Submit Payment
          </button>

          <button
            onClick={() => {
              this.props.history.goBack();
            }}
            className='btn agiaBlueButton-outline payNow'
            type='button'
            value='Submit'>
            Go back
          </button>
        </div>
      </div>
    );
  }
}
