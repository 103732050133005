import React, { useState } from "react";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { ARTICLES } from '../../../../_constants/constants'

export const ProductsMainMobile = props => {
  const BATCH_SIZE_INACTIVE_PRODUCTS = 5;
  let dataTemp = props.inactiveProducts.slice();

  // const [open, setOpen] = useState(false);
  const [showInactiveProducts, setShowInactiveProducts] = useState(false);
  const [showMoreCounter, setShowMoreCounter] = useState(BATCH_SIZE_INACTIVE_PRODUCTS);
  const [inactiveProducts, setInactiveProducts] = useState(dataTemp.splice(0, showMoreCounter));


  // const toggleOpen = id => {
  //   setOpen(value => ({
  //     ...value,
  //     [id]: !value[id]
  //   }));
  // };

  const showMore = () => {
    let newCounter = showMoreCounter + BATCH_SIZE_INACTIVE_PRODUCTS;
    let dataTemp = props.inactiveProducts.slice();
    setInactiveProducts(dataTemp.splice(0, newCounter));
    setShowMoreCounter(newCounter);
  };

  const getDisclaimers = () => {
    let disclaimerTypesMap = [
      { name: 'autopay', content: props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER] },
      { name: 'combinedbill', content: props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING] },
      { name: 'nocosttoyou', content: props.globlWebContents[ARTICLES.PRODUCT_DISCLAIMER_NOCOST] },
    ]
    let components = [];
    let iterated = [];
    props.products.forEach((product, index) => {
      let disclaimerObj = disclaimerTypesMap.find((item) => item.name === product.paymentStatusDisplayStyle);
      let hasAlreadyIterated = !iterated.includes(product.paymentStatusDisplayStyle);

      if (disclaimerObj && hasAlreadyIterated) {
        components.push(<div key={index} dangerouslySetInnerHTML={{ __html: disclaimerObj.content }} ></div>)
        iterated.push(product.paymentStatusDisplayStyle);
      }
    });

    return components;
  }

  return (
    <div className='mobileView productDetailTemplate'>
      <div className='singleCol'>
        {props.products.length > 0 &&
          <>
            <h2 className='homeHederText'>Active Products</h2>
            <div className='flexWhiteBox col-lg-12 col-md-12 float-left p-0'>
              {props.products.map(value => (
                <div key={value.policyNumber} className='leftColumn col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0'>
                  <div className={"headerColumn"}>
                    <h3>
                      <Link title={""} to={`/product-details/${value.policyNumber}`}>
                        {value.productDesc}
                      </Link>
                    </h3>
                    <div className='btn-links'>
                      {/* <span className={value.paymentStatus && value.paymentStatus.toLowerCase() === "due now" ? "duePaymentButton" : "autoPayButton"} value='Due now'>
                      {value.paymentStatus}
                    </span> */}
                    </div>
                  </div>
                  <div className='bodyRow'>
                    <div className='flexBox'>
                      <ul>
                        <li>
                          <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                          <span className='value'>{value.policyNumber}</span>
                        </li>
                        <li>
                          <span className='label'>COVERAGE TYPE</span>
                          <span className='value'>{value.coverageType}</span>
                        </li>
                        <li>
                          <span className='label'>COVERAGE EFFECTIVE DATE</span>
                          <span className='value'>{value.coverageEffectiveDate}</span>
                        </li>
                        <li>
                          <span className='label'>ANNUAL PRODUCT COST</span>
                          <span className='value'>{value.annualPremium}
                            {value.annualPremiumSpecialCharacter && <sup dangerouslySetInnerHTML={{ __html: value.annualPremiumSpecialCharacter }}></sup>}
                          </span>
                        </li>
                        <li>
                          <span className='label'>PAYMENT FREQUENCY</span>
                          <span className='value'>{value.currentPaymentFrequecyType}</span>
                        </li>
                        <li>
                          <span className='label'>DUE DATE</span>
                          <span className='value'>{value.dueDate}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='footerTextPara'>
              {/* <div dangerouslySetInnerHTML={{ __html: props.globlWebContents[ARTICLES.CLAIM_NOW] }} ></div> */}
              {getDisclaimers()}
              <div dangerouslySetInnerHTML={{ __html: props.globlWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
              <div dangerouslySetInnerHTML={{ __html: props.globlWebContents[ARTICLES.ANNUAL_PRODUCT_COST_DISCLAIMER] }}></div>
            </div>
          </>}

      </div>

      {props.inactiveProducts.length > 0 && (
        <div className='inActiveTable singleCol'>
          <div className='inavtiveHeader'>
            <h2 className='homeHederText'>Inactive Products</h2>
            <Link
              onClick={(e) => { e.preventDefault(); setShowInactiveProducts(!showInactiveProducts) }}
              aria-controls='collapse-text'
              aria-expanded={showInactiveProducts}
              className='collapse-text'
              to='#'>
              <span className='leftArrow'>Show</span>
              <span className='downArrow'>Hide</span>
            </Link>
          </div>
          <div className='inActiveContent'>
            <Collapse in={showInactiveProducts}>
              <div className='flexWhiteBox col-lg-12 col-md-12 float-left p-0'>
                {props.inactiveProducts.map((value, index) => (
                  <div key={index} className='leftColumn col-lg-12 col-md-12 col-xs-12 col-12 float-left p-0'>
                    <div className='headerColumn'>
                      <h3>
                        <Link title={""} to={`/product-details/${value.policyNumber}`}>
                          {value.productDesc}
                        </Link>
                      </h3>
                    </div>
                    <div className='bodyRow'>
                      <div className='flexBox'>
                        <ul>
                          <li>
                            <span className='label'>CERTIFICATE/PLAN NUMBER</span>
                            <span className='value'>{value.policyNumber}</span>
                          </li>
                          <li>
                            <span className='label'>AMOUNT DUE</span>
                            <span className='value'>{value.amountDue}</span>
                          </li>
                          <li>
                            <span className='label'>DUE DATE</span>
                            <span className='value'>{value.dueDate}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}

                {showMoreCounter < props.inactiveProducts.length + 1 - BATCH_SIZE_INACTIVE_PRODUCTS && (
                  <button
                    className='showMoreButton'
                    onClick={() => {
                      showMore();
                    }}>
                    Show {BATCH_SIZE_INACTIVE_PRODUCTS} more
                  </button>
                )}
              </div>
            </Collapse>
          </div>
        </div>
      )}
    </div>
  );
};
