import React, { Component } from "react";
import "./Not-Found.scss";

class NotFound extends Component {

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      
      <div class="container">
          <div class="row">
              <div class="col-md-12">
                  <div class="error-template">
                      <img alt={""} class="logo" src={require("../../../_assets/images/agia-logo.jpg").default}></img>
                      <h1>Oops, page not found</h1>
                      <h3>Sorry, the page you are looking for is not here.</h3>
                      <div class="error-details">
                        Error 404
                      </div>
                  </div>
              </div>
          </div>
      </div>
        
    );
  }
}
export default NotFound;
