import React from "react";
import Modal from "react-bootstrap/Modal";
import { NOTIFICATIONS } from "../../../../_constants/constants";
import {
  phoneFormatting,
  extractDigits,
  numberAndDash,
} from "../../../../_services/utility.service";
import { SharedService } from "../../../../_services/shared.service";
import { ProfileService } from "../../../../_services/profile.service";
import { GlobalContext } from "../../../../_context/global.context";
import { Notification } from "../../../shared/notifications/notifications";

export class ChangeContactInfo extends React.Component {
  sharedService = new SharedService();
  profileService = new ProfileService();

  constructor(props) {
    super(props);
    this.state = {
      address1: this.props.userProfile.address.addressLine1,
      address2: this.props.userProfile.address.addressLine2 || "",
      address3: this.props.userProfile.address.addressLine3 || "",
      _state: this.props.userProfile.address.stateCode,
      city: this.props.userProfile.address.cityName,
      zip: this.props.userProfile.address.zipCode,
      phone: phoneFormatting(
        this.props.userProfile.phoneNumber,
        this.props.userProfile.phoneNumber
      ),
      email: this.props.userProfile.policyHolder.emailAddress,

      isFormValid: false,

      errors: {
        address1: "",
        address2: "",
        address3: "",
        _state: "",
        city: "",
        zip: "",
        phone: "",
      },

      infoMessage: {
        type: "",
        content: [""],
      },
    };
  }

  componentDidMount() {
    this.checkRequiredFields();

    this.props.globalContext.triggerGTMDataLayer({
      event: "MyAccountPageView",
      pagePath: "/change-contact-info",
      pageTitle: `${
        this.props.globalContext.isCustomerServiceUser() ? "CS:" : ""
      } ${
        JSON.parse(localStorage.getItem("initialIds")).clientName
      } - Change Contact Information`,
    });
  }

  componentWillUnmount() {
    this.props.globalContext.triggerGTMDataLayer({
      event: "MyAccountPageView",
      pagePath: this.props.location.pathname,
      pageTitle: `${
        this.props.globalContext.isCustomerServiceUser() ? "CS:" : ""
      } ${window.document.title}`,
    });
  }

  checkRequiredFields() {
    if (/^\s*$/.test(this.state.address1)) {
      this.setState({ isFormValid: false });
      return;
    }
    // if (/^\s*$/.test(this.state.address2)) {
    //   this.setState({ isFormValid: false });
    //   return;
    // }
    if (/^\s*$/.test(this.state._state)) {
      this.setState({ isFormValid: false });
      return;
    }
    if (/^\s*$/.test(this.state.city)) {
      this.setState({ isFormValid: false });
      return;
    }
    if (/^\s*$/.test(this.state.zip)) {
      this.setState({ isFormValid: false });
      return;
    }
    // if (/^\s*$/.test(this.state.phone)) {
    //   this.setState({ isFormValid: false });
    //   return;
    // }
    if (/^\s*$/.test(this.state.email)) {
      this.setState({ isFormValid: false });
      return;
    }

    this.setState({ isFormValid: true });
    return;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.checkRequiredFields();
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  checkValidation = () => {
    let errorString = "";

    // Validation for phone
    if (this.state.phone !== "") {
      let phone = extractDigits(this.state.phone);
      if (!/^\d+$/.test(phone) || phone.length !== 10) {
        errorString = errorString + NOTIFICATIONS.INVALID_PHONE + "\n";
        // return NOTIFICATIONS.INVALID_PHONE;
      }
    }

    // Validation for email
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(this.state.email.trim()).toLowerCase())) {
      errorString = errorString + NOTIFICATIONS.INVALID_EMAIL_FORMAT + "\n";
    }

    if (errorString !== "") {
      return errorString;
    } else {
      return null;
    }
  };

  formatPhone = ({ target: { value } }) => {
    const normalized = phoneFormatting(value, this.state.phone);
    this.setState({ phone: normalized }, () => {
      this.checkRequiredFields();
    });
  };

  validationLocation = ({ _state, city, zip }) => {
    return new Promise((res, rej) => {
      let { zip } = this.state;
      this.props.setLoading(true);
      this.sharedService.validationLocation(zip).subscribe((response) => {
        this.props.setLoading(false);
        if (
          response.status === 200 &&
          response.data.state_short === _state &&
          response.data.postal_code === zip &&
          response.data.city === city
        ) {
          res({
            type: "SUCCESS",
            text: "",
          });
        } else {
          res({
            type: "FAILURE",
            text: NOTIFICATIONS.INVALID_LOCATION,
          });
        }
      });
    });
  };

  handleSubmit = () => {
    if (this.state.isFormValid) {
      let hasValidationError = this.checkValidation();
      if (hasValidationError === null) {
        // let validateLocation = await this.validationLocation(this.state);
        // let validateLocation = true; // Until we confirm the API to use
        if (true) {
          //validateLocation.type !== NOTIFICATIONS_TYPE.FAILURE
          //
          let { companyId, domainGroupId } = JSON.parse(
            localStorage.getItem("initialIds")
          );
          let { userid } = JSON.parse(localStorage.getItem("user"));
          let hostName = document.location.hostname;
          let params = {
            addressLine1: this.state.address1,
            addressLine2: this.state.address2,
            addressLine3: this.state.address3,
            cityName: this.state.city,
            stateCode: this.state._state,
            zipCode: this.state.zip,
            emailAddress: this.state.email,
            phoneNumber:
              this.state.phone !== "" ? extractDigits(this.state.phone) : "",
            personNumber: this.props.userProfile.policyHolder.personNumber,
            userName: userid,
            companyId: companyId,
            hostName: hostName,
            domainGroupId: domainGroupId,
          };
          this.props.setLoading(true);
          this.profileService.updateProfile(params).subscribe((response) => {
            this.props.setLoading(false);
            if (response.status === 200) {
              this.props.notificationContext.resetPopupNotifications();
              this.props.hide({
                messages: response.data.messages,
                userProfile: response.data,
              });
            } else {
              this.props.notificationContext.setPopupNotifications(
                response.data.messages
              );
            }
          });
        } else {
          // this.setState({
          //   loading: false,
          //   message: {
          //     type: NOTIFICATIONS_TYPE.FAILURE,
          //     text: validateLocation.text
          //   }
          // });
        }
      } else {
        this.props.notificationContext.setPopupNotifications([
          {
            type: "ERROR",
            content: [hasValidationError],
          },
        ]);
        this.setState({ loading: false });
      }
    }
  };

  checkZip = ({ target: { value, name } }) => {
    const isValid = numberAndDash(value);
    isValid &&
      this.handleChange({
        target: {
          name: name,
          value: value,
        },
      });
  };

  render() {
    return (
      <Modal
        backdrop="static"
        show={this.props.show}
        onHide={() => {
          this.props.notificationContext.resetPopupNotifications();
          this.props.hide();
        }}
      >
        <GlobalContext.Consumer>
          {(context) => (
            <>
              <Modal.Header closeButton>
                <div className="col-12 p-0">
                  <Modal.Title>Contact Information</Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body>
                {this.props.notificationContext.state.popupNotifications.map(
                  (notification, index) => (
                    <Notification
                      key={index}
                      id={index}
                      message={notification}
                    />
                  )
                )}
                <div className="formPanel">
                  <div className="form-group">
                    <div className="address ">
                      <label htmlFor="address">Address 1</label>
                      <input
                        value={this.state.address1}
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleChange}
                        maxLength="30"
                        className="form-control"
                        type="text"
                        name="address1"
                        disabled={
                          this.props.userProfile.editOption.addressEdit &&
                          this.props.userProfile.editOption.addressEdit
                            .disable === "Y"
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="address2">
                      <label htmlFor="address2">Address 2</label>
                      <input
                        value={this.state.address2}
                        onChange={this.handleChange}
                        maxLength="30"
                        onKeyDown={this.handleKeyDown}
                        className="form-control"
                        type="text"
                        name="address2"
                        disabled={
                          this.props.userProfile.editOption.addressEdit &&
                          this.props.userProfile.editOption.addressEdit
                            .disable === "Y"
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="city">
                      <label htmlFor="city">Address 3</label>
                      <input
                        value={this.state.address3}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        maxLength="30"
                        className="form-control"
                        type="text"
                        name="address3"
                        disabled={
                          this.props.userProfile.editOption.addressEdit &&
                          this.props.userProfile.editOption.addressEdit
                            .disable === "Y"
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="zipCode">City</label>
                    <input
                      value={this.state.city}
                      onChange={this.handleChange}
                      onKeyDown={this.handleKeyDown}
                      className="form-control"
                      type="text"
                      name="city"
                      maxLength="30"
                      disabled={
                        this.props.userProfile.editOption.addressEdit &&
                        this.props.userProfile.editOption.addressEdit
                          .disable === "Y"
                      }
                    />
                  </div>

                  <div className="form-group displayFlex">
                    <div className="state">
                      <label htmlFor="state">State</label>
                      <select
                        value={this.state._state}
                        name="_state"
                        onChange={this.handleChange}
                        disabled={
                          this.props.userProfile.editOption.addressEdit &&
                          this.props.userProfile.editOption.addressEdit
                            .disable === "Y"
                        }
                      >
                        <option value="">Please Select</option>
                        {context.state.states.map((value) => (
                          <option key={value.stateCode} value={value.stateCode}>
                            {value.stateCode}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="zipCode">
                      <label htmlFor="zipCode">Zip Code</label>
                      <input
                        value={this.state.zip}
                        onChange={this.checkZip}
                        onKeyDown={this.handleKeyDown}
                        className="form-control"
                        type="text"
                        name="zip"
                        maxLength="10"
                        disabled={
                          this.props.userProfile.editOption.addressEdit &&
                          this.props.userProfile.editOption.addressEdit
                            .disable === "Y"
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="phoneNumber">
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <input
                        value={this.state.phone}
                        onChange={this.formatPhone}
                        onKeyDown={this.handleKeyDown}
                        className="form-control"
                        type="text"
                        name="phone"
                        disabled={
                          this.props.userProfile.editOption.phoneNumberEdit &&
                          this.props.userProfile.editOption.phoneNumberEdit
                            .disable === "Y"
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="email">
                      <label htmlFor="email">Email</label>
                      <input
                        value={this.state.email}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        className="form-control"
                        type="text"
                        name="email"
                        disabled={
                          this.props.userProfile.editOption.emailEdit &&
                          this.props.userProfile.editOption.emailEdit
                            .disable === "Y"
                        }
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn agiaBlueButton-outline"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.notificationContext.resetPopupNotifications();
                    this.props.hide();
                  }}
                >
                  Cancel
                </button>

                <button
                  disabled={
                    this.state.isFormValid === false ||
                    (this.props.userProfile.editOption.save &&
                      this.props.userProfile.editOption.save.disable === "Y")
                  }
                  type="button"
                  className="btn agiaBlueButton"
                  onClick={this.handleSubmit}
                >
                  Save changes
                </button>
              </Modal.Footer>
            </>
          )}
        </GlobalContext.Consumer>
      </Modal>
    );
  }
}
