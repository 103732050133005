import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ARTICLES } from "../../../_constants/constants";

export class ActiveProductsDashboard extends Component {

  state = {
    showClaimNow: false
  }

  componentWillMount() {
    let hasInsuredProduct = this.props.activeProducts.find((product) => product.isInsuranceProduct === 'Y') || false;
    this.setState({ showClaimNow: hasInsuredProduct });
  }

  render() {
    return (
      <>
        <table className={(this.props.activeProducts.length === 0 ? 'productTableMarginBottom' : '') + ' table table-light borderless productTable'}>
          <thead>
            <tr>
              <th className="product-col" scope='col'>PRODUCT</th>
              <th className="payment-frequency-col" scope='col'>
                PAYMENT FREQUENCY
              </th>
              <th scope='col' className='amount amount-col'>
                AMOUNT DUE
              </th>
              <th className="duedate-col" scope='col'>DUE DATE</th>
            </tr>
          </thead>
          <tbody>
            {this.props.activeProducts.map(value => (
              <tr key={value.policyNumber}>
                <td>
                  <Link title={""} to={`/product-details/${value.policyNumber}`}>
                    <span className='anchorText'>{value.productDesc}</span>
                  </Link>
                  <span className='smallTableText'>{value.policyNumber}</span>
                </td>
                <td>{value.currentPaymentFrequecyType}</td>

                <td className='amount'>
                  {value.amountDue}
                  <sup dangerouslySetInnerHTML={{ __html: value.amountDueSpecialCharacter }}></sup>
                </td>
                <td><div className="display:flex;"><span className="dateValue">{value.dueDate}</span><span className={'paymentStatusBadge ' + value.paymentStatusDisplayStyle}>{value.paymentStatus}</span></div></td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='footerTextPara'>
          {this.props.activeProducts.length !== 0 && <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.AUTOPAY_MARKETTING] }} ></div>}
          {this.state.showClaimNow && <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.CLAIM_NOW] }} ></div>}
          {this.props.hasEAPlusProduct === 'Y' && <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.EA_PLUS_MEMBER_NOTE] }} ></div>}
          <div dangerouslySetInnerHTML={{ __html: this.props.globlWebContents[ARTICLES.SITE_DISCLAIMER] }}></div>
        </div>
      </>
    );
  }
}
