import React from "react";
import { ProductsService } from "../../../_services/products.service";
import { SharedService } from "../../../_services/shared.service";
import { ARTICLES } from "../../../_constants/constants";
import { Notification } from "../../../_components/shared/notifications/notifications";
import { ProductsMainDesktop } from "./desktop/ProductsMainDesktop";
import { ProductsMainMobile } from "./mobile/ProductsMainMobile";
import { PAGE_TITLES, PAGES_TITLE_KEYS } from "../../../_constants/constants";
import { Helmet } from "react-helmet";
import "./ProductsMain.scss";
// import * as data from './mock-data.json'

export class ProductsMain extends React.Component {
  productsService = new ProductsService();
  sharedService = new SharedService();

  state = {
    dataLoaded: false,
    products: [],
    inactiveProducts: [],
    globlWebContents: {},
    pageTitle: ""
  };

  componentDidMount() {

    let { client, clientName } = JSON.parse(localStorage.getItem("initialIds"));
    this.setState({ pageTitle: clientName + PAGE_TITLES.PRODUCT_SUMMARY })

    this.getProducts();
    this.globalWC_getContent([
      ARTICLES.CLAIM_NOW,
      ARTICLES.PRODUCT_DISCLAIMER,
      ARTICLES.PRODUCT_DISCLAIMER_COMBINED_BILLING,
      ARTICLES.PRODUCT_DISCLAIMER_NOCOST,
      ARTICLES.SITE_DISCLAIMER,
      ARTICLES.ANNUAL_PRODUCT_COST_DISCLAIMER
    ]);
  }

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items && response.data.items.forEach(element => {
          webContents[element.title] = element.content;
        });
        this.setState({
          globlWebContents: webContents
        });
        // document.getElementById("globalWC_headerPhoneInfo").innerHTML = response.data.content;
      }
    });
  };

  getProducts = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let { companyId, client } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      client: client,
      accountNumber: user.accountNumber,
      policyNumber: user.policyNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      userid: user.userid,
      companyId: companyId
    };

    this.props.setLoading(true);
    this.productsService.getProducts(params).subscribe(response => {
      this.props.setLoading(false);
      if (response.status === 200) {
        this.setState({
          products: response.data.activeProducts || [],
          inactiveProducts: response.data.inactiveProducts || [],
          dataLoaded: true
          // inactiveProducts: data.default,
        });
      } else {
        this.setState({
          products: [],
          dataLoaded: true
        });
      }
      this.props.notificationContext.processMessages(response.data.messages);
    });
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.globalContext.state.pagesTitle[PAGES_TITLE_KEYS.PRODUCT_SUMMARY] || this.state.pageTitle}</title>
        </Helmet>
        <div className='innerBodyWrapper'>
          {this.props.notificationContext.state.infoNotification
            ? this.props.notificationContext.state.infoNotification.map((notification, index) => <Notification key={index} id={index} message={notification} />)
            : ""}
          <div className='productsDetails'>
            <h1 className='mainHeadingT'>Products</h1> {/* Desktop View Start */}
            {this.props.notificationContext.state.nonInfoNotificaiton.map((notification, index) => <Notification key={index} id={index} message={notification} />)}
            {this.props.globalContext.state.deviceWidth >= 768 && (
              <ProductsMainDesktop products={this.state.products} inactiveProducts={this.state.inactiveProducts} globlWebContents={this.state.globlWebContents} />
            )}
            {this.props.globalContext.state.deviceWidth <= 767 && (
              <ProductsMainMobile products={this.state.products} inactiveProducts={this.state.inactiveProducts} globlWebContents={this.state.globlWebContents} />
            )}
          </div>
        </div>
      </>
    );
  }
}
