export const phoneFormatting = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    // if (!previousValue || value.length > previousValue.length) {
    if (currentValue.length <= 3) return currentValue;
    if (currentValue.length === 3) return `(${currentValue})`;
    if (currentValue.length <= 6) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    if (currentValue.length === 6) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}-`
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    // }
};

export const extractDigits = (string) => {
    var numb = string.match(/\d/g);
    numb = numb.join("");
    return numb;
};

export const formatCard = (value) => {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g);
    var match = matches ? matches[0] : ''
    var parts = []
    for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4))
    }
    if (parts.length) {
        return parts.join('-')
    } else {
        return value
    }
}

export const formatRoutingNumber = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    if (currentValue.length <= 3) return currentValue;
    if (currentValue.length === 3) return `${currentValue}-`;
    if (currentValue.length <= 5) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
    if (currentValue.length === 5) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}-`
    return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}-${currentValue.slice(5, 9)}`;
};

export const formatAccountNumber = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    if (currentValue.length <= 4) return currentValue;
    if (currentValue.length === 4) return `${currentValue}-`;
    if (currentValue.length <= 8) return `${currentValue.slice(0, 4)}-${currentValue.slice(4)}`;
    if (currentValue.length === 8) return `${currentValue.slice(0, 4)}-${currentValue.slice(4)}-`
    if (currentValue.length <= 13) return `${currentValue.slice(0, 4)}-${currentValue.slice(4, 8)}-${currentValue.slice(8)}`;
    if (currentValue.length === 13) return `${currentValue.slice(0, 4)}-${currentValue.slice(4, 8)}-${currentValue.slice(8)}-`
    return `${currentValue.slice(0, 4)}-${currentValue.slice(4, 8)}-${currentValue.slice(8, 13)}-${currentValue.slice(13, 17)}`;
};

export const onlyNumericInput = (value) => {
    return /^\d*$/.test(value)
}

export const numberAndDash = (value) => {
    return /^[0-9-]*$/.test(value)
}

export const getCardYears = () => {
    let years = [];
    let currentYear = new Date().getFullYear();
    let max = currentYear + 50;
    for (let i = currentYear; i <= max; i++) {
        years.push(currentYear++);
    }
    return years;
}

export const isMobile = () => {
    return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const convertStringToAmount = (amountString) => {
    if (amountString) {
        return Number(amountString.replace(/[^0-9.-]+/g, ""));
    } else {
        return 0
    }
}